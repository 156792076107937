import * as PIXI from "pixi.js";
import { memo, useCallback, useState } from "react";

import { useBackdropSound } from "@shared/lib/hooks";
import { localStorageService } from "@shared/lib/services";
import { ButtonSecondary } from "@shared/ui";

export const ToggleSound = memo(() => {
  const bgm = useBackdropSound();

  const on = PIXI.Assets.cache.get("lobby-screen.json").textures[`icon-sound_on`];
  const off = PIXI.Assets.cache.get("lobby-screen.json").textures[`icon-sound_off`];
  const isOn = Boolean(localStorageService.getData("sound"));

  const [icon, setIcon] = useState<PIXI.Texture>(bgm.isPlay || isOn ? on : off);

  const toggleBackgroundSound = useCallback(() => {
    if (bgm.isPlay) {
      bgm.mute();
      setIcon(off);
      localStorageService.saveData("sound", false);
    } else {
      bgm.unmute();
      if (!bgm.isPlay) {
        bgm.play();
      }
      setIcon(on);
      localStorageService.saveData("sound", true);
    }
  }, [bgm, off, on]);

  return <ButtonSecondary iconSize={90} iconTexture={icon} onPress={toggleBackgroundSound} />;
});

ToggleSound.displayName = "ToggleSound";
