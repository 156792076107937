import { useCallback, useMemo } from "react";

import { gameModel } from "@entities/game";
import { PROVIDER_ID_LIVE, SHOW_ALL, TAG_DEFAULT_ID, TECHNICAL_TAGS } from "@shared/lib/consts";
import { useProviderId, useTagId } from "@shared/lib/services";
import { getUniqueList } from "@shared/lib/utils";

import type { ITag } from "@shared/lib/api/types";

export function useSideMenuState() {
  const { rawGames } = gameModel.useFilteredGames();
  const { providerId, setProviderId } = useProviderId(PROVIDER_ID_LIVE);
  const { tagId, setTagId } = useTagId(TAG_DEFAULT_ID);

  const findGames = useCallback(
    (excludeLIVE: boolean) => {
      if (!excludeLIVE) {
        return rawGames.filter((game) => game.game_provider_id === PROVIDER_ID_LIVE);
      }

      return rawGames.filter((game) => game.game_provider_id !== PROVIDER_ID_LIVE);
    },
    [rawGames],
  );

  const getLIVETags = useCallback(() => {
    const computedLIVETags: ITag[] = [];
    const liveGames = findGames(false);

    for (const game of liveGames) {
      for (const tag of game.tags) {
        computedLIVETags.push(tag);
      }
    }

    return getUniqueList(computedLIVETags).filter((tag) => !TECHNICAL_TAGS.has(tag.name)) ?? [];
  }, [findGames]);

  const getOtherTags = useCallback(() => {
    const computedOtherTags: ITag[] = [];
    const otherGames = findGames(true);

    for (const game of otherGames) {
      for (const tag of game.tags) {
        computedOtherTags.push(tag);
      }
    }

    return getUniqueList(computedOtherTags).filter((tag) => !TECHNICAL_TAGS.has(tag.name)) ?? [];
  }, [findGames]);

  const computedTags = useMemo(
    () => (providerId === PROVIDER_ID_LIVE ? getLIVETags() : getOtherTags()),
    [providerId, getLIVETags, getOtherTags],
  );

  const handleTagClick = useCallback((tagId: number) => {
    setTagId(tagId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackToAll = useCallback(() => {
    setTagId(TAG_DEFAULT_ID);
    setProviderId(SHOW_ALL);
  }, [setProviderId, setTagId]);

  const handleBackToLive = useCallback(() => {
    setTagId(TAG_DEFAULT_ID);
    setProviderId(PROVIDER_ID_LIVE);
  }, [setProviderId, setTagId]);

  return {
    handleBackToLive,
    handleBackToAll,
    providerId,
    computedTags,
    setTagId: handleTagClick,
    tagId,
  };
}

export type TSideMenuState = ReturnType<typeof useSideMenuState>;
