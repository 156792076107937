import { Container, Sprite, Text } from "@pixi/react";
import * as PIXI from "pixi.js";

import { useGameController } from "@entities/game/model";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { ClickableContainer } from "@shared/ui";

import type { IPointData } from "pixi.js";

export const ToggleGameClose = ({ position }: { position?: IPointData }) => {
  const { t } = useTranslate();

  const { fonts, themeConfig, isMobile } = useThemeConfig();
  const { handleGameStop, currentGameUrl } = useGameController();

  const scale = isMobile ? 2 : 1;

  if (!currentGameUrl) {
    return null;
  }

  return (
    <ClickableContainer position={position} onpointerup={handleGameStop}>
      <Container scale={scale}>
        <Sprite
          texture={PIXI.Assets.cache.get("lobby-screen.json").textures[`icon-game-exit`]}
          anchor={0.5}
        />
        <Text
          position={{ x: themeConfig.header.backGameBtnTitleX, y: 0 }}
          anchor={{ x: 0.25, y: 0.5 }}
          text={t("back").toUpperCase()}
          style={
            new PIXI.TextStyle({
              ...fonts.sideMenuTitleStyle,
              fontSize: 24,
              fontWeight: 600,
              letterSpacing: 1,
            })
          }
        />
      </Container>
    </ClickableContainer>
  );
};
