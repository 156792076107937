import { create } from "zustand";

export type TChangeCallback<Value> = (value: Value) => void;

type TDropdownState = {
  isOpen: boolean;
  toggle: () => void;
  setChangeValueCallback: <T>(changeValueCb: TChangeCallback<T>) => void;
  changeValueCb: <T>(value: T) => void;
};
export const useDropdownStore = create<TDropdownState>((set) => ({
  isOpen: false,
  changeValueCb: () => null,
  toggle: () => set((state) => ({ isOpen: !state.isOpen })),
  setChangeValueCallback: (changeValueCb) =>
    set(<Value>() => ({ changeValueCb: changeValueCb as Value })),
}));
