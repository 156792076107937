import { Container } from "@pixi/react";
import gsap from "gsap";
import { forwardRef, memo, useCallback } from "react";

import { useThemeConfig } from "@shared/lib/hooks";
import { ArrowButton } from "@shared/ui";

import type * as PIXI from "pixi.js";

export const NextButton = memo(
  forwardRef<
    PIXI.Container,
    {
      handleNext: VoidFunction;
    }
  >(({ handleNext }, ref) => {
    const { themeWidth, themeHeight, isMobile } = useThemeConfig();
    const targetRef = ref as unknown as { current: PIXI.Container };

    const setAnimationRef = useCallback(
      (node: PIXI.Container) => {
        if (node && !isMobile) {
          gsap.to(node, {
            yoyo: true,
            repeat: -1,
            duration: 0.4,
            pixi: { x: 10 },
          });
        }
      },
      [isMobile],
    );

    const x = themeWidth * 0.95;
    const y = themeHeight / 2 - 50;

    const handleClick = () => {
      if (targetRef?.current) {
        handleNext();
      }
    };

    if (isMobile) return null;

    return (
      <Container ref={setAnimationRef}>
        <ArrowButton
          type="arrow-next"
          angle={0}
          anchor={0.5}
          position={{ x, y }}
          onClick={handleClick}
        />
      </Container>
    );
  }),
);

NextButton.displayName = "NextButton";
