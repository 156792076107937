import { Container, Text } from "@pixi/react";
import * as PIXI from "pixi.js";
import { useMemo, useState } from "react";

import { gameModel } from "@entities/game";
import { useSmartQuery } from "@shared/lib/api";
import { PROVIDER_ID_LIVE, providersNameMap, SHOW_ALL, TAG_DEFAULT_ID } from "@shared/lib/consts";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { useProviderId, useTagId } from "@shared/lib/services";
import { ButtonSecondary } from "@shared/ui";

export const ToggleLiveProvider = ({ position }: { position?: PIXI.IPointData }) => {
  const { t } = useTranslate();
  const { providerId, setProviderId } = useProviderId(PROVIDER_ID_LIVE);
  const { fonts, isMobile } = useThemeConfig();
  const { setTagId } = useTagId(TAG_DEFAULT_ID);
  const { currentGameUrl } = gameModel.useGameController();

  const on = PIXI.Assets.cache.get("lobby-screen.json").textures[`icon-favorite_on`];
  const off = PIXI.Assets.cache.get("lobby-screen.json").textures[`icon-favorite_off`];

  const [icon, setIcon] = useState<PIXI.Texture>(on);
  const queryProviderList = useSmartQuery("POST Game.providerList", { body: {} });

  const liveProvider = useMemo(() => {
    return queryProviderList?.data?.results[0].response.find(
      (provider) => provider.name === providersNameMap.live.name,
    );
  }, [queryProviderList?.data?.results]);

  const toggleTag = () => {
    if (providerId === SHOW_ALL) {
      // TODO: Fix pid setting behavior
      setProviderId(liveProvider?.id ?? SHOW_ALL);
      setTagId(TAG_DEFAULT_ID);
      setIcon(on);
    } else {
      setProviderId(SHOW_ALL);
      setIcon(off);
    }
  };

  if (isMobile || !!currentGameUrl) {
    return null;
  }

  return (
    <Container position={position}>
      <Text
        position={{ x: 70, y: -20 }}
        style={new PIXI.TextStyle({ ...fonts.primary, fill: "#81CE5E", fontWeight: "bolder" })}
        text={t("live").toUpperCase()}
      />
      <Text
        position={{ x: -170, y: -20 }}
        style={new PIXI.TextStyle({ ...fonts.primary, fontWeight: "bolder" })}
        text={t("games").toUpperCase()}
      />
      <ButtonSecondary
        isHiddenTexture={true}
        iconSize={50}
        iconTexture={providerId !== liveProvider?.id ? off : icon}
        onPress={toggleTag}
      />
    </Container>
  );
};
