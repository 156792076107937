import { Container } from "@pixi/react";
import { type QueryKey } from "@tanstack/react-query";
import * as PIXI from "pixi.js";
import { memo, useEffect, useState } from "react";

import { Tabs, type TTab } from "@/shared/ui/tabs";
import { gameModel } from "@entities/game";
import { playerModel } from "@entities/player";
import { useSmartQuery } from "@shared/lib/api";
import { usePosition, useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { useGameStringId } from "@shared/lib/services";
import { Modal, MutationButtonPrimary, SmartContainer, Spinner, Title } from "@shared/ui";
import { LimitListbox } from "@widgets/modal-select-limit/ui/limit-listbox";

import { useModalSelectLimitState } from "../lib/modal-select-limit.state";

const DEFAULT_SCALE_MOBILE = 2;
const DEFAULT_SCALE = 1;
const DEFAULT_PADDING_TOP = 75;
const DEFAULT_WORD_WRAP_WIDTH_MOBILE = 400;
const DEFAULT_WORD_WRAP_WIDTH = 800;
const TITLE_FONT_SIZE = 40;
const TITLE_FONT_WEIGHT = "bold";
const TITLE_FILL_COLOR = "#162f83";

export const ModalSelectLimitView = memo(() => {
  const { isOpen, close } = useModalSelectLimitState();
  const { setGameStringId } = useGameStringId();

  const handleClose = () => {
    setGameStringId(undefined);
    close();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      name="select-limit"
      onClose={handleClose}
      render={(width, height) => <Content width={width} height={height} close={close} />}
    />
  );
});

ModalSelectLimitView.displayName = "ModalSelectLimitView";

type TContentProps = {
  width: number;
  height: number;
  close: VoidFunction;
};

// type TTab = { isVisible: boolean; key: string; label: string; children: React.ReactNode };

const Content = memo(({ width, height, close }: TContentProps) => {
  const { t } = useTranslate();
  const { isMobile, isSafariOrIos } = useThemeConfig();
  const { currency } = playerModel.usePlayer();
  const { handleSubmit, refWindow } = gameModel.useGameController();
  const { gameStringId } = useGameStringId();
  const { computedGames } = gameModel.useFilteredGames();
  const [currentTab, setCurrentTab] = useState<TTab | null>(null);

  const gameName = computedGames.find((game) => game.string_id === gameStringId)?.name;

  const { isLoading, data, isSuccess } = useSmartQuery(
    "POST Game.vigTableList",
    {
      body: { game: gameName as string },
    },
    {
      queryKeyHashFn: (queryKey: QueryKey) => `${queryKey.filter(Boolean).join(".")}.${gameName}`,
    },
  );

  function submitModal(tableId?: string, limitName?: string) {
    if (isSafariOrIos) {
      refWindow.current = window.open("about:blank", "_blank");
    }

    handleSubmit({
      game: gameStringId as string,
      demo: false,
      place: "game_list",
      lang: "en",
      ...(tableId &&
        limitName && {
          vig_table_id: tableId,
          vig_limitname: limitName,
        }),
    });
    close();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tables = data?.results[0].response.list ?? [];

  useEffect(() => {
    if (isSuccess && tables.length === 0) {
      submitModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, tables]);

  const scale = isMobile ? DEFAULT_SCALE_MOBILE : DEFAULT_SCALE;
  const wordWrapWidth = isMobile ? DEFAULT_WORD_WRAP_WIDTH_MOBILE : DEFAULT_WORD_WRAP_WIDTH;
  width = width / scale;
  height = height / scale;
  const position = usePosition({ width, height });

  const tabItems: TTab[] = tables.map((table, tableIdx) => ({
    isVisible: true,
    key: table.tableId,
    label: `${t("table").toUpperCase()} ${tableIdx + 1}`,
    children: (
      <>
        {table.limits.map((limit, idx) => {
          const limitTitle = `${limit.min} - ${limit.max} ${currency}`;
          return (
            <SmartContainer key={idx} paddingLeft={20} paddingTop={120 * idx || 120}>
              <MutationButtonPrimary
                title={limitTitle}
                titleStyle={
                  new PIXI.TextStyle({
                    fontSize: TITLE_FONT_SIZE,
                    fontWeight: TITLE_FONT_WEIGHT,
                    fill: TITLE_FILL_COLOR,
                  })
                }
                onPress={() => submitModal(table.tableId, limit.limitName)}
              />
            </SmartContainer>
          );
        })}
      </>
    ),
  }));

  useEffect(() => {
    if (!currentTab) {
      setCurrentTab(tabItems[0]);
    }
  }, [currentTab, isSuccess, tabItems, tables]);

  if (isLoading || !currentTab) {
    return (
      <Container scale={scale}>
        <Spinner />
      </Container>
    );
  }

  if (!tables.length) {
    return (
      <SmartContainer
        scale={scale}
        position={{ x: 0, y: -position.bottom.y / 2 }}
        paddingTop={DEFAULT_PADDING_TOP}
      >
        <Title text={t("No tables available")} wordWrapWidth={wordWrapWidth} />
      </SmartContainer>
    );
  }

  return (
    <Container scale={scale}>
      <SmartContainer
        position={{ x: 0, y: -position.bottom.y / 2 }}
        paddingTop={isMobile ? 0 : DEFAULT_PADDING_TOP}
      >
        <Title
          anchorY={isMobile ? 0 : 0.5}
          text={`${t("Select a limit for")}\n${gameStringId}`.toUpperCase()}
          wordWrapWidth={wordWrapWidth}
        />
      </SmartContainer>
      {tables.length > 1 ? (
        <SmartContainer paddingBottom={isMobile ? 100 : 100}>
          {tables.length > 3 ? (
            <SmartContainer centeredChild={true} paddingTop={50} paddingRight={50}>
              <SmartContainer paddingLeft={45}>{currentTab.children}</SmartContainer>
              <LimitListbox
                selectFilteredItems={tabItems}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
              />
            </SmartContainer>
          ) : (
            <Tabs
              activeTab={currentTab}
              onTabChange={setCurrentTab}
              isVertical={isMobile}
              items={tabItems}
            />
          )}
        </SmartContainer>
      ) : (
        tables[0].limits.map((limit, idx) => {
          const limitTitle = `${limit.min} - ${limit.max} ${currency}`;

          return (
            <SmartContainer key={idx} position={{ x: 0, y: 10 + 120 * idx }} centeredChild={true}>
              <MutationButtonPrimary
                key={idx}
                title={limitTitle}
                titleStyle={
                  new PIXI.TextStyle({
                    fontSize: TITLE_FONT_SIZE,
                    fontWeight: TITLE_FONT_WEIGHT,
                    fill: TITLE_FILL_COLOR,
                  })
                }
                onPress={() => submitModal(tables[0].tableId, limit.limitName)}
              />
            </SmartContainer>
          );
        })
      )}
    </Container>
  );
});

Content.displayName = "Content";
