import { Redirect } from "wouter";

import { playerModel } from "@entities/player";
import { LobbyPage } from "@pages/lobby";
import { usePosition } from "@shared/lib/hooks";
import { Spinner } from "@shared/ui";
import { PanicIframe } from "@widgets/panic-iframe";

export function RootGuard() {
  const player = playerModel.usePlayer();
  const position = usePosition();

  if (player.isLoading) {
    return <Spinner position={position.center} />;
  }

  if (player.isFetched && !player.bankGroup) {
    return <Redirect to="/log-in" />;
  } else if (player?.lobby?.isPanic) {
    return <PanicIframe />;
  } else {
    return <LobbyPage />;
  }
}
