import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { useSmartMutation, useSmartQuery } from "@shared/lib/api";

import type { TRuleTypes } from "@shared/lib/api/types";

export const useLobbyParams = () => {
  const lobbyParamsQuery = useSmartQuery("POST Lobby.getParams", { body: {} });

  return {
    data: lobbyParamsQuery?.data,
    isLoading: lobbyParamsQuery.isLoading,
    isError: lobbyParamsQuery.isError,
  };
};

export const useIsSweepstakesEnabled = () => {
  const lobby = useLobbyParams();

  return {
    isSSEnabled: lobby.data?.results[0].response.sweepstakes,
  };
};

export const useRules = () => {
  const lobby = useLobbyParams();
  const queryClient = useQueryClient();
  const getNeedAcceptQuery = useSmartQuery("POST Rules.getNeedAccept", { body: {} });
  const getRulesQuery = useSmartQuery("POST Rules.getRules", { body: {} });
  const isSSLobby = lobby.data?.results[0].response.sweepstakes;

  const getNeedAcceptData = getNeedAcceptQuery?.data?.results.at(0)?.response;
  const getRulesData = getRulesQuery?.data?.results.at(0)?.response;

  const acceptRuleMutation = useSmartMutation("POST Rules.accept", {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["POST Rules.getNeedAccept"] });
    },
  });

  const handleAcceptRule = useCallback(
    (rule: TRuleTypes) => {
      acceptRuleMutation.mutate({ body: { type: rule } });
    },
    [acceptRuleMutation],
  );

  const isSSRulesExist =
    !!isSSLobby && !!getRulesData?.find((rule) => rule.type === "SweepstakesRules");
  const isTermsAndConditionsExist = !!getRulesData?.find(
    (rule) => rule.type === "TermsAndConditions",
  );

  return {
    isSSRulesExist,
    isTermsAndConditionsExist,
    handleAcceptRule,
    getNeedAcceptQuery,
    getRulesQuery,
    needAcceptRules: getNeedAcceptData,
    rules: getRulesData,
    acceptRuleMutation,
    isSSEnabled: isSSLobby,
  };
};

export const useContacts = () => {
  const { data } = useLobbyParams();
  const contactsResponseText = data?.results?.[0]?.response?.contacts;
  return { contactsResponseText };
};

export const useLobbyButtons = () => {
  const { data } = useLobbyParams();
  const buttons = data?.results?.[0]?.response?.lobbyButtons;
  return { buttons };
};
