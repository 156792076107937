import { Container, Sprite, Text } from "@pixi/react";
import * as PIXI from "pixi.js";

import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { MutationButtonPrimary } from "@shared/ui";

import type { Spritesheet } from "pixi.js";

interface IProps {
  position?: PIXI.IPointData;
  handleOk: () => void;
  error: string;
}

export function AlertError({ error, handleOk, position = { x: 0, y: 0 } }: IProps) {
  const { fonts } = useThemeConfig();
  const { t } = useTranslate();
  const background = PIXI.Assets.cache.get<Spritesheet>("common.json").textures[`error-bg`];
  const iconRocket = PIXI.Assets.cache.get("common.json").textures["icon-rocket"];

  return (
    <Container position={position} interactive={true} zIndex={4}>
      <Sprite texture={background} anchor={0.5} />
      <Sprite texture={iconRocket} anchor={0.5} y={-45} />
      <Text
        y={-background.height / 2 + 50}
        anchor={{ x: 0.5, y: 0.5 }}
        zIndex={4}
        style={{
          ...fonts.primary,
          align: "center",
          fontSize: 40,
        }}
        text={t("error").toUpperCase()}
      />
      <Text
        y={50}
        anchor={0.5}
        zIndex={4}
        style={{
          ...fonts.primary,
          align: "center",
          fontSize: 24,
          fill: "white",
          wordWrap: true,
          wordWrapWidth: background.width - 40,
          breakWords: true,
        }}
        text={error}
      />
      <MutationButtonPrimary
        title={t("ok")}
        scale={0.8}
        position={{ x: -120, y: 120 }}
        onPress={handleOk}
      />
    </Container>
  );
}
