import { Container, Graphics, Text } from "@pixi/react";
import { TextStyle } from "pixi.js";
import { forwardRef, useMemo } from "react";

import { playerModel } from "@entities/player";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { formatter } from "@shared/lib/utils";

import type { Container as PIXIContainer } from "pixi.js";

interface TableColumn<T> {
  key: keyof T;
  label: string;
  transform: () => string;
}

interface TableRow {
  balanceBet: number;
  balanceRedeem: number;
  balanceDeposit: number;
  balanceBonus: number;
  balanceWin: number;
}

const DRAW_CELL_GAP = 31;
const MAX_ROW_WIDTH = 0.25;

export const Table = forwardRef<PIXIContainer>((_, ref) => {
  const { themeWidth } = useThemeConfig();
  const { balanceBet, balanceDeposit, balanceBonus, balanceWin, balanceRedeem, currency } =
    playerModel.usePlayer() ?? {};

  const { t } = useTranslate();

  const columns: TableColumn<TableRow>[] = useMemo(
    () => [
      {
        key: "balanceBet",
        label: t("Entries"),
        transform: () => formatter.formatMoney(formatter.toMoney(Number(balanceBet))),
      },
      {
        key: "balanceRedeem",
        label: t("Redeemable"),
        transform: () => formatter.formatMoney(formatter.toMoney(Number(balanceRedeem))),
      },
      {
        key: "balanceDeposit",
        label: t("Credit"),
        transform: () => formatter.formatMoney(formatter.toMoney(Number(balanceDeposit))),
      },
      {
        key: "balanceBonus",
        label: t("Bonus"),
        transform: () => formatter.formatMoney(formatter.toMoney(Number(balanceBonus))),
      },
      {
        key: "balanceWin",
        label: t("Total Win"),
        transform: () => formatter.formatMoney(formatter.toMoney(Number(balanceWin))),
      },
    ],
    [balanceBet, balanceBonus, balanceDeposit, balanceRedeem, balanceWin, t],
  );

  return (
    <Container ref={ref}>
      {columns.map((column, index) => {
        const isLastRow = columns.length - 1 === index;
        const isFirstRow = index === 0;

        return (
          <>
            <Graphics
              draw={(g) => {
                g.clear();
                g.moveTo(0, index * DRAW_CELL_GAP);
                g.lineStyle(2, "#eca90a");

                if (isFirstRow) {
                  g.beginFill("#271552");
                  g.drawRect(0, 0, themeWidth * MAX_ROW_WIDTH, DRAW_CELL_GAP);
                  g.endFill();
                }

                g.lineTo(themeWidth * MAX_ROW_WIDTH, index * DRAW_CELL_GAP);
                g.moveTo(themeWidth * MAX_ROW_WIDTH, 0);
                g.lineTo(themeWidth * MAX_ROW_WIDTH, index * DRAW_CELL_GAP);
                g.moveTo(0, 0);
                g.lineTo(0, index * DRAW_CELL_GAP);
              }}
            />
            <Text
              x={10}
              y={index * DRAW_CELL_GAP}
              text={column.label}
              style={
                new TextStyle({
                  ...styles.text,
                  fontWeight: "bolder",
                  fontSize: 20,
                })
              }
            />
            <Text
              y={index * DRAW_CELL_GAP}
              x={(themeWidth * MAX_ROW_WIDTH) / 1.5}
              text={`${column.transform()} ${currency}`}
              style={
                new TextStyle({
                  ...styles.value,
                })
              }
            />
            {isLastRow && (
              <Graphics
                draw={(g) => {
                  g.clear();
                  g.moveTo(0, (index + 1) * DRAW_CELL_GAP);
                  g.lineStyle(2, "#eca90a");
                  g.lineTo(themeWidth * MAX_ROW_WIDTH, (index + 1) * DRAW_CELL_GAP);
                  g.moveTo(themeWidth * MAX_ROW_WIDTH, 0);
                  g.lineTo(themeWidth * MAX_ROW_WIDTH, (index + 1) * DRAW_CELL_GAP);
                  g.moveTo(0, 0);
                  g.lineTo(0, (index + 1) * DRAW_CELL_GAP);
                }}
              />
            )}
          </>
        );
      })}
    </Container>
  );
});

Table.displayName = "Table";

const styles = {
  text: {
    fontSize: 24,
    lineHeight: 30,
  },
  value: {
    fill: 0xffffff,
    leading: 10,
    letterSpacing: 2,
    lineHeight: 25,
    wordWrapWidth: 1080,
    fontSize: 18,
    fontWeight: "600",
    whiteSpace: "pre",
    wordWrap: true,
  },
} as const;
