import { RulesGuard } from "../ui/guards/rules";

import { Layout } from "./layout";

export const LobbyPage = () => {
  return (
    <>
      <Layout />
      <RulesGuard />
    </>
  );
};

LobbyPage.displayName = "LobbyPage";
