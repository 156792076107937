import { gsap } from "gsap";
import * as PIXI from "pixi.js";

import { sfx } from "@shared/lib/services";

import { GameSymbol } from "./game-symbol";

export interface IReelConfig {
  totalReels: number;
  totalReelCells: number;
  reelCellHeight: number;
  reelCellWidth: number;
  reelVerticalPadding: number;
  reelHorizontalMargin: number;
  countSpinning: number;
  countUniqueSymbolTextures: number;
}

export class Reel extends PIXI.Container {
  declare children: GameSymbol[];

  static totalCells: number = 0;

  spinning = false;
  reelIndex: number;

  #spinningCycles = 0;
  #startY: number = 0;
  #endTween: GSAPTween;
  #spinTween: GSAPTween;

  readonly #countSpinning: number;
  readonly #reelCellWidth: number;
  readonly #reelCellHeight: number;
  readonly #winSlotIndex: number;
  readonly #horizontalMargin: number;
  readonly #verticalPadding: number;
  readonly #countUniqueSymbolTextures: number;
  readonly #updateReelStatus: VoidFunction;

  constructor(
    index: number,
    winSlotIndex: number,
    duration: number,
    updateReelStatus: VoidFunction,
    config: IReelConfig,
  ) {
    super();

    const {
      reelCellHeight,
      reelVerticalPadding,
      reelCellWidth,
      reelHorizontalMargin,
      countSpinning,
      countUniqueSymbolTextures,
    } = config;

    this.reelIndex = index;
    this.#winSlotIndex = winSlotIndex;

    this.#reelCellHeight = reelCellHeight;
    this.#reelCellWidth = reelCellWidth;

    this.#horizontalMargin = reelHorizontalMargin;
    this.#verticalPadding = reelVerticalPadding;

    this.#countSpinning = countSpinning;
    this.#countUniqueSymbolTextures = countUniqueSymbolTextures;

    this.#updateReelStatus = updateReelStatus;

    this.#spinTween = gsap.to(this, {
      paused: true,
      repeatDelay: 0,
      ease: "none",
      duration: 0.18,
      delay: 0,
      repeat: -1,
      pixi: {
        y: this.#reelCellHeight + this.#verticalPadding,
      },
      onRepeat: this.spin.bind(this),
    });

    this.#endTween = gsap.to(this, {
      paused: true,
      ease: "none",
      yoyo: true,
      repeat: 1,
      repeatDelay: 0,
      duration: duration,
      pixi: {
        y: -this.#verticalPadding,
        brightness: 2,
      },
      onComplete: this.complete.bind(this),
    });

    this.setup();
    this.setPosition();
  }

  private setPosition() {
    this.x = this.reelIndex * (this.width + this.#horizontalMargin);
    this.y = 0;
  }

  private setup() {
    for (let index = 0; index < Reel.totalCells; index = index + 1) {
      const symbol = new GameSymbol(
        0,
        index * (this.#reelCellHeight + this.#verticalPadding),
        Math.floor(Math.random() * this.#countUniqueSymbolTextures),
      );

      symbol.width = this.#reelCellWidth;
      symbol.height = this.#reelCellHeight;
      symbol.name = "symbol-" + index;

      this.addChild(symbol);
    }

    this.#startY = this.children[0].y;

    this.hiddenFirstLastSymbols();
  }

  private spin() {
    this.flip();

    if (!this.spinning) {
      this.y = 0;
      this.children[0].y = this.#startY;
      this.#spinTween.pause();
      this.#endTween.restart();
      this.children.forEach((symbol) => symbol.replaceTexture(this.#winSlotIndex));
      this.hiddenFirstLastSymbols();
    }

    if (this.#spinningCycles > this.#countSpinning) {
      this.stop();
    }

    this.#spinningCycles = this.#spinningCycles + 1;
  }

  private flip() {
    if (!this.spinning) return;
    // Remove the last child
    const lastChild = this.children.pop();

    // Check if there was a last child
    if (lastChild) {
      lastChild.y = this.#verticalPadding + 20;
      // Set the y-coordinate and make it visible
      lastChild.visible = true;
      lastChild.replaceTexture(Math.floor(Math.random() * this.#countUniqueSymbolTextures));
      this.children.unshift(lastChild);
      // Update y-coordinates for the remaining symbols
      this.children.forEach((symbol, index) => {
        symbol.y = index * (this.#reelCellHeight + this.#verticalPadding);
      });
    }
  }

  private hiddenFirstLastSymbols() {
    this.children.at(-1)!.alpha = 0;
    this.children.at(0)!.alpha = 0;
  }

  private visibleFirstLastSymbols() {
    this.children.at(-1)!.alpha = 1;
    this.children.at(0)!.alpha = 1;
  }

  public start() {
    this.spinning = true;
    this.#spinTween.restart();
    this.visibleFirstLastSymbols();
  }

  public stop() {
    if (!this.spinning) return;
    this.spinning = false;
  }

  public complete() {
    this.y = 0;
    this.#endTween.pause();
    sfx.play("sounds/reel-stopped.wav");

    setTimeout(() => {
      this.#updateReelStatus();
    }, 1000);
  }
}
