import { type UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { tanstackQueryClient } from "@app/providers/tanstack-query/client";

type TProps<TData, TError, TResult> = {
  mutation: UseMutationResult<TData, TError, TResult, unknown>;
};

export function useSmartMutationClear<TData, TError, TResult>({
  mutation,
}: TProps<TData, TError, TResult>) {
  const mutationCache = useQueryClient(tanstackQueryClient).getMutationCache();

  const clearMutation = useCallback(() => {
    mutation.reset();
    mutationCache.clear();
  }, [mutation, mutationCache]);

  return { clearMutation };
}
