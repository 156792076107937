import { Sprite, Text } from "@pixi/react";
import * as PIXI from "pixi.js";
import { useState } from "react";

import { useThemeConfig, useTranslate } from "@/shared/lib/hooks";
import { ClickableContainer } from "@shared/ui";

import type { Spritesheet } from "pixi.js";

export interface ICheckboxProps {
  defaultValue?: boolean;
  onToggle?: (checked: boolean) => void;
}

export const Checkbox = ({ defaultValue = false, onToggle }: ICheckboxProps) => {
  const { t } = useTranslate();
  const { fonts } = useThemeConfig();

  const common = PIXI.Assets.cache.get<Spritesheet>("common.json");
  const defaultCheckbox = common.textures["checkbox_default"];
  const checkedCheckbox = common.textures["checkbox_checked"];

  const [checked, toggleCheckbox] = useState(defaultValue);

  function handleCheckboxToggle() {
    toggleCheckbox((prev) => {
      const next = !prev;

      if (onToggle) {
        onToggle(next);
      }

      return next;
    });
  }

  return (
    <ClickableContainer onpointertap={handleCheckboxToggle}>
      <Sprite
        anchor={{ x: 0, y: 0.5 }}
        texture={checked ? checkedCheckbox : defaultCheckbox}
        width={defaultCheckbox.width}
        height={defaultCheckbox.height}
      />
      <Text
        name="RememberMe"
        text={t("Remember me")}
        anchor={{ x: 0, y: 0.5 }}
        position={{ x: 70, y: 0 }}
        style={
          new PIXI.TextStyle({
            ...fonts.primary,
            fontSize: 38,
            lineJoin: "bevel",
            stroke: "#162F83",
            strokeThickness: 5,
          })
        }
      />
    </ClickableContainer>
  );
};
