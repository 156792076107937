import { Container, Text } from "@pixi/react";
import * as PIXI from "pixi.js";

import { ToggleDailyWheelButtonView } from "@features/toggle-daily-wheel";
import { ToggleRecommendations } from "@features/toggle-recommendations";
import { useTranslate } from "@shared/lib/hooks";
import { useThemeConfig } from "@shared/lib/hooks/use-theme-config";
import { ButtonSecondary } from "@shared/ui";
import { useFooterState } from "@widgets/footer/lib/footer.state";

export const LeftButtonsGroup = () => {
  const { t } = useTranslate();
  const { themeConfig, themeWidth, fonts, isMobile } = useThemeConfig();
  const { open } = useFooterState();

  const textStyle = { ...fonts.primary, fontSize: 18, fontWeight: "bolder" };

  const paddingX = isMobile ? -themeWidth / 2 + 150 : -themeWidth / 2 + 200;
  const paddingY = isMobile
    ? themeConfig.footer.menuButton.marginTop + 50
    : themeConfig.footer.menuButton.marginTop;

  const wheelFortunePos = isMobile
    ? {
        x: -395,
        y: -themeConfig.footer.height * 1.5,
      }
    : {
        x: paddingX + 20,
        y: paddingY,
      };

  return (
    <>
      <Container
        scale={themeConfig.footer.menuButton.scale}
        angle={isMobile ? 90 : 0}
        x={wheelFortunePos.x}
        y={wheelFortunePos.y}
      >
        <ToggleDailyWheelButtonView />
      </Container>
      <Container scale={themeConfig.footer.menuButton.scale} x={paddingX + 190} y={paddingY}>
        <ToggleRecommendations />
        <Text
          renderable={!isMobile}
          style={textStyle}
          anchor={{ x: 0.45, y: -2.5 }}
          text={t("recommendations").toUpperCase()}
        />
      </Container>
      <Container scale={themeConfig.footer.menuButton.scale} x={paddingX + 400} y={paddingY}>
        <ButtonSecondary
          iconTexture={PIXI.Assets.cache.get("lobby-screen.json").textures[`icon-info`]}
          iconPosition={{ x: 2, y: 0 }}
          onPress={open}
        />
        <Text
          renderable={!isMobile}
          style={textStyle}
          anchor={{ x: 0.5, y: -2.5 }}
          text={t("info").toUpperCase()}
        />
      </Container>
    </>
  );
};
