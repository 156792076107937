import { Container, Sprite, Text } from "@pixi/react";
import * as PIXI from "pixi.js";
import { useEffect, useState } from "react";

import { useThemeConfig } from "@shared/lib/hooks";
import { ClickableContainer } from "@shared/ui";

import type { IPointData } from "pixi.js";
import type { ReactNode } from "react";

interface IProps {
  title?: string;
  onPress?: VoidFunction;
  disabled?: boolean;
  iconTexture?: PIXI.Texture;
  disabledTexture?: PIXI.Texture;
  iconSize?: number;
  children?: ReactNode;
  iconPosition?: IPointData;
  isActive?: boolean;
}

export const ButtonIconWithText = ({
  onPress,
  iconTexture,
  iconSize = 40,
  disabled,
  disabledTexture,
  iconPosition = { x: 10, y: 0 },
  title,
  isActive,
}: IProps) => {
  const { fonts } = useThemeConfig();
  const defaultTexture = PIXI.Assets.cache.get("common.json").textures["button-icon_default"];
  const hoverTexture = PIXI.Assets.cache.get("common.json").textures["button-icon_hover"];
  const pressedTexture = PIXI.Assets.cache.get("common.json").textures["button-icon_pressed"];

  const [texture, setTexture] = useState(defaultTexture);

  useEffect(() => {
    if (isActive) {
      setTexture(defaultTexture);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  if (disabled) {
    return (
      <>
        <Sprite anchor={{ x: 0.5, y: 0.5 }} texture={disabledTexture} />
        <Sprite
          anchor={{ x: 0, y: 0.5 }}
          texture={iconTexture}
          width={iconSize}
          height={iconSize}
          position={{
            x: iconPosition.x - 81,
            y: iconPosition.y,
          }}
        />
      </>
    );
  }

  if (isActive) {
    return (
      <Container>
        <Sprite anchor={{ x: 0.5, y: 0.5 }} texture={pressedTexture}>
          {iconTexture !== PIXI.Texture.EMPTY && (
            <Sprite
              texture={iconTexture}
              anchor={{ x: 0, y: 0.5 }}
              position={{
                x: iconPosition.x - 81,
                y: iconPosition.y,
              }}
            />
          )}
        </Sprite>
        <Text
          text={title}
          anchor={{ x: 0.25, y: 0.5 }}
          style={
            new PIXI.TextStyle({
              ...fonts.primary,
              ...fonts.tagsStyle,
              wordWrap: true,
              wordWrapWidth: 123,
            })
          }
        />
      </Container>
    );
  }

  return (
    <ClickableContainer
      onpointerup={() => {
        onPress?.();
      }}
      onmouseenter={() => {
        setTexture(hoverTexture);
      }}
      onmouseleave={() => {
        setTexture(defaultTexture);
      }}
      ontouchstart={() => {
        setTexture(pressedTexture);
      }}
    >
      <Sprite anchor={{ x: 0.5, y: 0.5 }} texture={disabled ? disabledTexture : texture}>
        {iconTexture !== PIXI.Texture.EMPTY && (
          <Sprite
            texture={iconTexture}
            anchor={{ x: 0, y: 0.5 }}
            position={{
              x: iconPosition.x - 81,
              y: iconPosition.y,
            }}
          />
        )}
      </Sprite>
      <Text
        text={title}
        anchor={{ x: 0.25, y: 0.5 }}
        style={
          new PIXI.TextStyle({
            ...fonts.primary,
            ...fonts.tagsStyle,
            wordWrap: true,
            wordWrapWidth: 123,
          })
        }
      />
    </ClickableContainer>
  );
};
