import { Tunnel } from "@shared/lib/vendors/tunnel-rat/use-tonnel";

import { ReactRouterProvider } from "./providers";

export const App = () => {
  const { ui } = Tunnel.getInstance();

  return (
    <>
      <ReactRouterProvider />
      <ui.Out />
    </>
  );
};

App.displayName = "App";
