import { Container, Sprite, Text } from "@pixi/react";
import { Assets, TextStyle } from "pixi.js";
import { memo } from "react";

import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { useModal } from "@shared/lib/services";
import { formatter } from "@shared/lib/utils";
import { COLOR, MiniModal, MutationButtonPrimary, MutationErrorText } from "@shared/ui";

import { useModalBonusState } from "../lib/modal-bonus.state";

import type { Spritesheet } from "pixi.js";

export const ModalBonusView = memo(() => {
  const { bonus, isOpen } = useModalBonusState();
  const { isMobile } = useThemeConfig();
  const { close } = useModal("bonus");

  if (!bonus || !isOpen) {
    return null;
  }

  const scale = isMobile ? 1 : 1.7;

  return (
    <MiniModal
      name="bonus"
      onClose={close}
      scale={{ x: scale, y: scale }}
      render={(modalSpriteWidth, modalSpriteHeight) => (
        <Content modalSpriteWidth={modalSpriteWidth} modalSpriteHeight={modalSpriteHeight} />
      )}
    />
  );
});

ModalBonusView.displayName = "ModalBonusView";

export const Content = memo(({ modalSpriteWidth, modalSpriteHeight }: TModalContent) => {
  const { t } = useTranslate();
  const { fonts, isMobile } = useThemeConfig();
  const { close } = useModal("bonus");

  const { bonus, handleBonusAccept, useBonusMutation, currency } = useModalBonusState();

  const bonusGift = Assets.cache.get<Spritesheet>("common.json").textures["bonus-gift-icon"];

  const scale = isMobile ? 2 : 1;
  const modalWidth = modalSpriteWidth / scale;
  const modalHeight = modalSpriteHeight / scale;
  const scaleButtons = isMobile ? 0.7 : 0.7;

  const buttonWidth = 320 * scaleButtons;
  const buttonGap = 20;

  return (
    <Container scale={scale}>
      <Text
        x={modalWidth / 2}
        y={isMobile ? 50 : 50}
        anchor={{ x: 0.5, y: 0 }}
        zIndex={2}
        text={`${t("take bonus").toUpperCase()}!`}
        style={
          new TextStyle({
            ...fonts.primary,
            fontSize: 50,
            dropShadow: true,
            dropShadowColor: "#400C4B",
            dropShadowAngle: 0.78,
            dropShadowDistance: 6,
          })
        }
      />
      <Sprite
        position={{ x: modalWidth / 2, y: isMobile ? 350 : 240 }}
        scale={isMobile ? scale : scale * 1.4}
        name="modal-sprite"
        anchor={0.5}
        texture={bonusGift}
      />
      <Text
        x={modalWidth / 2}
        y={modalHeight - 270}
        anchor={0.5}
        text={bonus?.type.toUpperCase()}
        style={
          new TextStyle({
            ...fonts.primary,
            fontSize: 32,
            fill: COLOR.white,
          })
        }
      />
      <Text
        x={modalWidth / 2}
        y={modalHeight - 200}
        anchor={0.5}
        text={`${formatter.formatMoney(formatter.toMoney(bonus?.amount))} ${currency}`}
        style={
          new TextStyle({
            ...fonts.primary,
            fontSize: 56,
            dropShadow: true,
            dropShadowColor: "#400C4B",
            dropShadowAngle: 0.78,
            dropShadowDistance: 6,
          })
        }
      />
      <MutationErrorText
        mutation={useBonusMutation}
        position={{ x: modalWidth / 2, y: modalHeight - 150 }}
      />
      <MutationButtonPrimary
        position={{
          x: modalWidth / 2 - buttonWidth - buttonGap,
          y: modalHeight - 120,
        }}
        title={t("take").toUpperCase()}
        onPress={handleBonusAccept}
        mutation={useBonusMutation}
        scale={scaleButtons}
      />
      <MutationButtonPrimary
        position={{ x: modalWidth / 2 + buttonGap, y: modalHeight - 120 }}
        title={t("later").toUpperCase()}
        onPress={close}
        scale={scaleButtons}
        isNegative
      />
    </Container>
  );
});

Content.displayName = "Content";
