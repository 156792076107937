import { Container } from "@pixi/react";
import { memo, useCallback } from "react";

import type * as PIXI from "pixi.js";
import type { PropsWithChildren } from "react";

type TProps = PropsWithChildren<{
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
  position?: PIXI.IPointData;
  pivot?: PIXI.IPointData;
  scale?: number;
  centeredChild?: boolean;
  disablePivot?: boolean;
  name?: string;
}>;

export const SmartContainer = memo(
  ({
    children,
    position = { x: 0, y: 0 },
    pivot = { x: 0, y: 0 },
    paddingBottom = 0,
    paddingTop = 0,
    paddingRight = 0,
    paddingLeft = 0,
    scale = 1,
    centeredChild = false,
    disablePivot,
    name = "smart-container",
  }: TProps) => {
    const computedPosition = {
      x: position.x + paddingLeft - paddingRight,
      y: position.y + paddingTop - paddingBottom,
    };

    const setChildrenRef = useCallback(
      (node: PIXI.Container) => {
        if (!node || !centeredChild) return;

        const child = node.children[0];

        if (!child && disablePivot) return;

        const { width, height } = child.getLocalBounds();

        if (pivot?.x && pivot?.y) {
          node.pivot.set(pivot.x, pivot.y);
        } else {
          node.pivot.set(width / 2, height / 2);
        }
      },
      [centeredChild, disablePivot, pivot.x, pivot.y],
    );

    return (
      <Container
        name={name}
        zIndex={1}
        ref={setChildrenRef}
        scale={scale}
        position={computedPosition}
        sortableChildren={true}
      >
        {children}
      </Container>
    );
  },
);

SmartContainer.displayName = "PaddingContainer";
