import { useState } from "react";

import { EditFormView } from "@features/player";
import { SettingsFormView } from "@features/settings";
import { useTranslate } from "@shared/lib/hooks";
import { useModal } from "@shared/lib/services";

import type { TUseTranslate } from "@shared/lib/hooks";
import type { TTab } from "@shared/ui";

function getTabItems(t: TUseTranslate["t"]): TTab[] {
  return [
    {
      isVisible: true,
      key: "general",
      label: t("general").toUpperCase(),
      children: <SettingsFormView />,
    },
    {
      isVisible: true,
      key: "password",
      label: t("password").toUpperCase(),
      children: <EditFormView submitButtonPosition={{ x: 40, y: 220 }} />,
    },
  ];
}

export const useModalEditSettingsState = () => {
  const { t } = useTranslate();
  const { isOpen, close } = useModal("edit-settings");
  const tabItems = getTabItems(t);
  const [currentTab, setCurrentTab] = useState<TTab>(tabItems[0]);

  return {
    tabItems,
    isOpen,
    close,
    currentTab,
    setCurrentTab,
    t,
  };
};

export type TModalEditSettingsState = ReturnType<typeof useModalEditSettingsState>;
