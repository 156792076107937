import { Container, Sprite } from "@pixi/react";
import { gsap } from "gsap";
import * as PIXI from "pixi.js";
import { Rectangle } from "pixi.js";
import { memo, useCallback, useMemo, useRef, useState } from "react";

import { ClickableContainer } from "@shared/ui";

import type { IPointData, Spritesheet } from "pixi.js";
import type { ReactNode } from "react";

interface IProps {
  onPress?: VoidFunction;
  disabled?: boolean;
  iconTexture?: PIXI.Texture;
  iconSize?: number;
  width?: number;
  height?: number;
  children?: ReactNode;
  iconPosition?: IPointData;
  isActive?: boolean;
  anchor?: IPointData | number;
  isHiddenTexture?: boolean;
  position?: IPointData | number;
  scale?: number;
}

export const ButtonSecondary = memo(
  ({
    onPress = () => null,
    iconTexture,
    disabled,
    iconPosition = { x: 0, y: 0 },
    isActive,
    anchor = { x: 0.5, y: 0.5 },
    width,
    height,
    isHiddenTexture,
    position = { x: 0, y: 0 },
    scale = 1,
  }: IProps) => {
    const commonSpritesheetData = PIXI.Assets.cache.get<Spritesheet>("common.json");

    const defaultTexture = commonSpritesheetData.textures["button-menu_default"];
    const hoverTexture = commonSpritesheetData.textures["button-menu_hover"];
    const pressedTexture = commonSpritesheetData.textures["button-menu_pressed"];
    const disabledTexture = commonSpritesheetData.textures["button-menu_disabled"];

    const hitArea = new Rectangle(-35, -35, defaultTexture.width, defaultTexture.height);
    const buttonRef = useRef<PIXI.Container | null>(null);
    const [texture, setTexture] = useState(defaultTexture);

    const tweenScaleIncrease = useCallback((target: PIXI.Container) => {
      gsap.to(target, {
        ease: "none",
        duration: 0.1,
        pixi: { scale: 1.03 },
      });
    }, []);

    const tweenScaleDecrease = useCallback((target: PIXI.Container) => {
      gsap.to(target, {
        ease: "none",
        duration: 0.1,
        pixi: { scale: 0.95 },
      });
    }, []);

    const tweenScaleDefault = useCallback((target: PIXI.Container) => {
      gsap.to(target, {
        ease: "none",
        duration: 0.1,
        pixi: { scale: 1 },
      });
    }, []);

    const handleButtonRef = useCallback((node: PIXI.Container) => {
      if (node) {
        buttonRef.current = node;
      }
    }, []);

    const handleMouseEnter = useCallback(() => {
      setTexture(hoverTexture);
      if (buttonRef.current) {
        tweenScaleIncrease(buttonRef.current);
      }
    }, [hoverTexture, tweenScaleIncrease]);

    const renderButton = useMemo(() => {
      if (isActive) {
        return (
          <>
            <Sprite x={0} y={0} anchor={{ x: 0.5, y: 0.5 }} texture={pressedTexture} />
            {iconTexture !== PIXI.Texture.EMPTY && (
              <Sprite
                position={iconPosition}
                texture={iconTexture!}
                anchor={{ x: 0.45, y: 0.52 }}
              />
            )}
          </>
        );
      }

      if (disabled) {
        return (
          <>
            <Sprite
              width={width}
              height={height}
              x={0}
              y={0}
              anchor={anchor}
              texture={disabledTexture}
            />
            <Sprite anchor={{ x: 0.5, y: 0.5 }} texture={iconTexture} position={iconPosition} />
          </>
        );
      }

      if (isHiddenTexture) {
        return <Sprite anchor={{ x: 0.5, y: 0.5 }} texture={iconTexture} position={iconPosition} />;
      }

      return (
        <>
          <Sprite x={0} y={0} anchor={anchor} texture={texture} />
          <Sprite
            anchor={{ x: 0.45, y: 0.52 }}
            texture={iconTexture!}
            position={iconPosition}
            x={iconPosition.x}
            y={iconPosition.y}
          />
        </>
      );
    }, [
      anchor,
      disabled,
      disabledTexture,
      height,
      iconPosition,
      iconTexture,
      isActive,
      isHiddenTexture,
      pressedTexture,
      texture,
      width,
    ]);

    return (
      <ClickableContainer
        scale={scale}
        destroy={() => gsap.killTweensOf(tweenScaleDefault, tweenScaleDecrease)}
        hitArea={hitArea}
        position={position}
        onpointerup={onPress}
        onmouseenter={handleMouseEnter}
        onmouseleave={() => {
          if (buttonRef.current) {
            tweenScaleDefault(buttonRef.current);
          }
          setTexture(defaultTexture);
        }}
        ontouchstart={() => {
          if (buttonRef.current) {
            tweenScaleDecrease(buttonRef.current);
          }

          setTexture(pressedTexture);
        }}
        ontouchend={() => {
          setTexture(defaultTexture);
        }}
        ontouchendoutside={() => {
          setTexture(defaultTexture);
        }}
      >
        <Container ref={handleButtonRef}>{renderButton}</Container>
      </ClickableContainer>
    );
  },
);

ButtonSecondary.displayName = "ButtonSecondary";
