import { Sprite, Text } from "@pixi/react";
import * as PIXI from "pixi.js";
import { type Spritesheet, TextStyle } from "pixi.js";

import { PROVIDER_ID_LIVE } from "@shared/lib/consts";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";

import type { TSideMenuState } from "../model/side.menu.state";

export const SideMenuSprite = ({ providerId }: TSideMenuState) => {
  const menu = PIXI.Assets.cache.get<Spritesheet>("lobby-screen.json").textures[`side-menu`];
  const { t } = useTranslate();
  const { fonts } = useThemeConfig();
  const filterTitle =
    providerId === PROVIDER_ID_LIVE ? `\t\t\t${t("Live Casino")}` : t("Games Filter");

  return (
    <Sprite anchor={{ x: 0, y: 0.2 }} texture={menu}>
      <Text
        x={340}
        zIndex={1}
        anchor={{ x: -0.4, y: 0 }}
        text={filterTitle.toUpperCase()}
        style={
          new TextStyle({
            ...fonts.primary,
            ...fonts.sideMenuTitleStyle,
            letterSpacing: -1,
          })
        }
        angle={89.5}
      />
    </Sprite>
  );
};
