import { ErrorBoundary } from "react-error-boundary";

import { HtmlErrorBoundary } from "@shared/ui";

import { JackpotsContextProvider, PixiAssetsProvider, TanstackQueryProvider } from "../providers";

import type { PropsWithChildren } from "react";

export const RootLayout = ({ children }: PropsWithChildren) => {
  return (
    <TanstackQueryProvider>
      <ErrorBoundary fallback={<HtmlErrorBoundary />}>
        <PixiAssetsProvider>
          <JackpotsContextProvider>{children}</JackpotsContextProvider>
        </PixiAssetsProvider>
      </ErrorBoundary>
    </TanstackQueryProvider>
  );
};

RootLayout.displayName = "RootLayout";
