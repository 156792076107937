import { ApiError } from "./error";

import type { Endpoint, Input, Output } from "@shared/lib/api/types";

export type SendOptions<E extends Endpoint> = {
  endpoint: E;
  signal?: AbortSignal;
} & Input<E>;

export class ApiClient {
  async send<E extends Endpoint>({ endpoint, body, signal }: SendOptions<E>): Promise<Output<E>> {
    const [method] = endpoint.split(" ");

    const request = new Request("/actionHandler/request", {
      method,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        calls: [
          {
            method: endpoint.replace("POST", "").trim(),
            args: body,
          },
        ],
      }),
      signal,
    });

    const apiResponse = await fetch(request);
    const result = await apiResponse.json();
    const { results } = result;
    const { exception, success } = results[0];

    if (apiResponse.status !== 200 || !success) {
      const message = exception?.message;
      throw new ApiError(message ?? "Unknown error");
    }

    return result;
  }
}

export const apiClient = new ApiClient();
