import { Container } from "@pixi/react";
import { memo } from "react";

import { useThemeConfig } from "@shared/lib/hooks";
import { SmartContainer } from "@shared/ui";

import { JackpotTitleAnimation } from "./jackpot-title-animation";
import { Slot } from "./slot/slot";
import { WinJackpotInfo } from "./win-jackpot-info";

export const MiniGame = memo(() => {
  const { isMobile } = useThemeConfig();

  const computedStyles = {
    paddingRight: isMobile ? 20 : 0,
    paddingBottom: isMobile ? 250 : 0,
    scale: isMobile ? 1.5 : 1,
    centeredChild: isMobile,
  };

  return (
    <Container scale={computedStyles.scale}>
      <SmartContainer
        centeredChild={computedStyles.centeredChild}
        paddingRight={computedStyles.paddingRight}
        paddingBottom={computedStyles.paddingBottom}
      >
        <JackpotTitleAnimation />
        <Slot />
        <WinJackpotInfo />
      </SmartContainer>
    </Container>
  );
});

MiniGame.displayName = "MiniGame";
