import { create } from "zustand";

interface ILobbyStore {
  isSideMenuStartedOpen: boolean;
  toggleSideMenu: (isOpen: boolean) => void;
}

export const useLobbyStore = create<ILobbyStore>()((set) => ({
  isSideMenuStartedOpen: false,
  toggleSideMenu: (isOpen) => set(() => ({ isSideMenuStartedOpen: isOpen })),
}));
