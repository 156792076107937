import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { useAppStore } from "@app/providers";
import { useSmartMutation, useSmartMutationState, useSmartQuery } from "@shared/lib/api";
import { useThemeConfig } from "@shared/lib/hooks";
import { useRegisterUpdateGameBalanceService } from "@shared/lib/services";

function setIsLayoutLoading(isLoading: boolean) {
  const setIsLayoutLoading = useAppStore.getState().setIsLayoutLoading;
  setIsLayoutLoading(isLoading);
}

class Player {
  useLogout = () => {
    const queryClient = useQueryClient();

    const logoutMutation = useSmartMutation("POST Auth.logout", {
      onSuccess() {
        setIsLayoutLoading(false);
        queryClient.invalidateQueries({ queryKey: ["POST Auth.user"] });
        window.location.href = "/sign-in";
      },
    });

    const handleExit = useCallback(() => {
      logoutMutation.mutate({ body: {} });
      setIsLayoutLoading(true);
    }, [logoutMutation]);

    return { handleExit, logoutMutation };
  };
  useLogin = () => {
    const queryClient = useQueryClient();
    const loginMutation = useSmartMutation("POST Auth.login", {
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ["POST Auth.user"] });
        setIsLayoutLoading(false);
      },
      onError() {
        setIsLayoutLoading(false);
      },
    });

    return { loginMutation };
  };
  useEditPlayerProfile = () => {
    const queryClient = useQueryClient();

    const editMutation = useSmartMutation("POST Auth.userUpdate", {
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ["POST Auth.user"] });
        setIsLayoutLoading(false);
      },
      onError() {
        setIsLayoutLoading(false);
      },
    });

    return { editMutation };
  };
  useEditPlayerPassword = () => {
    const queryClient = useQueryClient();

    const editMutation = useSmartMutation("POST Auth.changePassword", {
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ["POST Auth.user"] });
        setIsLayoutLoading(false);
      },
      onError() {
        setIsLayoutLoading(false);
      },
    });

    return { editMutation };
  };
  usePlayer = () => {
    const queryClient = useQueryClient();
    const playerQuery = useSmartQuery("POST Auth.user", { body: {} }, { refetchInterval: 15_000 });
    const { isCurrencyHidden } = useThemeConfig();
    useRegisterUpdateGameBalanceService({
      registerCallback: () => queryClient.invalidateQueries({ queryKey: ["POST Auth.user"] }),
    });

    const data = playerQuery?.data?.results[0];

    return {
      query: playerQuery,
      lobby: data?.response?.lobby,
      bankGroup: data?.response?.bankGroup,
      name: data?.response?.displayable_name ?? data?.response?.name,
      phone: data?.response?.phone,
      email: data?.response?.email,
      balanceBet: data?.response?.balanceBet,
      balanceWin: data?.response?.balanceWin,
      balanceDeposit: data?.response?.balanceDeposit,
      balanceBonus: data?.response?.balanceBonus,
      balanceRedeem: data?.response?.balanceRedeem,
      balance: data?.response?.balance,
      isLoading: playerQuery.isLoading,
      isSuccess: playerQuery.isSuccess,
      isError: playerQuery.isError,
      isFetched: playerQuery.isFetched,
      currency: isCurrencyHidden ? "" : data?.response?.currency ?? "",
    };
  };
  isAuth = () => {
    const authState = useSmartMutationState({
      filters: { mutationKey: ["POST Auth.login"], status: "success" },
      select: (mutation) => mutation.state.data?.results[0].success,
    }) as Array<boolean>;

    const [isAuth] = authState;
    return isAuth;
  };
}

export const playerModel = new Player();
