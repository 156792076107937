import { useCallback } from "react";

import { ClickableContainer } from "@shared/ui";

import { useDropdownStore } from "./listbox-context";

import type { PropsWithChildren } from "react";

type TListboxOption<T> = {
  value: T;
  disabled?: boolean;
};

export function ListboxOption<T>({
  children,
  value,
  disabled,
}: PropsWithChildren<TListboxOption<T>>) {
  const toggle = useDropdownStore(({ toggle }) => toggle);
  const changeValueCb = useDropdownStore(({ changeValueCb }) => changeValueCb);

  const changeValue = useCallback(() => {
    toggle();
    changeValueCb(value);
  }, [changeValueCb, toggle, value]);

  return (
    <ClickableContainer
      onpointerup={changeValue}
      alpha={disabled ? 0.5 : 1}
      eventMode={disabled ? "none" : "static"}
    >
      {children}
    </ClickableContainer>
  );
}
