import { Container } from "@pixi/react";
import * as PIXI from "pixi.js";
import { memo, useCallback } from "react";

import { PROVIDER_ID_LIVE, TAG_DEFAULT_ID } from "@shared/lib/consts";
import { useTranslate } from "@shared/lib/hooks";
import { ButtonIconWithText } from "@shared/ui";

import type { TSideMenuState } from "../model/side.menu.state";
import type { Texture } from "pixi.js";

const TAG_HEIGHT = 72;
const ICON_SIZE = 30;

export const Tags = memo(
  ({
    toggle,
    setTagId,
    providerId,
    computedTags,
    handleBackToAll: backToAll,
    handleBackToLive: backToLive,
    tagId,
  }: TSideMenuState & {
    toggle: VoidFunction;
  }) => {
    const { t } = useTranslate();

    const iconBook = PIXI.Assets.cache.get("common.json").textures["icon-book"];
    const iconFish = PIXI.Assets.cache.get("common.json").textures["icon-fish"];
    const iconBaccarat = PIXI.Assets.cache.get("common.json").textures["icon-baccarat"];
    const iconRoulette = PIXI.Assets.cache.get("common.json").textures["icon-roulette"];
    const iconBlackJack = PIXI.Assets.cache.get("common.json").textures["icon-blackjack"];
    const iconBack = PIXI.Assets.cache.get("common.json").textures["icon-back-tag"];
    const iconBackLive = PIXI.Assets.cache.get("common.json").textures["icon-back-live-tag"];
    const iconSuper = PIXI.Assets.cache.get("common.json").textures["icon-super"];
    const iconOther = PIXI.Assets.cache.get("common.json").textures["icon-other-tag"];
    const iconNew = PIXI.Assets.cache.get("common.json").textures["icon-new"];
    const iconAviator = PIXI.Assets.cache.get("common.json").textures["icon-aviator"];
    const iconTable = PIXI.Assets.cache.get("common.json").textures["icon-table"];

    const iconMap: Record<string, Texture> = {
      all: iconBook,
      Fishing: iconFish,
      other: iconOther,
      baccarat: iconBaccarat,
      roulette: iconRoulette,
      blackjack: iconBlackJack,
      super: iconSuper,
      new: iconNew,
      aviator: iconAviator,
      Table: iconTable,
    };

    const handleTag = useCallback(
      (id: number) => {
        toggle();
        setTagId(id);
      },
      [setTagId, toggle],
    );

    const handleBackToAll = useCallback(() => {
      toggle();
      backToAll();
    }, [backToAll, toggle]);

    const handleBackToLive = useCallback(() => {
      toggle();
      backToLive();
    }, [backToLive, toggle]);

    return (
      <Container y={-80}>
        <Container y={75} x={127}>
          {providerId === PROVIDER_ID_LIVE ? (
            <ButtonIconWithText
              title={t("back to games").toUpperCase()}
              iconSize={ICON_SIZE}
              onPress={handleBackToAll}
              iconTexture={iconBack}
            />
          ) : (
            <ButtonIconWithText
              title={t("all").toUpperCase()}
              iconSize={ICON_SIZE}
              isActive={TAG_DEFAULT_ID === tagId}
              onPress={() => handleTag(TAG_DEFAULT_ID)}
              iconTexture={iconBook}
            />
          )}
        </Container>
        <Container y={0} x={127}>
          {providerId !== PROVIDER_ID_LIVE && (
            <ButtonIconWithText
              title={t("go to live").toUpperCase()}
              iconSize={ICON_SIZE}
              onPress={handleBackToLive}
              iconTexture={iconBackLive}
            />
          )}
        </Container>
        {computedTags?.slice(0, 6).map((tag, index) => (
          <Container key={tag.id} position={{ x: 127, y: TAG_HEIGHT * 2 + 10 + index * 85 }}>
            <ButtonIconWithText
              title={tag.name.toUpperCase()}
              key={tag.id}
              isActive={tag.id === tagId}
              onPress={() => handleTag(tag.id)}
              iconTexture={iconMap[tag.name] ?? iconMap["other"]}
            />
          </Container>
        ))}
      </Container>
    );
  },
);

Tags.displayName = "Tags";
