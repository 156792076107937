import { Sprite, Text } from "@pixi/react";
import * as PIXI from "pixi.js";

import { useThemeConfig } from "@shared/lib/hooks";
import { Listbox, SmartContainer } from "@shared/ui";

import type { TTab } from "@shared/ui";
import type { Spritesheet } from "pixi.js";

const OPTION_PADDING_ITEM = 50;

type Props<T> = {
  currentTab: T;
  setCurrentTab: (value: T) => void;
  selectFilteredItems: T[];
};

export function LimitListbox({ setCurrentTab, currentTab, selectFilteredItems }: Props<TTab>) {
  const { fonts } = useThemeConfig();

  const selectOpen = PIXI.Assets.cache.get<Spritesheet>("common.json").textures["select-open"];
  const selectClosed = PIXI.Assets.cache.get<Spritesheet>("common.json").textures["select-close"];

  const listWidth = 500;

  const optionHitArea = new PIXI.Rectangle(0, 0, listWidth, 50);

  return (
    <Listbox onChange={setCurrentTab}>
      {(isOpen) => (
        <>
          <SmartContainer paddingRight={30} paddingBottom={100}>
            <Listbox.Label>
              <Sprite texture={isOpen ? selectOpen : selectClosed} />
              <Text
                style={{ ...fonts.primary, fontSize: 40 }}
                text={currentTab.label}
                anchor={{ x: 0, y: 0.5 }}
                position={{ x: 50, y: 95 / 2 }}
              />
            </Listbox.Label>
          </SmartContainer>
          <Listbox.Options>
            {selectFilteredItems.map((tab, index) => {
              return (
                <Listbox.Option key={tab.key} value={tab} disabled={!tab.isVisible}>
                  <Text
                    hitArea={optionHitArea}
                    style={{ ...fonts.primary, fill: 0xffffff }}
                    text={tab.label}
                    x={25}
                    y={index * OPTION_PADDING_ITEM}
                  />
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
}
