import { Container, Text } from "@pixi/react";
import { TextStyle } from "pixi.js";
import { memo } from "react";

import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { Modal, MutationButtonPrimary } from "@shared/ui";
import { Tabs } from "@shared/ui/tabs";

import { useModalRulesState } from "../lib/modal-rules.state";

import type { TModalRulesState } from "../lib/modal-rules.state";

export const ModalRulesView = memo(() => {
  const { fonts } = useThemeConfig();
  const { t } = useTranslate();

  const state = useModalRulesState();

  if (!state.isVisible) {
    return null;
  }

  return (
    <Modal
      name="modal-rules"
      render={(modalSpriteWidth, modalSpriteHeight) => (
        <>
          <Text
            y={-340}
            anchor={0.5}
            text={t("rules").toUpperCase()}
            style={
              new TextStyle({
                ...fonts.primary,
                fontSize: 56,
              })
            }
          />
          <Content
            modalSpriteWidth={modalSpriteWidth}
            modalSpriteHeight={modalSpriteHeight}
            {...state}
          />
        </>
      )}
    />
  );
});

const Content = memo(
  ({
    modalSpriteWidth,
    modalSpriteHeight,
    tabItems,
    currentTab,
    setCurrentTab,
    handleExit,
    handleRuleAccept,
    acceptRuleMutation,
  }: TModalRulesState & TModalContent) => {
    const { t } = useTranslate();
    const { isMobile } = useThemeConfig();

    const scale = isMobile ? 2 : 1;
    const scaleButtons = isMobile ? 0.65 : 0.8;

    const scaleWidth = modalSpriteWidth / scale;
    const scaleHeight = modalSpriteHeight / scale;

    return (
      <Container scale={scale}>
        <Tabs
          activeTab={currentTab}
          isVertical={isMobile}
          items={tabItems}
          onTabChange={setCurrentTab}
        />
        <MutationButtonPrimary
          onPress={handleExit}
          isNegative={true}
          position={{ x: isMobile ? 20 : scaleWidth - scaleWidth / 1.2, y: scaleHeight - 100 }}
          scale={scaleButtons}
          title={t("decline")}
        />
        <MutationButtonPrimary
          mutation={acceptRuleMutation}
          onPress={handleRuleAccept}
          position={{
            x: isMobile ? scaleWidth - 235 : scaleWidth - scaleWidth / 2.5,
            y: scaleHeight - 100,
          }}
          scale={scaleButtons}
          title={t("accept")}
        />
      </Container>
    );
  },
);

Content.displayName = "Content";
ModalRulesView.displayName = "ModalRulesView";
