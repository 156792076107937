import { useEffect } from "react";
import { create } from "zustand";

import type { ReactNode } from "react";
import type { StoreApi } from "zustand";

type TProps = { children: React.ReactNode };

type TState = {
  current: Array<React.ReactNode>;
  version: number;
  set: StoreApi<TState>["setState"];
};

function tunnel() {
  const useStore = create<TState>((set) => ({
    current: new Array<ReactNode>(),
    version: 0,
    set,
  }));

  return {
    In: ({ children }: TProps) => {
      const set = useStore((state) => state.set);
      const version = useStore((state) => state.version);

      useEffect(() => {
        set((state) => ({
          version: state.version + 1,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        set(({ current }) => ({
          current: [...current, children],
        }));

        return () =>
          set(({ current }) => ({
            current: current.filter((c) => c !== children),
          }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [children, version]);

      return null;
    },

    Out: () => {
      return useStore((state) => state.current);
    },
  };
}

export class Tunnel {
  ui: ReturnType<typeof tunnel>;
  static instance: Tunnel;

  static getInstance() {
    if (!Tunnel.instance) {
      Tunnel.instance = new Tunnel();
    }

    return Tunnel.instance;
  }

  private constructor() {
    this.ui = tunnel();
  }
}
