import * as PIXI from "pixi.js";

import { SHOW_ALL, TAG_DEFAULT_ID, TAG_RECOMMENDATION_ID } from "@shared/lib/consts";
import { useProviderId, useTagId } from "@shared/lib/services";
import { ButtonSecondary } from "@shared/ui";

export const ToggleRecommendations = () => {
  const { tagId, setTagId } = useTagId();
  const { setProviderId } = useProviderId();

  const toggleTag = () => {
    if (tagId === TAG_RECOMMENDATION_ID) {
      setTagId(TAG_DEFAULT_ID);
    } else {
      setProviderId(SHOW_ALL);
      setTagId(TAG_RECOMMENDATION_ID);
    }
  };

  return (
    <ButtonSecondary
      isActive={tagId === TAG_RECOMMENDATION_ID}
      iconSize={50}
      iconTexture={PIXI.Assets.cache.get("lobby-screen.json").textures[`icon-recommendation`]}
      onPress={toggleTag}
    />
  );
};
