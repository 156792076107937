import * as PIXI from "pixi.js";
import { memo } from "react";

import { usePosition } from "@shared/lib/hooks";
import { AnimatedSpine, SmartContainer } from "@shared/ui";

export const JackpotTitleAnimation = memo(() => {
  const position = usePosition();

  const titleAnimData = PIXI.Assets.cache.get(
    "spine/jackpot/jp-mini-game-title/skeleton.json",
  ).spineData;

  return (
    <SmartContainer position={position["center"]} paddingBottom={300}>
      <AnimatedSpine spineData={titleAnimData} />
    </SmartContainer>
  );
});

JackpotTitleAnimation.displayName = "JackpotTitleAnimation";
