export interface IDeviceState {
  forceMobile: boolean;
  forceLandscape: boolean;
  _windowWidth: number;
  _windowHeight: number;
  portrait: boolean;
  landscape: boolean;
  ios: boolean;
  android: boolean;
  mobile: boolean;
  iPadPro: boolean;
  tablet: boolean;
  desktop: boolean;
  platform: "phone" | "tablet" | "desktop";
  supportsTouchEvents: boolean;
  supportsPointerEvents: boolean;
  ua: string;

  match(key: string): boolean;

  resize(width: number, height: number): void;

  readonly width: number;
  readonly height: number;
}

import * as PIXI from "pixi.js";

import type { IRenderer } from "pixi.js";

export class Device {
  public readonly forceMobile: boolean;
  public readonly forceLandscape: boolean;
  private readonly ua: string;
  private _windowWidth = window.innerWidth;
  private _windowHeight = window.innerHeight;

  constructor(
    private readonly renderer: IRenderer,
    forceLandscape = false,
  ) {
    this.forceMobile = document.documentElement?.classList?.contains("layout-mobile") ?? false;
    this.forceLandscape = forceLandscape;
    this.ua = window.navigator.userAgent.toLowerCase();
  }

  match(key: string) {
    return this.ua.includes(key);
  }

  resize(width: number, height: number) {
    this._windowWidth = width;
    this._windowHeight = height;
  }

  get width() {
    return this._windowWidth;
  }

  get height() {
    return this._windowHeight;
  }

  get portrait() {
    return this.height / this.width > 1 && !this.forceLandscape;
  }

  get landscape() {
    return !this.portrait;
  }

  get ios() {
    return PIXI.utils.isMobile.apple.phone || PIXI.utils.isMobile.apple.tablet;
  }

  get iosOrSafari() {
    if (this.match("safari") && this.match("chrome")) {
      return false;
    }

    return this.ios || this.match("safari");
  }

  get android() {
    return this.match("android");
  }

  get mobile() {
    return this.match("iphone") || (this.android && this.match("mobile")) || this.forceMobile;
  }

  get iPadPro() {
    return window.navigator.platform === "MacIntel" && window.navigator.maxTouchPoints > 1;
  }

  get tablet() {
    return this.match("ipad") || (this.android && !this.match("mobile")) || this.iPadPro;
  }

  get desktop() {
    return !(this.mobile || this.tablet);
  }

  get platform() {
    return this.mobile ? "phone" : this.tablet ? "tablet" : "desktop";
  }

  get supportsTouchEvents() {
    return this.renderer.events.supportsTouchEvents;
  }

  get supportsPointerEvents() {
    return this.renderer.events.supportsPointerEvents;
  }
}
