import { Stage } from "@pixi/react";
import { QueryClientContext } from "@tanstack/react-query";
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";
import { Assets, ColorMatrixFilter, DisplayObject, Graphics, utils } from "pixi.js";
import { memo, useCallback, useEffect, useRef } from "react";

import { useBackdropSound } from "@/shared/lib/hooks";
import { JackpotsContext } from "@app/providers";
import { useInitApp } from "@app/use-init-app";
import { localStorageService } from "@shared/lib/services";

import { ReactIntlProvider } from "../react-intl/with-intl";

import { AssetsContext } from "./pixi-assets.provider";

import type { Application, ICanvas } from "pixi.js";
import type { Context, PropsWithChildren, ReactNode } from "react";

function ContextBridgeProvider<T>(props: {
  children: ReactNode;
  Context: Context<T>;
  render: (children: ReactNode) => ReactNode;
}): ReactNode {
  const Context = props.Context;

  return (
    <JackpotsContext.Consumer>
      {(context) => (
        <AssetsContext.Consumer>
          {(resources) => (
            <Context.Consumer>
              {(value) =>
                props.render(
                  <ReactIntlProvider>
                    <AssetsContext.Provider value={resources}>
                      <Context.Provider value={value}>
                        <JackpotsContext.Provider value={context}>
                          {props.children}
                        </JackpotsContext.Provider>
                      </Context.Provider>
                    </AssetsContext.Provider>
                  </ReactIntlProvider>,
                )
              }
            </Context.Consumer>
          )}
        </AssetsContext.Consumer>
      )}
    </JackpotsContext.Consumer>
  );
}

export const PixiApplication = memo(({ children, ...props }: PropsWithChildren<IStage>) => {
  const initAppRef = useRef<Application<ICanvas> | null>(null);
  const { init } = useInitApp();
  const bgm = useBackdropSound();
  const isLocalStorageSoundOn = Boolean(localStorageService.getData("sound"));

  const appSetRef = useCallback((app: Application<ICanvas>) => {
    PixiPlugin.registerPIXI({
      DisplayObject: DisplayObject,
      Graphics: Graphics,
      ColorMatrixFilter: ColorMatrixFilter,
    });

    gsap.registerPlugin(PixiPlugin);

    if (!initAppRef.current && app?.view) {
      initAppRef.current = app;
    }
  }, []);

  useEffect(() => {
    if (!isLocalStorageSoundOn) {
      bgm.stop();
    } else {
      localStorageService.saveData("sound", true);
      bgm.play();
    }
  }, [bgm, isLocalStorageSoundOn]);

  useEffect(() => {
    if (initAppRef.current) {
      initAppRef.current?.ticker.destroy();
      initAppRef.current?.renderer.destroy();
      utils.clearTextureCache();
      utils.destroyTextureCache();
      Assets.cache.reset();
      console.debug("Lobby PixiApplication destroyed");
    }
  }, []);

  if (initAppRef.current) {
    return null;
  }

  return (
    <ContextBridgeProvider
      Context={QueryClientContext}
      render={(children) => (
        <Stage onMount={init} ref={appSetRef as () => void} {...props}>
          {children}
        </Stage>
      )}
    >
      {children}
    </ContextBridgeProvider>
  );
});

PixiApplication.displayName = "PixiApplication";
