import { memo } from "react";

import { PixiInput } from "./input";

import type { IPointData } from "pixi.js";

interface IProps {
  defaultValue?: string;
  onChange: (text: string) => void;
  placeholder?: string;
  anchor?: IPointData;
  position?: IPointData;
  width?: number;
  height?: number;
  type?: "text" | "password";
  scale?: number;
  isFocused?: boolean;
}

export const InputView = memo(
  ({
    defaultValue,
    onChange,
    placeholder,
    anchor,
    width,
    height,
    position,
    type = "text",
    scale = 1,
    isFocused = false,
  }: IProps) => {
    return (
      <PixiInput
        isFocused={isFocused}
        scale={scale}
        type={type}
        anchor={anchor}
        width={width}
        height={height}
        position={position}
        placeholder={placeholder}
        onChange={onChange}
        value={defaultValue}
      />
    );
  },
);

InputView.displayName = "InputComponent";
