import { memo } from "react";

import { useAppStore } from "@app/providers";
import { usePosition } from "@shared/lib/hooks";
import { AlertError, QueryErrorAlert, SmartContainer } from "@shared/ui";

import { useGameListState } from "../lib/game-list.state";

import { GameListView } from "./game-list.view";

export const GameList = memo(() => {
  const position = usePosition();
  const { queryGames } = useGameListState();
  const gameOpenError = useAppStore(({ gameOpenError }) => gameOpenError);
  const setGameOpenError = useAppStore(({ setGameOpenError }) => setGameOpenError);

  return (
    <>
      {!!gameOpenError && (
        <SmartContainer position={position["center"]}>
          <AlertError handleOk={() => setGameOpenError()} error={gameOpenError} />
        </SmartContainer>
      )}
      <QueryErrorAlert query={queryGames} position={position.center} />
      <GameListView />
    </>
  );
});

GameList.displayName = "GameList";
