import { Container } from "@pixi/react";
import { memo } from "react";

import { GameList } from "@widgets/game-list";
import { SideMenu } from "@widgets/side-menu";

export const LayoutContent = memo(() => {
  return (
    <Container name="content" sortableChildren={true}>
      <GameList />
      <SideMenu />
    </Container>
  );
});

LayoutContent.displayName = "LayoutContent";
