import { Container } from "@pixi/react";

import { usePosition, useThemeConfig } from "@shared/lib/hooks";
import { ButtonExpand } from "@shared/ui";

import useFullscreen from "../lib/use-fullscreen";

export const ToggleFullscreen = () => {
  const [_, toggleFullscreen] = useFullscreen();
  const { isMobile } = useThemeConfig();
  const position = usePosition();

  if (isMobile) {
    return null;
  }

  return (
    <Container pivot={{ x: 50, y: -50 }} position={position["top-right"]}>
      <ButtonExpand onPress={toggleFullscreen} />
    </Container>
  );
};

ToggleFullscreen.displayName = "FullscreenButton";
