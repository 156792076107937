export const get = <T>(object: T, path?: string, defaultValue?: unknown): any => {
  if (!path || !isObject(object)) {
    return defaultValue;
  }

  const result = compact(path.split(/[,[\].]+?/)).reduce(
    (result, key) =>
      isNullOrUndefined(result) ? result : result[key as keyof NonNullable<unknown>],
    object,
  );

  return isUndefined(result) || result === object
    ? isUndefined(object[path as keyof T])
      ? defaultValue
      : object[path as keyof T]
    : result;
};

export const compact = <TValue>(value: TValue[]) =>
  Array.isArray(value) ? value.filter(Boolean) : [];

export const isNullOrUndefined = (value: unknown): value is null | undefined => value == null;

export const isObjectType = (value: unknown): value is object => typeof value === "object";

export const isObject = <T extends object>(value: unknown): value is T =>
  !isNullOrUndefined(value) && !Array.isArray(value) && isObjectType(value);

export const isUndefined = (value: unknown): value is undefined => value === undefined;
