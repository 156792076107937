import { useCallback } from "react";

import { bgm, localStorageService, sfx } from "@shared/lib/services";

export function useSettingsFormState() {
  const setBackgroundVolume = useCallback((value: number) => {
    bgm.volume = value;
    localStorageService.saveData("bgm", value);
  }, []);

  const setEffectVolume = useCallback((value: number) => {
    sfx.volume = value;
    localStorageService.saveData("sfx", value);
  }, []);

  return {
    setBackgroundVolume,
    setEffectVolume,
    defaultBackgroundVolume: bgm.volume,
    defaultEffectVolume: sfx.volume,
  };
}
