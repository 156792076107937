import { Container, Sprite, Text } from "@pixi/react";
import { gsap } from "gsap";
import * as PIXI from "pixi.js";
import { memo, useCallback } from "react";

import { useAppStore } from "@app/providers";
import { playerModel } from "@entities/player";
import { useJackpotsContext, useThemeConfig } from "@shared/lib/hooks";
import { formatter } from "@shared/lib/utils";
import { AlertError, AnimatedSpine, ButtonClose, COLOR } from "@shared/ui";

export const Jackpots = memo(() => {
  const { isMobile, themeConfig, themeWidth } = useThemeConfig();
  const { slots, error } = useJackpotsContext();
  const { currency } = playerModel.usePlayer() ?? {};

  const isJackpotsWidgetVisible = useAppStore(
    ({ isJackpotsWidgetVisible }) => isJackpotsWidgetVisible,
  );

  const toggleJackpotsWidgetVisible = useAppStore(
    ({ toggleJackpotsWidgetVisible }) => toggleJackpotsWidgetVisible,
  );

  const spineDataShine = PIXI.Assets.cache.get("spine/jackpot/jp-shine/skeleton.json").spineData;
  const spineDataTitle = PIXI.Assets.cache.get(
    "spine/jackpot/jp-mini-game-title/skeleton.json",
  ).spineData;
  const spineAnimateBg = PIXI.Assets.cache.get(
    "spine/jackpot/jp-animate-bg/skeleton.json",
  ).spineData;

  const slotMini = PIXI.Assets.cache.get("lobby-screen.json").textures[`slot-mini`];
  const slotMajor = PIXI.Assets.cache.get("lobby-screen.json").textures[`slot-major`];
  const slotGrand = PIXI.Assets.cache.get("lobby-screen.json").textures[`slot-grand`];
  const slotUltimate = PIXI.Assets.cache.get("lobby-screen.json").textures[`slot-ultimate`];
  const background = PIXI.Assets.cache.get(`jackpots-bg.png`);

  const animationRef = useCallback((node: PIXI.Container) => {
    if (!node) return;

    gsap.to(node, {
      duration: 0.25,
      ease: "none",
      pixi: { alpha: 1 },
    });
  }, []);

  if (!slots || !isJackpotsWidgetVisible) {
    return null;
  }

  const pivot = isMobile ? { x: -background.width / 2, y: background.height / 2 } : { x: 0, y: 0 };
  const angle = isMobile ? 90 : 0;
  const anchor = isMobile ? { x: 0.5, y: 0.5 } : { x: 0, y: 0 };

  return (
    <Container key={background} name="jackpots-widget" alpha={0} ref={animationRef} zIndex={4}>
      <Sprite pivot={pivot} anchor={anchor} angle={angle} texture={background} interactive={true} />
      <AnimatedSpine
        position={{ y: 130, x: themeWidth / 2 }}
        isAnimating={true}
        spineData={spineDataTitle}
      />
      <AnimatedSpine
        position={{ y: 100, x: themeWidth / 2 }}
        timeScale={0.75}
        spineData={spineAnimateBg}
      />
      <Container
        interactiveChildren={false}
        name="slot-ultimate"
        position={themeConfig.jackpots.slotUltimate.position}
        scale={themeConfig.jackpots.scale}
      >
        <Sprite zIndex={2} texture={slotUltimate} />
        <AnimatedSpine
          position={{ y: 130, x: 60 }}
          skinName="ultimate"
          isAnimating={true}
          spineData={spineDataShine}
        />
        <Text
          anchor={0.5}
          position={{ x: 400, y: 140 }}
          style={styles.text}
          text={`${formatter.formatMoney(formatter.toMoney(slots.at(3)!.value)).toUpperCase()} ${currency}`}
        />
      </Container>
      <Container
        interactiveChildren={false}
        name="slot-grand"
        position={themeConfig.jackpots.slotGrand.position}
        scale={themeConfig.jackpots.scale}
      >
        <Sprite zIndex={2} texture={slotGrand} />
        <AnimatedSpine
          position={{ y: 140, x: 500 }}
          skinName="grand"
          isAnimating={true}
          spineData={spineDataShine}
        />
        <Text
          anchor={0.5}
          position={{ x: 190, y: 140 }}
          style={styles.text}
          text={`${formatter.formatMoney(formatter.toMoney(slots.at(2)!.value)).toUpperCase()} ${currency}`}
        />
      </Container>
      <Container
        interactiveChildren={false}
        name="slot-major"
        position={themeConfig.jackpots.slotMajor.position}
        scale={themeConfig.jackpots.scale}
      >
        <Sprite texture={slotMajor} />
        <AnimatedSpine position={{ y: 135, x: 110 }} skinName="major" spineData={spineDataShine} />
        <Text
          anchor={0.5}
          position={{ x: 400, y: 140 }}
          style={styles.text}
          text={`${formatter.formatMoney(formatter.toMoney(slots.at(1)!.value)).toUpperCase()} ${currency}`}
        />
      </Container>
      <Container
        interactiveChildren={false}
        name="slot-mini"
        position={themeConfig.jackpots.slotMini.position}
        scale={themeConfig.jackpots.scale}
      >
        <Sprite zIndex={2} texture={slotMini} />
        <AnimatedSpine position={{ y: 135, x: 470 }} skinName="mini" spineData={spineDataShine} />
        <Text
          anchor={0.5}
          position={{ x: 185, y: 140 }}
          style={styles.text}
          text={`${formatter.formatMoney(formatter.toMoney(slots.at(0)!.value)).toUpperCase()} ${currency}`}
        />
      </Container>
      <ButtonClose
        onClick={toggleJackpotsWidgetVisible}
        position={{ x: themeWidth - 150, y: themeConfig.header.height + 10 }}
      />
      {!!error && <AlertError handleOk={toggleJackpotsWidgetVisible} error={error} />}
    </Container>
  );
});

Jackpots.displayName = "Jackpots";

const styles = {
  text: new PIXI.TextStyle({
    dropShadowColor: COLOR.light_red,
    dropShadow: true,
    dropShadowDistance: 4,
    fontSize: 56,
    fill: 0xffffff,
    align: "center",
  }),
};
