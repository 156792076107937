import { memo } from "react";

import { usePosition, useThemeConfig } from "@shared/lib/hooks";
import { useModal } from "@shared/lib/services";
import { Modal, SmartContainer, Title } from "@shared/ui";
import { Tabs } from "@shared/ui/tabs";

import { useInfoState } from "../model/info.state";

import { InfoListbox } from "./info-listbox";

export const ModalInfoView = memo(() => {
  const { close, isOpen } = useModal("info");

  if (!isOpen) return null;

  return (
    <Modal
      name="info"
      onClose={close}
      render={(modalSpriteWidth, modalSpriteHeight) => (
        <Content modalSpriteWidth={modalSpriteWidth} modalSpriteHeight={modalSpriteHeight} />
      )}
    />
  );
});

const Content = memo(({ modalSpriteWidth, modalSpriteHeight }: TModalContent) => {
  const { isMobile } = useThemeConfig();
  const state = useInfoState();
  const scale = isMobile ? 2 : 1;

  const position = usePosition({
    width: modalSpriteWidth / scale,
    height: modalSpriteHeight / scale,
  });

  return isMobile ? (
    <>
      <SmartContainer scale={2}>
        <SmartContainer position={{ x: 0, y: -position.bottom.y / 2 }} paddingTop={45}>
          <Title text={state.t("info").toUpperCase()} />
        </SmartContainer>
        <SmartContainer
          paddingTop={250}
          paddingLeft={40}
          position={{ x: -position["right-center"].x / 2, y: -position.bottom.y / 2 }}
        >
          {state.currentTab.children}
        </SmartContainer>
        <SmartContainer
          centeredChild={true}
          position={{ x: 0, y: -position.bottom.y / 2 }}
          paddingTop={250}
          paddingLeft={30}
        >
          <InfoListbox {...state} />
        </SmartContainer>
      </SmartContainer>
    </>
  ) : (
    <>
      <SmartContainer position={{ x: 0, y: -position.bottom.y / 2 + 45 }}>
        <Title text={state.currentTab.label} />
      </SmartContainer>
      <Tabs
        isVertical={true}
        activeTab={state.currentTab}
        onTabChange={state.setCurrentTab}
        items={state.selectFilteredItems}
        tabPaddingLeft={-400}
        tabPaddingTop={-130}
        contentPaddingTop={-540}
        contentPaddingLeft={-100}
      />
    </>
  );
});

Content.displayName = "Content";
ModalInfoView.displayName = "ModalInfo";
