import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

import { apiClient } from "@shared/lib/api/api-client";

import type { Endpoint, Input, Output } from "@shared/lib/api/types";
import type { UseMutationOptions } from "@tanstack/react-query";

type MutationEndpoint = Endpoint;

type MutationOptions<E extends MutationEndpoint> = Omit<
  UseMutationOptions<Output<E>, unknown, Input<E>, unknown>,
  "mutationFn"
>;

const ERROR_PROPERTIES: Array<unknown> = ["isError", "error"];

export function useSmartMutation<E extends MutationEndpoint>(
  endpoint: E,
  options: MutationOptions<E> = {},
) {
  const isErrorHandledRef = useRef(false);

  const mutation = useMutation({
    mutationKey: [endpoint],
    ...options,
    mutationFn(input: Input<E>) {
      return apiClient.send({ endpoint, ...input });
    },
  });

  useEffect(() => {
    if (!mutation.error) {
      isErrorHandledRef.current = false;
      return;
    }

    setTimeout(() => {
      if (isErrorHandledRef.current) {
        return;
      }
    }, 100);
  }, [mutation.error]);

  return new Proxy(mutation, {
    get<T>(target: T, property: keyof T) {
      if (ERROR_PROPERTIES.includes(property)) {
        isErrorHandledRef.current = true;
      }
      return target[property];
    },
  });
}
