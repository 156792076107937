import { PixiComponent, useApp } from "@pixi/react";
import * as PIXI from "pixi.js";

import { ScrollView } from "./pixi/scrollview";

import type { IScrollView } from "./pixi/scrollview";
import type { ScrollBarView } from "@shared/lib";

interface IScrollViewProps extends IScrollView {
  onChange?: Nullable<(rate: number, scrollbar: ScrollBarView) => void>;
}

const PixiComponentScrollView = PixiComponent("ScrollView", {
  create: ({
    width,
    height,
    content,
    app,
    scrollType,
    paddingBetweenContentAndScrollBar,
    isScrollbarHidden,
    // onChange,
  }: IScrollViewProps) => {
    // if (onChange) {
    //   scrollbar.scrollbar.sliderEventEmitter.on("slider_change", (e) => {
    //     onChange(e.rate, scrollbar.scrollbar);
    //   });
    // }

    if (scrollType === "vertical" && content?.height) {
      if (height >= content.height) {
        return new PIXI.Container();
      }
    }

    return new ScrollView({
      content,
      app,
      width,
      height,
      scrollType,
      paddingBetweenContentAndScrollBar,
      isScrollbarHidden,
    });
  },
  willUnmount(instance: ScrollView) {
    instance?.removeChildren();
    instance?.destroy();
  },

  applyProps(instance, oldProps, newProps) {
    if (oldProps.content !== newProps.content) {
      if (instance instanceof ScrollView) {
        instance?.scrollbar?.updateSlider();
        instance?.scrollbar?.updateContentsPosition({ rate: 0 });
      }
    }
  },

  config: {
    destroy: true,
    destroyChildren: true,
  },
});

export const ScrollViewComponent = ({
  width,
  height,
  content,
  scrollType,
  onChange,
  paddingBetweenContentAndScrollBar,
  isScrollbarHidden,
}: Omit<IScrollViewProps, "app">) => {
  const app = useApp();

  if (!content) {
    return null;
  }

  return (
    <PixiComponentScrollView
      onChange={onChange}
      isScrollbarHidden={isScrollbarHidden}
      paddingBetweenContentAndScrollBar={paddingBetweenContentAndScrollBar}
      scrollType={scrollType}
      app={app}
      width={width}
      height={height}
      content={content}
    />
  );
};
