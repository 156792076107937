import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useRef } from "react";

import { useAppStore } from "@app/providers";
import { useSmartMutation, useSmartMutationState } from "@shared/lib/api";
import { useSmartMutationClear } from "@shared/lib/api/use-smart-mutation-clear";
import { PROVIDER_ID_LIVE } from "@shared/lib/consts";
import { useBackdropSound, useThemeConfig } from "@shared/lib/hooks";
import {
  localStorageService,
  useGameStringId,
  useModal,
  useProviderId,
} from "@shared/lib/services";
import { get } from "@shared/lib/utils";

import type { StartupGameSchema } from "./startup.game.form.schema";

export function useGameController() {
  const { isSafariOrIos } = useThemeConfig();
  const { open } = useModal("select-limit");
  const bgm = useBackdropSound();
  const queryClient = useQueryClient();
  const { providerId } = useProviderId();
  const setIsLayoutLoading = useAppStore(({ setIsLayoutLoading }) => setIsLayoutLoading);
  const setGameOpenError = useAppStore(({ setGameOpenError }) => setGameOpenError);
  const { setGameStringId, gameStringId } = useGameStringId();

  const isLiveBrand = providerId === PROVIDER_ID_LIVE;
  const isIOSLIVE = isLiveBrand && isSafariOrIos;
  const isLocalStorageSoundOn = Boolean(localStorageService.getData("sound"));
  const refWindow = useRef<Window | null>(null);

  const mutationStartupGame = useSmartMutation("POST Game.run", {
    gcTime: Infinity,
    onMutate: () => {
      setIsLayoutLoading(true);
    },
    onSuccess: (data) => {
      setIsLayoutLoading(false);

      if (isIOSLIVE && refWindow.current) {
        refWindow.current.location.href = data.results[0].response.link;
        handleGameStop();
      } else {
        bgm.mute();
      }
    },
    onError: (error) => {
      queryClient.invalidateQueries({ queryKey: ["POST Game.list"] });
      setGameStringId(undefined);
      setIsLayoutLoading(false);
      setGameOpenError(get(error, "message", "unknown error"));
      refWindow.current?.close();
    },
  });

  const { clearMutation } = useSmartMutationClear({ mutation: mutationStartupGame });

  const handleSubmit = useCallback(
    (gameStartupParams: StartupGameSchema) => {
      mutationStartupGame.mutate({ body: gameStartupParams });
    },
    [mutationStartupGame],
  );

  const sourceHandlerSubmit = isLiveBrand ? (_: unknown) => open() : handleSubmit;

  const handleGameStart = useCallback(
    (stringId: string) => {
      setGameStringId(stringId);

      sourceHandlerSubmit({
        place: "game_list",
        game: stringId,
        lang: "en",
        demo: false,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setGameStringId],
  );

  const handleGameStop = useCallback(() => {
    clearMutation();
    setGameStringId(undefined);

    if (!isIOSLIVE && isLocalStorageSoundOn) {
      bgm.unmute();
    }
  }, [bgm, clearMutation, isLocalStorageSoundOn, setGameStringId, isIOSLIVE]);

  const gameUrlState = useSmartMutationState({
    filters: { mutationKey: ["POST Game.run"], status: "success" },
    select: (mutation) => mutation.state.data?.results[0].response.link,
  }) as Array<string>;

  const currentGameUrl = useMemo(() => {
    return gameUrlState.at(-1);
  }, [gameUrlState]);

  return {
    refWindow,
    handleSubmit,
    gameStringId,
    setGameStringId,
    mutationStartupGame,
    currentGameUrl,
    handleGameStart,
    handleGameStop,
  };
}
