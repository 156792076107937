export const COLOR = {
  light_red: "#f64511d6",
  gold: "#f3e942",
  orange: "#e88828",
  orange_gold: "#fab520",
  violet: "#9e6ce5",
  violetShadow: "#955cff",
  white: "#ffffff",
  black: "#010101",
  empty: "#00000000",
} satisfies { [key: string]: string };
