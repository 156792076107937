import { Container, Graphics, Text } from "@pixi/react";
import { TextStyle } from "pixi.js";
import { useMemo } from "react";

import { bonusModel } from "@entities/bonus";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { formatter } from "@shared/lib/utils";
import { COLOR, QueryErrorAlert, withScroll } from "@shared/ui";

interface TableColumn<T> {
  key: keyof T;
  label: string;
  transform: (value: T[keyof T]) => string;
  coeffWidth: number;
}

interface TableRow {
  issuedAt: number;
  status: "activated" | "pending";
  type: "cashback" | "bounceback" | "lastchance";
  amount: number;
  wager: number;
  remainingWager: number | null;
}

export const Table = withScroll(({ scrollWidth }) => {
  const { t } = useTranslate();
  const { fonts, isMobile } = useThemeConfig();
  const { bonuses, query } = bonusModel.useBonusAndWagers();

  const dateToLocaleString = (date: number) => new Date(date * 1000).toLocaleString();
  const dateToLocalDateString = (date: number) => new Date(date * 1000).toLocaleDateString();

  const toDate = isMobile ? dateToLocalDateString : dateToLocaleString;

  const columns: TableColumn<TableRow>[] = useMemo(
    () => [
      {
        key: "issuedAt",
        label: t("Date"),
        transform: (value) => (typeof value === "number" ? toDate(value) : String(value)),
        coeffWidth: 0.2,
      },
      {
        key: "type",
        label: t("Bonus"),
        transform: (value) => String(value),
        coeffWidth: 0.25,
      },
      {
        key: "amount",
        label: t("Amount"),
        transform: (value) => formatter.formatMoney(formatter.toMoney(Number(value))),
        coeffWidth: isMobile ? 0.25 : 0.2,
      },
      {
        key: "wager",
        label: t("Wager"),
        transform: (value) => formatter.formatMoney(formatter.toMoney(Number(value))),
        coeffWidth: isMobile ? 0.22 : 0.2,
      },
      {
        key: "status",
        label: t("Status"),
        transform: (value) => String(value),
        coeffWidth: 0.2,
      },
    ],
    [isMobile, t, toDate],
  );

  if (!bonuses) {
    return null;
  }

  return (
    <Container x={10} y={40}>
      {columns.map((column, index) => (
        <Container key={column.key}>
          <Text
            x={index * scrollWidth * column.coeffWidth}
            text={column.label}
            style={
              new TextStyle({
                ...fonts.primary,
                fontWeight: "bolder",
                fontSize: 20,
              })
            }
          />
          <Graphics
            draw={(g) => {
              g.clear();
              g.moveTo(0, 30);
              g.lineStyle(2, "#eca90a");
              g.lineTo(scrollWidth, 30);
              g.moveTo(0, 0);
            }}
          />
        </Container>
      ))}
      {(bonuses as TableRow[]).map((row, rowIndex) => (
        <Container key={row.issuedAt} y={(rowIndex + 1) * 35}>
          {columns.map((column, columnIndex) => (
            <Container key={column.key}>
              <Text
                x={columnIndex * scrollWidth * column.coeffWidth}
                text={column.transform(row[column.key])}
                style={
                  new TextStyle({
                    ...fonts.secondary,
                    fill: COLOR.white,
                    wordWrapWidth: scrollWidth,
                    fontSize: 16,
                  })
                }
              />
              <Graphics
                draw={(g) => {
                  g.clear();
                  g.moveTo(0, 30);
                  g.lineStyle(2, fonts.primary.fill[0]);
                  g.lineTo(scrollWidth, 30);
                  g.moveTo(0, 0);
                }}
              />
            </Container>
          ))}
        </Container>
      ))}
      <QueryErrorAlert query={query} />
    </Container>
  );
});

Table.displayName = "Table";
