import { applyDefaultProps, PixiComponent } from "@pixi/react";

import { TextInput } from "@shared/ui/controls/input/custom-input";

import type { IPointData } from "pixi.js";

type Props = {
  onChange?: (text: string) => void;
  onEnter?: (text: string) => void;
  placeholder?: string;
  anchor?: IPointData;
  position?: IPointData;
  width?: number;
  height?: number;
  value?: string;
  type: "text" | "password";
  scale?: number;
  isFocused?: boolean;
};

export const PixiInput = PixiComponent("Input", {
  create: (props: Props) => {
    const { onChange, placeholder, width, anchor, position, scale, type, height, value } = props;

    const input = new TextInput(
      {
        input: {
          fontSize: "38px",
          textAlign: "center",
          padding: "22px",
          width: width,
          color: "#ffffff",
        },
        box: {
          default: { fill: 0x162f83, rounded: 12, stroke: { color: 0x162f83, width: 3 } },
          focused: { fill: 0x162f83, rounded: 12, stroke: { color: 0xffffff, width: 3 } },
          disabled: { fill: 0x162f83, rounded: 12 },
        },
      },
      type,
    );

    input.placeholder = placeholder;

    if (width) {
      input.width = width;
    }

    if (height) {
      input.height = height;
    }

    if (anchor || position) {
      input.setTransform(position?.x, position?.y);
    }

    if (scale) {
      input.scale.set(scale);
    }

    input.on("input", (text: string) => {
      if (onChange) {
        onChange(text);
      }
    });

    if (value && onChange) {
      onChange(value);
      input.text = value;
    }

    return input;
  },

  // didMount(instance) {
  //   instance.focus();
  // },

  applyProps(instance: TextInput, oldProps: Readonly<Props>, newProps: Readonly<Props>) {
    if (oldProps.isFocused) {
      instance.focus();
    }

    applyDefaultProps(instance, oldProps, newProps);
  },

  config: {
    destroy: true,
    destroyChildren: true,
  },
});
