import { sound, type PlayOptions, type Sound } from "@pixi/sound";

import { localStorageService } from "@shared/lib/services";

import { BACKGROUND_VOLUME_DEFAULT } from "../../consts";

class BGM {
  public isPlay: boolean;

  private sound: Nullable<Sound>;
  private _volume: number;

  constructor() {
    this.isPlay = false;
    this.sound = null;
    this._volume = localStorageService.getData("bgm") ?? BACKGROUND_VOLUME_DEFAULT;
    this.play = this.play.bind(this);
    this.stop = this.stop.bind(this);
  }

  public play(options?: PlayOptions) {
    if (!this.sound) {
      this.sound = sound.find("sounds/backdrop.mp3");
    }

    if (this.sound.isPlaying || this.isPlay) {
      return;
    }

    this.sound.play({
      loop: true,
      ...options,
      volume: 1,
    });

    this.volume = this._volume ?? 1;

    this.isPlay = true;
  }

  public stop() {
    if (this.sound) {
      this.sound.stop();
      this.isPlay = false;
    }
  }

  mute(cb?: VoidFunction) {
    if (this.sound) {
      this.sound.muted = true;
      this.isPlay = false;
      cb?.();
    }
  }

  unmute(cb?: VoidFunction) {
    if (this.sound) {
      this.sound.muted = false;
      this.isPlay = true;
      cb?.();
    }
  }

  set volume(volume: number) {
    if (this.sound) {
      const computedVolume = Number(volume.toFixed(2));
      this.sound.volume = computedVolume;
      this._volume = computedVolume;
    }
  }

  get volume() {
    return this._volume;
  }
}

export const bgm = new BGM();
