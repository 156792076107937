import { Container, Text } from "@pixi/react";
import { TextStyle } from "pixi.js";
import { memo } from "react";

import { playerModel } from "@entities/player";
import { useThemeConfig } from "@shared/lib/hooks/use-theme-config";

import { PLAYER_NAME_MAX_WIDTH_DESKTOP, PLAYER_NAME_MAX_WIDTH_MOBILE } from "../lib/const";
import { processText } from "../lib/helpers";

export const PlayerLogin = memo(() => {
  const { name } = playerModel.usePlayer();
  const { themeConfig, fonts, isMobile } = useThemeConfig();

  const textStyle = new TextStyle({
    ...fonts.primary,
    fontSize: themeConfig.header.login.fontSize,
  });

  return (
    <Container position={themeConfig.header.login.position}>
      <Text
        anchor={{ x: 0, y: 0.5 }}
        style={
          new TextStyle({
            ...fonts.primary,
            fontSize: themeConfig.header.login.fontSize,
          })
        }
        text={processText(
          String(name),
          isMobile ? PLAYER_NAME_MAX_WIDTH_MOBILE : PLAYER_NAME_MAX_WIDTH_DESKTOP,
          textStyle,
        )}
        name="PlayerLogin"
      />
    </Container>
  );
});

PlayerLogin.displayName = "PlayerLogin";
