import { memo, useContext, useEffect, useState } from "react";

import { AssetsContext } from "@app/providers";
import { ProgressLoaderHTML } from "@shared/ui";

import type { AssetsBundlesName } from "@app/providers";

type TProps = {
  bundleNames: AssetsBundlesName[];
  children: (isLoaded: boolean) => React.ReactNode;
};

export const AsyncLoadableBundle = memo(({ children, bundleNames }: TProps) => {
  const { progress, loadBundles } = useContext(AssetsContext);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const load = async () => {
      await loadBundles(bundleNames);
      setIsLoaded(true);
    };

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (progress !== 1) {
    return <ProgressLoaderHTML progress={progress} />;
  }

  return <>{children(isLoaded)}</>;
});

AsyncLoadableBundle.displayName = "AsyncLoadable";
