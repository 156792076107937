import { useAppStore } from "@app/providers";
import { SpinnerWithBg } from "@shared/ui";

export const SpinnerLayout = () => {
  const isLayoutLoading = useAppStore((state) => state.isLayoutLoading);

  if (isLayoutLoading) {
    return <SpinnerWithBg />;
  }

  return null;
};
