import { get } from "@shared/lib/utils";
import { AlertError } from "@shared/ui/error-queries/alert-error";

import type { UseQueryResult } from "@tanstack/react-query";
import type * as PIXI from "pixi.js";

interface IProps<TData, TError> {
  query: UseQueryResult<TData, TError>;
  position?: PIXI.IPointData;
}

export function QueryErrorAlert<TData, TResult>({ query, position }: IProps<TData, TResult>) {
  const error = get(query.error, "message", "Unknown error");

  if (query.isError) {
    return <AlertError position={position} handleOk={query.refetch} error={error} />;
  }

  return null;
}
