import { createContext, useEffect, useState } from "react";

import { wheelFortuneModel } from "@entities/wheel-fortune";
import { usePosition } from "@shared/lib/hooks";
import { MutationErrorAlert, QueryErrorAlert, SmartContainer, Spinner } from "@shared/ui";

import type { IDailyWheelFortuneSettings } from "@shared/lib/api/types";
import type { PropsWithChildren } from "react";

export type TWheelGameStatus = "spin-stop" | "spin-start" | "spin-finished";

export interface IDailyWheelContext {
  updateSpinStatus: (status: TWheelGameStatus) => void;
  spinStatus: TWheelGameStatus;
  settings: Nullable<IDailyWheelFortuneSettings>;
  handleWin: VoidFunction;
}

const initialAppContextValue: IDailyWheelContext = {
  updateSpinStatus: () => null,
  settings: null,
  handleWin: () => null,
  spinStatus: "spin-stop",
};

export const DailyWheelContext = createContext<IDailyWheelContext>(initialAppContextValue);

export const DailyWheelContextProvider = ({ children }: PropsWithChildren) => {
  const position = usePosition();
  const [reelStatus, setReelStatus] = useState(initialAppContextValue.spinStatus);
  const { settings, handleWin, isLoading, queryDailyWheelSettings, mutationDailyWheelGetWin } =
    wheelFortuneModel.useWheelFortuneSettings();

  useEffect(() => {
    if (mutationDailyWheelGetWin.error) {
      setReelStatus("spin-stop");
    }
  }, [mutationDailyWheelGetWin.error]);

  if (isLoading) {
    return <Spinner position={position.center} />;
  }

  return (
    <DailyWheelContext.Provider
      value={{
        spinStatus: reelStatus,
        settings,
        handleWin,
        updateSpinStatus: setReelStatus,
      }}
    >
      {children}
      <SmartContainer paddingBottom={120} position={position["center"]}>
        <QueryErrorAlert query={queryDailyWheelSettings} />
      </SmartContainer>
      <SmartContainer paddingBottom={120} position={position["center"]}>
        <MutationErrorAlert mutation={mutationDailyWheelGetWin} />
      </SmartContainer>
    </DailyWheelContext.Provider>
  );
};
