import { utils } from "pixi.js";

import { SliderView } from "@shared/lib/vendors/pixi/scrollview/SliderView";
import { SliderViewUtil } from "@shared/lib/vendors/pixi/scrollview/SliderViewUtil";

import type { ScrollBarContentsEventType } from "./index";
import type { Container, DisplayObject, Graphics } from "pixi.js";

export class ScrollBarContents extends utils.EventEmitter<ScrollBarContentsEventType> {
  get target(): DisplayObject {
    return this._target;
  }

  set target(value: DisplayObject) {
    this._target = value;
    this.emit("changed_contents_size");
  }

  private _target: DisplayObject;

  get mask(): Graphics {
    return this._mask as Graphics;
  }

  set mask(value: Graphics) {
    this._mask = value;
    this.emit("changed_contents_size");
  }

  private _mask: Graphics;

  /**
   * @param target Содержимое для прокрутки
   * @param mask Маска для вырезания цели
   * @param container Родительский контейнер, в котором хранятся контент и маска
   */
  constructor(
    target: DisplayObject,
    mask: Graphics,
    public container: Container,
  ) {
    super();
    this._target = target;
    this._mask = mask;
    ScrollBarContents.init(this);
  }

  private static init(scrollBarContents: ScrollBarContents): void {
    if (scrollBarContents._target.mask !== scrollBarContents._mask) {
      scrollBarContents._target.mask = scrollBarContents._mask;
    }

    const addToContainer = (displayObject: DisplayObject) => {
      if (displayObject.parent === scrollBarContents.container) return;

      displayObject.parent?.removeChild(displayObject);
      scrollBarContents.container.addChild(displayObject);
    };
    addToContainer(scrollBarContents._target);
    addToContainer(scrollBarContents._mask);
  }

  /**
   * @param isHorizontal
   */
  public getScrollPositionAsRate(isHorizontal: boolean): number {
    const getPos = SliderViewUtil.getPosition;
    const zeroPos = getPos(this.mask, isHorizontal);
    const contentsPos = getPos(this.target, isHorizontal);
    const contentsPositionDif = zeroPos - contentsPos;

    const movableRange = this.getMovableRange(isHorizontal);

    return (contentsPositionDif / movableRange) * SliderView.MAX_RATE;
  }

  /**
   * Получить максимальную область прокрутки. единица измерения пикселей
   * Если диапазон движения меньше или равен нулю пикселей, возвращается минимальное положительное значение.
   *
   * @param isHorizontal
   * @private
   */
  private getMovableRange(isHorizontal: boolean): number {
    const getSize = SliderViewUtil.getSize;
    const targetSize = getSize(this._target, isHorizontal);
    const maskSize = getSize(this._mask, isHorizontal);
    const dif = targetSize - maskSize;
    if (dif <= 0.0) {
      return 1e-128;
    }
    return dif;
  }

  /**
   * Прокручивать контент до указанной скорости
   *
   * @param rate
   * @param isHorizontal
   */
  public scroll(rate: number, isHorizontal: boolean): void {
    const getPos = SliderViewUtil.getPosition;
    const zeroPos: number = getPos(this._mask, isHorizontal);
    const movableRange = this.getMovableRange(isHorizontal);
    const contentsPos = zeroPos - movableRange * (rate / SliderView.MAX_RATE);

    SliderViewUtil.setPosition(this._target, isHorizontal, contentsPos);
  }

  /**
   * Получите соотношение количества контента, отображаемого в области отображения.
   * Это соотношение становится масштабом кнопки полосы прокрутки.
   *
   * Пример: 0,5, если размер контента равен 200, а область отображения — 100.
   * 1.0, если отображается весь контент
   *
   * @param isHorizontal
   * @return 0.0 ~ 1.0
   */
  public getDisplayRate(isHorizontal: boolean): number {
    const getSize = SliderViewUtil.getSize;
    const contentsSize = getSize(this.target, isHorizontal);
    const maskSize = getSize(this.mask, isHorizontal);
    return SliderViewUtil.clamp(maskSize / contentsSize, SliderView.MAX_RATE, 0.0);
  }

  public dispose(): void {
    this.removeAllListeners();
  }
}
