import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

import { useSmartMutation, useSmartMutationState, useSmartQuery } from "@shared/lib/api";

class BonusModel {
  useIsAnimationBonusTakeSuccess = () => {
    const bonusStatus = useSmartMutationState({
      filters: { mutationKey: ["POST Bonus.use"], status: "success" },
      select: (mutation) => mutation.state.data?.results[0].response.status,
    }) as Array<string>;

    return useMemo(() => bonusStatus?.at(-1) === "activated", [bonusStatus]);
  };
  useActiveBonus = () => {
    const { data, isLoading, isError } = useSmartQuery(
      "POST Bonus.getActive",
      { body: {} },
      { refetchInterval: 15_000 },
    );
    const bonus = data?.results[0].response.bonus;
    return { bonus, isLoading, isError, isAvailable: data?.results[0].response.available };
  };

  useHandleBonus = () => {
    const queryClient = useQueryClient();

    const useBonusMutation = useSmartMutation("POST Bonus.use", {
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ["POST Auth.user"] });
        queryClient.invalidateQueries({ queryKey: ["POST Bonus.getActive"] });
      },
    });

    const handleBonusUse = useCallback(() => {
      useBonusMutation.mutate({ body: {} });
    }, [useBonusMutation]);

    return { handleBonusUse, useBonusMutation };
  };

  useBonusAndWagers = () => {
    const query = useSmartQuery("POST Bonus.getAccepted", { body: {} });
    const data = query?.data?.results[0].response;

    return {
      query,
      bonuses: data?.bonus,
      wager: data?.wager ?? 0,
      remainingWager: data?.remaining_wager ?? 0,
      isLoading: query.isLoading,
      isError: query.isError,
    };
  };
}

export const bonusModel = new BonusModel();
