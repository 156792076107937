type EndpointMap = {
  "POST Auth.user": {
    input: {
      params?: undefined;
      query?: undefined;
      body: {
        isUserActive?: boolean;
      };
    };
    output: {
      results: [
        {
          success: boolean;
          response: {
            casino_id: number;
            remote_id: string;
            bank_group_id: number;
            currency: string;
            displayable_name: string;
            name: string;
            balance: number | null;
            balanceBet?: number;
            balanceWin?: number;
            balanceBonus?: number;
            balanceDeposit?: number;
            balanceRedeem?: number;
            bonus_balance: null | number;
            wager_counter: null | number;
            email: string;
            phone: string;
            active: boolean;
            member_card: string;
            delete_time: null | number;
            bankGroup: {
              id: number;
              string_id: string;
              name: string;
              active: boolean;
              currency: string;
              sweepstakes_multiplier: number;
              wager_coeff: number;
              bonuses: {
                cashback?: Cashback;
                bounceback?: Bounceback;
                lastchance?: Lastchance;
              };
              jackpots: {
                enabled: boolean;
                version: number;
              };
              fortuneWheel: {
                isActive: boolean;
                nextWinTime: number;
              };
            };
            lobby: {
              isPanic: boolean;
              panicUrl: string;
            };
          };
        },
      ];
    };
  };
  "POST Auth.login": {
    input: {
      params?: undefined;
      query?: undefined;
      body: {
        login: string;
        password: string;
        remember: boolean;
      };
    };
    output: {
      results: [
        {
          success: boolean;
        },
      ];
    };
  };
  "POST Auth.userUpdate": {
    input: {
      params?: undefined;
      query?: undefined;
      body: {
        name: string;
        email: string;
        phone: string;
      };
    };
    output: {
      results: NonNullable<unknown>;
    };
  };
  "POST Auth.changePassword": {
    input: {
      params?: undefined;
      query?: undefined;
      body: {
        oldPassword: string;
        newPassword: string;
      };
    };
    output: {
      results: NonNullable<unknown>;
    };
  };
  "POST Auth.logout": {
    input: {
      params?: undefined;
      query?: undefined;
      body?: NonNullable<unknown>;
    };
    output: {
      results: [
        {
          success: boolean;
        },
      ];
    };
  };
  "POST Lobby.getParams": {
    "input": {
      params?: undefined;
      query?: undefined;
      body?: NonNullable<unknown>;
    };
    "output": {
      results: [
        {
          success: boolean;
          response: {
            casinoId: number;
            directRegistration: boolean;
            facebookRegistration: boolean;
            sweepstakes: boolean;
            bankGroupId: number;
            currency: string;
            lobbyId: number;
            lobbyButtons: {
              exit: {
                enabled: boolean;
              };
              deposit: {
                enabled: boolean;
              };
              withdraw: {
                enabled: boolean;
              };
              registration: {
                enabled: boolean;
              };
              contacts: {
                enabled: boolean;
                data: null;
              };
              ssUpdateBalance: {
                enabled: boolean;
              };
            };
            contacts: string;
            slider: {
              banners: {
                id: number;
                type: string;
                bonusId: null | number;
                backgroundImage: {
                  desktop: string;
                  mobile: string;
                };
                titles: Array<{
                  lang: string;
                  value: string;
                }>;
                subtitles: Array<{
                  lang: string;
                  value: string;
                }>;
                action: null | {
                  type: "provider" | "game" | "tag";
                  id: string;
                };
              };
            };
          };
        },
      ];
    };
    "POST Lobby.getWsStream": {
      input: {
        params?: undefined;
        query?: undefined;
        body: {
          defaultBankGroup: string;
        };
      };
      output: {
        results: [
          {
            success: boolean;
            count: number;
            response: {
              playerToken: string;
              stream: string;
            };
          },
        ];
      };
    };
    "POST Game.list": {
      input: {
        params?: undefined;
        query?: undefined;
        body?: NonNullable<unknown>;
      };
      output: {
        results: [
          {
            success: boolean;
            count: number;
            response: {
              list: [
                {
                  close_button: boolean;
                  default_config_id: number;
                  delete_time: number | null;
                  description: string | null;
                  enabled: boolean;
                  external_jackpot: boolean;
                  freeround: boolean;
                  game_provider_id: number;
                  game_section_id: number;
                  id: number;
                  internal_jackpot: boolean;
                  is_favorite: boolean;
                  jackpot_slots_count: number;
                  name: string;
                  pic: string;
                  popularity: number;
                  string_id: string;
                  tags: [
                    {
                      id: number;
                      name: string;
                      priority: number;
                    },
                  ];
                },
              ];
            };
          },
        ];
      };
    };
    "POST Game.tagList": {
      input: {
        params?: undefined;
        query?: undefined;
        body: {
          bankGroupStringId: number;
        };
      };
      output: {
        results: [
          {
            success: boolean;
            response: {
              totalCount: number;
              count: number;
              list: [
                {
                  id: number;
                  name: string;
                  priority: number;
                },
              ];
            };
          },
        ];
      };
    };
    "POST Game.run": {
      input: {
        params?: undefined;
        query?: undefined;
        body: {
          demo: boolean;
          game: string;
          lang: string;
          place: TGameStartupSection;
        };
      };
      output: {
        results: [
          {
            success: boolean;
            count: number;
            response: {
              link: string;
              session_id: string;
            };
          },
        ];
      };
    };
  };
  "POST Lobby.getWsStream": {
    input: {
      params?: undefined;
      query?: undefined;
      body: {
        defaultBankGroup: string;
      };
    };
    output: {
      results: [
        {
          success: boolean;
          count: number;
          response: {
            playerToken: string;
            stream: string;
          };
        },
      ];
    };
  };
  "POST Rules.getRules": {
    input: {
      params?: undefined;
      query?: undefined;
      body?: NonNullable<unknown>;
    };
    output: {
      results: [
        {
          success: boolean;
          response: {
            type: TRuleTypes;
            text: string;
            version: string;
            lastUpdate: Date;
          }[];
        },
      ];
    };
  };
  "POST Rules.getNeedAccept": {
    input: {
      params?: undefined;
      query?: undefined;
      body?: NonNullable<unknown>;
    };
    output: {
      results: [
        {
          success: boolean;
          response: {
            type: "TermsAndConditions" | "SweepstakesRules";
            text: string;
            version: string;
            lastUpdate: Date;
          }[];
        },
      ];
    };
  };
  "POST Rules.accept": {
    input: {
      params?: undefined;
      query?: undefined;
      body?: {
        type: TRuleTypes;
      };
    };
    output: {
      results: [
        {
          success: boolean;
          response: null;
        },
      ];
    };
  };
  "POST Bonus.getAccepted": {
    input: {
      params?: undefined;
      query?: undefined;
      body?: NonNullable<unknown>;
    };
    output: {
      results: [
        {
          success: boolean;
          response: {
            balance: number;
            bonus: {
              issuedAt: number;
              status: string;
              type: string;
              amount: number;
              wager: number;
              remainingWager: number | null;
            }[];
            remaining_wager: number;
            wager: number;
          };
        },
      ];
    };
  };
  "POST Bonus.getActive": {
    input: {
      params?: undefined;
      query?: undefined;
      body?: NonNullable<unknown>;
    };
    output: {
      results: [
        {
          success: boolean;
          response: {
            bonus_amount: number;
            deposit_amount: number;
            remaining_wager: number;
            available: boolean;
            bonus: {
              id: number;
              string_id: BonusType;
              type: BonusType;
              bonus_id: number;
              amount: number;
            };
          };
        },
      ];
    };
  };
  "POST Bonus.use": {
    input: {
      params?: undefined;
      query?: undefined;
      body?: NonNullable<unknown>;
    };
    output: {
      results: [
        {
          success: boolean;
          response: {
            status: "activated" | "pending";
          };
        },
      ];
    };
  };
  "POST Game.list": {
    input: {
      params?: undefined;
      query?: undefined;
      body?: NonNullable<unknown>;
    };
    output: {
      results: [
        {
          success: boolean;
          count: number;
          response: {
            list: [
              {
                close_button: boolean;
                default_config_id: number;
                delete_time: number | null;
                description: string | null;
                enabled: boolean;
                external_jackpot: boolean;
                freeround: boolean;
                game_provider_id: number;
                game_section_id: number;
                id: number;
                internal_jackpot: boolean;
                is_favorite: boolean;
                jackpot_slots_count: number;
                name: string;
                pic: string;
                popularity: number;
                string_id: string;
                tags: [
                  {
                    id: number;
                    name: string;
                    priority: number;
                  },
                ];
              },
            ];
          };
        },
      ];
    };
  };
  "POST Game.getRecommendations": {
    input: {
      params?: undefined;
      query?: undefined;
      body?: NonNullable<unknown>;
    };
    output: {
      results: [
        {
          success: boolean;
          response: {
            games: number[];
          };
        },
      ];
    };
  };
  "POST Game.tagList": {
    input: {
      params?: undefined;
      query?: undefined;
      body: {
        bankGroupStringId: number;
      };
    };
    output: {
      results: [
        {
          success: boolean;
          response: {
            totalCount: number;
            count: number;
            list: ITag[];
          };
        },
      ];
    };
  };
  "POST Game.providerList": {
    input: {
      params?: undefined;
      query?: undefined;
      body?: NonNullable<unknown>;
    };
    output: {
      results: [
        {
          success: boolean;
          response: {
            id: number;
            name: string;
            string_id: string;
          }[];
        },
      ];
    };
  };
  "POST Game.run": {
    input: {
      params?: undefined;
      query?: undefined;
      body: {
        demo: boolean;
        game: string;
        lang: string;
        place: TGameStartupSection;
        vig_table_id?: string;
        vig_limitname?: string;
      };
    };
    output: {
      results: [
        {
          success: boolean;
          count: number;
          response: {
            link: string;
            session_id: string;
          };
        },
      ];
    };
  };
  "POST Game.vigTableList": {
    input: {
      params?: undefined;
      query?: undefined;
      body: {
        game: string;
      };
    };
    output: {
      results: [
        {
          success: boolean;
          response: {
            totalCount: number;
            count: number;
            list: {
              tableId: string;
              limits: [
                {
                  limitName: string;
                  min: string;
                  max: string;
                },
              ];
            }[];
          };
        },
      ];
    };
  };
  "POST FortuneWheel.getSettings": {
    input: {
      params?: undefined;
      query?: undefined;
      body: NonNullable<unknown>;
    };
    output: {
      results: [
        {
          success: boolean;
          response: {
            settings: IDailyWheelFortuneSettings;
          };
        },
      ];
    };
  };
  "POST FortuneWheel.getWin": {
    input: {
      params?: undefined;
      query?: undefined;
      body?: NonNullable<unknown>;
    };
    output: {
      results: [
        {
          success: boolean;
          response: IDailyWheelWin;
        },
      ];
    };
  };
};

export type Endpoint = keyof EndpointMap;

export type Input<E extends Endpoint> = EndpointMap[E]["input"];

export type Output<E extends Endpoint> = EndpointMap[E]["output"];

export type TGameStartupSection = "game_list" | "favourites" | "recommendation" | "banner";

export type Cashback = {
  value: number;
  wager: boolean;
  threshold: number;
  cooldown: number;
  minDeposit: number;
  maxDeposit: number;
  happyHours?: {
    enabled: boolean;
    boost: number;
    from: string;
    to: string;
  };
};

export type Bounceback = {
  value: number;
  wager: boolean;
  threshold: number;
  cooldown: number;
  minDeposit: number;
  maxDeposit: number;
  happyHours?: {
    enabled: boolean;
    boost: number;
    from: string;
    to: string;
  };
};

export type Lastchance = {
  value: number;
  wager: boolean;
  threshold: number;
  cooldown: number;
};

export type TBonuses = Cashback | Bounceback | Lastchance;
export type TRuleTypes = "TermsAndConditions" | "SweepstakesRules";

export const enum BonusType {
  cashback = "cashback",
  bounceback = "bounceback",
  lastchance = "lastchance",
}

export enum JackpotsType {
  "mini",
  "major",
  "grand",
  "ultimate",
}

export interface ISlot {
  isCommunity: boolean | null;
  jackpotId: string;
  value: number;
  slot: number;
  name: string;
}

export interface IWin {
  value: number;
  id: number;
  state: "pending" | "paid" | "canceled";
  isViewed?: boolean;
  isCommunity?: boolean;
  jackpotId: string;
  slot: number;
}

export interface IDailyWheelFortuneSettings {
  rules: IDailyWheelRules;
  sectors: IDailyWheelSector[];
}

export interface IDailyWheelSector {
  id: number;
  value: number;
}

export interface IDailyWheelRules {
  daily: string;
  general: string;
}

export interface IDailyWheelWin {
  sectorId: number;
  win: number;
}

export interface ITag {
  id: number;
  name: string;
  priority: number;
}
