import { Container, Text } from "@pixi/react";
import { TextStyle } from "pixi.js";

import { useContacts } from "@entities/lobby";
import { COLOR } from "@shared/ui";

export const Contacts = () => {
  const { contactsResponseText } = useContacts();

  return (
    <Container y={40}>
      <Text
        text={contactsResponseText ?? ""}
        style={
          new TextStyle({
            fontWeight: "lighter",
            fontSize: 20,
            wordWrapWidth: 750,
            wordWrap: true,
            breakWords: true,
            fill: COLOR.white,
          })
        }
      />
    </Container>
  );
};
