import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

import { apiClient } from "./api-client";

import type { Endpoint, Input, Output } from "./types";
import type { UseQueryOptions } from "@tanstack/react-query";

type QueryEndpoint = Endpoint;

type QueryKey<E extends QueryEndpoint> = [
  endpoint: E,
  params: Input<E>["params"],
  query: Input<E>["query"],
];

type QueryOptions<E extends QueryEndpoint> = Omit<
  UseQueryOptions<Output<E>, Error, Output<E>, QueryKey<E>>,
  "queryKey" | "queryFn" | "initialData"
>;

const ERROR_PROPERTIES: Array<unknown> = ["isError", "error"];

export function useSmartQuery<E extends QueryEndpoint>(
  endpoint: E,
  input: Input<E>,
  options: QueryOptions<E> = {},
) {
  // const { message } = App.useApp();

  const isErrorHandledRef = useRef(false);

  const queryKey = [endpoint, input.params, input.query] as QueryKey<E>;
  const query = useQuery({
    ...options,
    queryKey,
    queryFn({ signal }) {
      return apiClient.send({ endpoint, ...input, signal });
    },
  });

  useEffect(() => {
    if (!query.error) {
      isErrorHandledRef.current = false;
      return;
    }

    // const error = query.error;

    setTimeout(() => {
      if (!isErrorHandledRef.current) {
        return;
      }

      // message.error(error.message);
    }, 100);
  }, [query.error]);

  return new Proxy(query, {
    get<T>(target: T, property: keyof T) {
      if (ERROR_PROPERTIES.includes(property)) {
        isErrorHandledRef.current = true;
      }
      return target[property];
    },
  });
}
