import { TextMetrics, type TextStyle } from "pixi.js";

export function processText(text: string, maxWidth: number, style: TextStyle) {
  const lines = text.split("").reduce((acc, v, i) => {
    acc.push((acc[i - 1] || "") + v);
    return acc;
  }, [] as string[]);

  const metrics = TextMetrics.measureText(lines.join("\n"), style);
  const properLineIdx = metrics.lineWidths.findLastIndex((width) => width < maxWidth);

  return metrics.maxLineWidth > maxWidth ? lines[properLineIdx] + "..." : lines[properLineIdx];
}
