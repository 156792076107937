import { createContext, useMemo, useRef, useState } from "react";

import type { IWin } from "@/shared/lib/api/types";
import { useJackpotsContext, usePosition } from "@shared/lib/hooks";
import { TextError } from "@shared/ui/error-queries/text-error";

import type { PropsWithChildren } from "react";

export type TReelStatus = "spin-stop" | "spin-start" | "spin-finished";

export interface IMiniGameContext {
  fetchWinAccept: VoidFunction;
  updateReelStatus: (status: TReelStatus) => void;
  win: Nullable<IWin>;
  reelStatus: TReelStatus;
}

const initialAppContextValue: IMiniGameContext = {
  fetchWinAccept: () => null,
  updateReelStatus: () => null,
  win: null,
  reelStatus: "spin-stop",
};

export const MiniGameContext = createContext<IMiniGameContext>(initialAppContextValue);

export const MiniGameContextProvider = ({ children }: PropsWithChildren) => {
  const position = usePosition();
  const [reelStatus, setReelStatus] = useState(initialAppContextValue.reelStatus);
  const { fetchWinAccept, wins, error } = useJackpotsContext();
  const prevWin = useRef<IWin | null>(null);

  const winJackpot = useMemo(() => {
    if (prevWin.current) {
      return prevWin.current;
    }

    if (!wins) {
      return null;
    }

    const pendingWins = wins?.filter((win) => win.state === "pending");

    const [firstPending] = pendingWins;

    return firstPending;
  }, [wins]);

  const handleWin = () => {
    if (winJackpot?.id) {
      fetchWinAccept(winJackpot.id);
    }
  };

  return (
    <MiniGameContext.Provider
      value={{
        reelStatus,
        updateReelStatus: setReelStatus,
        win: winJackpot,
        fetchWinAccept: handleWin,
      }}
    >
      {children}
      {!!error && <TextError error={error} position={position["bottom-center"]} />}
    </MiniGameContext.Provider>
  );
};
