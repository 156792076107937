import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "wouter";

import { useSmartQuery } from "@shared/lib/api";
import { JackpotsType } from "@shared/lib/api/types";
import { useJPSocket } from "@shared/lib/services";

import type { ISlot, IWin } from "@shared/lib/api/types";
import type { PropsWithChildren } from "react";

export interface IJackpotsContext {
  wins: IWin[] | null;
  slots: ISlotView[] | null;
  fetchWinAccept: (windId: number) => void;
  error: Nullable<string>;
}

type ISlotView = Pick<ISlot, "name" | "value" | "slot">;

const initialAppContextValue: IJackpotsContext = {
  slots: null,
  wins: null,
  fetchWinAccept: () => null,
  error: null,
};

export const JackpotsContext = createContext<IJackpotsContext>(initialAppContextValue);

export const JackpotsContextProvider = ({ children }: PropsWithChildren) => {
  const [slots, setSlots] = useState<ISlot[] | null>(null);
  const [wins, setWins] = useState<IWin[] | null>(null);
  const [location] = useLocation();

  const {
    addWinsListener,
    acceptWin,
    handleGetSlotsAndWins,
    connect,
    addSlotsListener,
    isOpen,
    init,
    error,
  } = useJPSocket();

  const JackpotsStreamQuery = useSmartQuery("POST Lobby.getWsStream", {
    body: {
      defaultBankGroup: "promo",
    },
  });

  const streamId = JackpotsStreamQuery.data?.results[0].response.stream ?? null;

  const fetchConnection = useCallback(async () => {
    if (!isOpen) {
      await connect();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const fetchSlotsState = useCallback(async () => {
    if (!isOpen) {
      return;
    }

    const { slots, wins } = await handleGetSlotsAndWins();

    if (slots) {
      setSlots(slots);
    }

    if (wins) {
      setWins(wins);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, streamId]);

  const fetchWinAccept = useCallback(
    async (winId: number) => {
      if (!isOpen) {
        return;
      }

      await acceptWin(winId);
      const { slots, wins } = await handleGetSlotsAndWins();

      setWins(wins);
      setSlots(slots);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  );

  const addSlotsHandlers = useCallback(() => {
    if (!isOpen) {
      return;
    }

    const slotsListener = (updatedSlots: ISlot[] | null) => {
      if (updatedSlots) {
        setSlots((prevSlots) => {
          const updatedSlotIds = updatedSlots.map((updatedSlot) => updatedSlot.slot);
          const newSlots = prevSlots?.map((prevSlot) => {
            const index = updatedSlotIds.indexOf(prevSlot.slot);
            return index !== -1 ? updatedSlots[index] : prevSlot;
          });

          return newSlots ?? prevSlots;
        });
      }
    };

    addSlotsListener(slotsListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const addWinHandlers = useCallback(() => {
    if (!isOpen) {
      return;
    }

    const winListener = (win: IWin | null) => {
      if (win) {
        setWins((prevState) => [...(prevState ?? []), win]);
      }
    };

    addWinsListener(winListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const computedSlots: ISlotView[] | null = useMemo(() => {
    if (!isOpen) {
      return null;
    }
    const sortedJp = slots?.sort((a, b) => a.value - b.value);
    const sortedSlots = sortedJp?.map((slot, index) => ({
      value: slot.value,
      name: JackpotsType[index],
      slot: slot.slot,
    }));

    return sortedSlots ?? null;
  }, [isOpen, slots]);

  const computedWins: IWin[] | null = useMemo(() => {
    if (!isOpen) {
      return null;
    }

    return wins;
  }, [isOpen, wins]);

  useEffect(() => {
    if (streamId) {
      init(streamId).then(() => {
        Promise.all([fetchConnection(), fetchSlotsState(), addSlotsHandlers(), addWinHandlers()]);
      });
    } else {
      JackpotsStreamQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addSlotsHandlers,
    addWinHandlers,
    fetchConnection,
    fetchSlotsState,
    init,
    streamId,
    location,
  ]);

  const computedWinsPending = useMemo(() => {
    return computedWins?.filter((win) => win.state === "pending") ?? null;
  }, [computedWins]);

  return (
    <JackpotsContext.Provider
      value={{
        slots: computedSlots,
        wins: computedWinsPending,
        fetchWinAccept,
        error,
      }}
    >
      {children}
    </JackpotsContext.Provider>
  );
};
