import * as PIXI from "pixi.js";
import { memo } from "react";

import { bonusModel } from "@entities/bonus/model";
import { useSmartMutationClear } from "@shared/lib/api";
import { usePosition } from "@shared/lib/hooks";
import { AnimatedSpine } from "@shared/ui";

export const GiftAnimation = memo(() => {
  const { useBonusMutation } = bonusModel.useHandleBonus();
  const isAnimationBonusTakeSuccess = bonusModel.useIsAnimationBonusTakeSuccess();
  const position = usePosition();
  const { clearMutation } = useSmartMutationClear({ mutation: useBonusMutation });

  const spineData = PIXI.Assets.cache.get("spine/win/skeleton.json").spineData;

  if (isAnimationBonusTakeSuccess) {
    return (
      <AnimatedSpine
        onAnimationCb={clearMutation}
        isAnimating={true}
        alpha={1}
        anchor={{ x: 0.5, y: 0.5 }}
        position={position.center}
        loop={false}
        spineData={spineData}
        animationName="winGift"
      />
    );
  }

  return null;
});

GiftAnimation.displayName = "GiftAnimation";
