import { Container } from "@pixi/react";
import { useMemo } from "react";

import { useBonusInfo } from "@entities/bonus";
import { playerModel } from "@entities/player";
import { BonusType } from "@shared/lib/api/types";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { formatter, TaggedTextBuilder } from "@shared/lib/utils";
import { COLOR, TaggedTextComponent } from "@shared/ui";

import type { IBonusCashbackSchema } from "@entities/bonus";

interface IBonusInfoProps {
  bonus: IBonusCashbackSchema | null | undefined;
  currency?: string | null;
  width: number;
  type: BonusType;
  y: number;
}

function BonusInfo({ bonus, currency, width, type, y }: IBonusInfoProps) {
  const { t } = useTranslate();
  const { fonts } = useThemeConfig();
  const { bankGroup } = playerModel.usePlayer();
  const wagerCoefficient = bankGroup?.wager_coeff;

  if (!bonus) {
    return null;
  }

  const buildBonusEntityText = new TaggedTextBuilder()
    .addBold(type.toUpperCase())
    .addPrefix("•")
    .add(
      t(`The bonus amount is {percent}%`, {
        percent: `<b>${String(bonus.value)} </b>`,
      }),
    )
    .add(
      t(`The bonus becomes available when the balance is less than {balance} {currency}`, {
        balance: `<b>${formatter.formatMoney(formatter.toMoney(bonus?.threshold))} </b>`,
        currency: currency ?? "",
      }),
      type === BonusType.cashback && bonus?.threshold > 0,
    )
    .add(
      t(`The bonus is available 5-7 minutes after making a deposit`),
      type === BonusType.bounceback,
    )
    .add(
      t(`Wager coefficient {coeff}`, {
        coeff: `<b>${String(wagerCoefficient)}</b>` ?? "",
      }),
    )
    .add(
      t(`The player can use the bonus once every {hours} hours`, {
        hours: `<b>${String(bonus.cooldown)} </b>`,
      }),
      bonus.cooldown > 0,
    )
    .add(
      t(
        `The bonus is available for deposits from {minDeposit} {currency} till {maxDeposit} {currency}`,
        {
          minDeposit: `<b>${formatter.formatMoney(formatter.toMoney(bonus?.minDeposit))}</b>`,
          maxDeposit: `<b>${formatter.formatMoney(formatter.toMoney(bonus?.maxDeposit))}</b>`,
          currency: currency ?? "",
        },
      ),
      bonus?.maxDeposit > 0 && bonus?.minDeposit > 0,
    )
    .add(
      t(`Happy hours from {startTime} till {endTime}`, {
        startTime: `<b>${bonus?.happyHours?.from}</b>`,
        endTime: `<b>${bonus?.happyHours?.to}</b>`,
      }),
      !!bonus?.happyHours,
    )
    .build();

  return (
    <Container y={y} x={10}>
      <TaggedTextComponent
        tagStyles={{
          b: {
            ...fonts.primary,
            fontWeight: 700,
            fontSize: 22,
          },
          default: {
            ...fonts.primary,
            fontSize: 22,
            wordWrap: true,
            wordWrapWidth: width - 10,
            fill: COLOR.white,
          },
          splitStyle: "words",
        }}
        text={buildBonusEntityText}
      />
    </Container>
  );
}

interface TBaseBonusProps {
  width: number;
  y: number;
}

export const BaseBonus = ({ width, y }: TBaseBonusProps) => {
  const { currency } = playerModel.usePlayer();
  const cashback = useBonusInfo(BonusType.cashback);
  const bounceback = useBonusInfo(BonusType.bounceback);
  const lastchance = useBonusInfo(BonusType.lastchance);

  const computedBonuses = useMemo(() => {
    return [lastchance, cashback, bounceback].filter((bonus) => !!bonus);
  }, [cashback, bounceback, lastchance]);

  return (
    <Container width={width} y={y}>
      {computedBonuses.map((bonus, index) => (
        <BonusInfo
          key={index}
          bonus={bonus?.bonus as any}
          type={bonus?.name as BonusType}
          currency={currency}
          width={width}
          y={index * 220}
        />
      ))}
    </Container>
  );
};
