import { playerModel } from "@entities/player";
import { useTranslate } from "@shared/lib/hooks";
import { MutationButtonPrimary } from "@shared/ui";

export const LogoutButton = () => {
  const { t } = useTranslate();
  const { handleExit, logoutMutation } = playerModel.useLogout();

  return (
    <MutationButtonPrimary title={t("logout")} onPress={handleExit} mutation={logoutMutation} />
  );
};
