import { Container } from "@pixi/react";
import { useMemo } from "react";

import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { TaggedTextBuilder } from "@shared/lib/utils";
import { COLOR, TaggedTextComponent } from "@shared/ui";

export const BonusDescription = ({ posY, width }: { posY: number; width: number }) => {
  const { t } = useTranslate();
  const { fonts } = useThemeConfig();

  const styles = useMemo(
    () =>
      ({
        title: {
          ...fonts.primary,
          fontWeight: "bolder",
          fontSize: 24,
        },
        text: {
          ...fonts.primary,
          fontSize: 24,
          fill: COLOR.white,
          wordWrap: true,
          wordWrapWidth: width - 40,
        },
      }) as const,
    [fonts.primary, width],
  );

  const buildText = new TaggedTextBuilder()
    .addBold(t("Bonuses rules"))
    .add(
      t(
        "All bonuses are issued at the time of making a" +
          " deposit To use the bonus, the player must explicitly accept" +
          "it by clicking the TAKE button in the lobby At the same time, a player can have only one" +
          "bonus The bonus can be canceled, under certain conditions",
      ),
    )
    .addLine()
    .addBold(t("Note"))
    .add(
      t(
        "The bonus wager is not recouped in: table games, roulette, fish, aviator, sports, betting",
      ),
    )
    .addLine()
    .addBold(t("Automatic bonus cancellation rules"))
    .add(
      t(
        "Bonus has not been wagered. Attempt to withdraw money\n" +
          "Withdraw amount <= min Withdraw limit settings",
      ),
    )
    .build();

  return (
    <Container y={posY} x={10}>
      <TaggedTextComponent tagStyles={{ b: styles.title, default: styles.text }} text={buildText} />
    </Container>
  );
};
