import { Container, Sprite } from "@pixi/react";
import gsap from "gsap";
import * as PIXI from "pixi.js";
import { useCallback, useEffect, useMemo, useRef } from "react";

import { useThemeConfig } from "@shared/lib/hooks";
import { sfx } from "@shared/lib/services";
import { AnimatedSpine, ButtonSecondary } from "@shared/ui";

import { useDailyWheelContext } from "../../lib/use-daily-wheel-context";

import { Sector } from "./sector";

import type { TSector } from "./sector";
import type { IDailyWheelWin } from "@shared/lib/api/types";

const wheelConfig = {
  radius: 185,
  textGapToCenter: 100,
  textAnchorPercentage: (185 - 100 / 2) / 185,
  startAngleOffset: 90,
  spinCount: 5,
  spinVelocity: 360 * 5,
  sectionColors: [0x3659bf, 0x2c1a71],
};

type TWheel = Pick<IDailyWheelWin, "sectorId">;

export const Wheel = ({ sectorId }: TWheel) => {
  const { themeHeight, themeWidth, themeScreenDevice } = useThemeConfig();
  const dailyWheelModel = useDailyWheelContext();

  const wheelRef = useRef<PIXI.Container | null>(null);
  const wheelArrowRef = useRef<PIXI.Sprite | null>(null);

  const wheelBg = PIXI.Assets.cache.get("wheel-fortune.json").textures[`daily-wheel-bg`];
  const wheelCenterLogo =
    PIXI.Assets.cache.get("wheel-fortune.json").textures[`daily-wheel-center-logo`];
  const wheelWinArrow =
    PIXI.Assets.cache.get("wheel-fortune.json").textures[`daily-wheel-win-arrow`];
  const wheelSpineDataLamp = PIXI.Assets.cache.get("spine/wheel/lamp/skeleton.json").spineData;
  const spinButton = PIXI.Assets.cache.get("wheel-fortune.json").textures[`spin-button`];

  const repeatedSectors = Array(2).fill(dailyWheelModel?.settings?.sectors).flat();

  const { startAngleOffset, textAnchorPercentage, spinVelocity, radius, sectionColors } =
    wheelConfig;

  const circumferenceRadian = 2 * Math.PI;
  const randomOffsetForSmoothStop = 160;

  // длина 1 сектора на окружности в радианах
  const circumferencePerSectorRadian = useMemo(() => {
    return circumferenceRadian / repeatedSectors.length;
  }, [circumferenceRadian, repeatedSectors.length]);

  // Cдвиг угла на выигрышную секцию
  const winAngleDegrees = useMemo(() => {
    const awardSectorWheelIndex = sectorId - 1;
    const awardAngleCenter = (awardSectorWheelIndex * 360) / repeatedSectors.length + spinVelocity;

    return awardAngleCenter + startAngleOffset;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorId, repeatedSectors.length]);

  const tweenWheelStop = gsap.to(wheelRef.current, {
    paused: true,
    duration: 2.5,
    pixi: { rotation: -winAngleDegrees },
    ease: "none",
    onComplete: () => {
      dailyWheelModel.updateSpinStatus("spin-finished");
      tweenWheelArrowStart.pause();
      tweenWheelArrowStop.restart();
    },
  });

  const tweenWheelArrowStart = gsap.to(wheelArrowRef.current, {
    repeat: -1,
    paused: true,
    duration: 0.3,
    pixi: { angle: -16 },
    ease: "none",
  });

  const tweenWheelArrowStop = gsap.to(wheelArrowRef.current, {
    paused: true,
    duration: 0.3,
    pixi: { angle: 0 },
    ease: "none",
  });

  const tweenWheelStart = gsap.to(wheelRef.current!, {
    paused: true,
    duration: 2.5,
    pixi: { rotation: -winAngleDegrees - randomOffsetForSmoothStop },
    ease: "none",
    onStart: () => {
      sfx.play("sounds/wheel-spin.wav");
      tweenWheelArrowStart.restart();
    },
    onComplete: () => {
      tweenWheelStop.restart();
    },
  });

  const tweenSpinningStart = () => {
    if (dailyWheelModel.spinStatus === "spin-stop") {
      dailyWheelModel.handleWin();
      dailyWheelModel.updateSpinStatus("spin-start");
    }
  };

  const computedSectorsSettings: TSector[] | undefined = useMemo(() => {
    if (!dailyWheelModel?.settings?.sectors) return;

    return repeatedSectors.map((value, index) => ({
      startAngle: index * circumferencePerSectorRadian - circumferencePerSectorRadian / 2,
      endAngle:
        index * circumferencePerSectorRadian -
        circumferencePerSectorRadian / 2 +
        circumferencePerSectorRadian,
      rotation: index * circumferencePerSectorRadian,
      wheelRadius: radius,
      value: value.value,
      color: index % 2 ? sectionColors[1] : sectionColors[0],
      x: radius * textAnchorPercentage * Math.cos(index * circumferencePerSectorRadian),
      y: radius * textAnchorPercentage * Math.sin(index * circumferencePerSectorRadian),
    }));
  }, [
    dailyWheelModel?.settings?.sectors,
    repeatedSectors,
    circumferencePerSectorRadian,
    radius,
    sectionColors,
    textAnchorPercentage,
  ]);

  const refContainer = useCallback((node: PIXI.Container) => {
    if (!node) return;
    wheelArrowRef.current = node.parent.children[2] as PIXI.Sprite;
    wheelRef.current = node;
  }, []);

  useEffect(() => {
    if (winAngleDegrees && dailyWheelModel.spinStatus === "spin-start") {
      tweenWheelStart.restart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyWheelModel.spinStatus, winAngleDegrees]);

  if (!computedSectorsSettings) {
    return null;
  }

  const paddingY = themeScreenDevice === "mobile" ? themeHeight / 2.7 : themeHeight / 2;
  const scale = themeScreenDevice === "mobile" ? 1.5 : 1;

  return (
    <Container
      name="wheel"
      x={themeWidth / 2}
      y={paddingY}
      sortableChildren={true}
      zIndex={2}
      scale={scale}
    >
      <Sprite y={-12} zIndex={0} anchor={0.5} texture={wheelBg} />
      <AnimatedSpine
        pivot={{ x: 250, y: -250 }}
        anchor={{ x: 0, y: 0 }}
        angle={31}
        spineData={wheelSpineDataLamp}
        animationName={dailyWheelModel.spinStatus === "spin-start" ? "animation3" : "animation1"}
        position={{ x: 28, y: -53 }}
      />
      <Sprite zIndex={1} anchor={{ x: 0.5, y: 0 }} y={-250} texture={wheelWinArrow} />
      <Sprite zIndex={1} anchor={0.5} texture={wheelCenterLogo} />
      <Container angle={startAngleOffset} ref={refContainer} anchor={0.5}>
        {computedSectorsSettings?.map((sector) => <Sector key={sector.startAngle} {...sector} />)}
      </Container>
      <ButtonSecondary
        isHiddenTexture={true}
        onPress={tweenSpinningStart}
        iconTexture={spinButton}
        position={{ x: 0, y: 250 }}
      />
    </Container>
  );
};
