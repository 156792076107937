import { Container } from "@pixi/react";
import * as PIXI from "pixi.js";

import { useThemeConfig } from "@shared/lib/hooks/use-theme-config";
import { AnimatedSpine } from "@shared/ui";

import { JackpotButton } from "./jackpot-button";
import { SlotsRewardText } from "./slots-reward-text";

export const JackpotOpenerView = () => {
  const { themeConfig, themeScreenDevice } = useThemeConfig();

  const spineData = PIXI.Assets.cache.get("spine/jackpot/jp-opener-anim/skeleton.json").spineData;

  return (
    <Container
      scale={themeScreenDevice === "mobile" ? 1.8 : 1}
      position={themeConfig.footer.jackpot.position}
    >
      <JackpotButton />
      <SlotsRewardText />
      <AnimatedSpine
        position={themeConfig.footer.jackpot.spine.position}
        alpha={0.7}
        spineData={spineData}
      />
    </Container>
  );
};
