import { Container } from "@pixi/react";
import gsap from "gsap";
import { forwardRef, memo, useCallback } from "react";

import { useThemeConfig } from "@shared/lib/hooks";
import { ArrowButton } from "@shared/ui";

import type * as PIXI from "pixi.js";

export const PrevButton = memo(
  forwardRef<
    PIXI.Container,
    {
      handlePrev: VoidFunction;
    }
  >(({ handlePrev }, ref) => {
    const { themeHeight, isMobile } = useThemeConfig();
    const targetRef = ref as unknown as { current: PIXI.Container };

    const setAnimationRef = useCallback(
      (node: PIXI.Container) => {
        if (node && !isMobile) {
          gsap.to(node, {
            yoyo: true,
            repeat: -1,
            duration: 0.4,
            pixi: { x: 10 },
          });
        }
      },
      [isMobile],
    );

    const x = 160;
    const y = themeHeight / 2 - 50;

    const handleClick = () => {
      if (targetRef?.current) {
        handlePrev();
      }
    };

    if (isMobile) return null;

    return (
      <Container ref={setAnimationRef}>
        <ArrowButton
          scale={1}
          type="arrow-next"
          angle={180}
          anchor={0.5}
          position={{ x, y }}
          onClick={handleClick}
        />
      </Container>
    );
  }),
);

PrevButton.displayName = "PrevButton";
