import { Container, Graphics } from "@pixi/react";
import * as PIXI from "pixi.js";
import { useCallback, useRef, useState } from "react";

import { ScrollViewComponent } from "./scroll-view";

import type { ScrollBarView } from "@shared/lib";
import type { ComponentType } from "react";

export type TWithScroll = {
  scrollHeight: number;
  scrollWidth: number;
  scrollType?: "horizontal" | "vertical";
  isScrollbarHidden?: boolean;
  onSlideChange?: (rate: number, scrollbar: ScrollBarView) => void;
};

export function withScroll<T extends TWithScroll = TWithScroll>(
  WrappedComponent: ComponentType<T>,
) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithScroll = (props: T) => {
    const { scrollWidth, scrollHeight, onSlideChange, scrollType, isScrollbarHidden } = props;
    const wrapperScrollBox = useRef<PIXI.Container | null>(null);
    const [tableBounds, setTableBounds] = useState<PIXI.Rectangle>(new PIXI.Rectangle(0, 0, 0, 0));

    const computedWidth = scrollWidth ?? tableBounds.width;
    const computedHeight = scrollHeight ?? wrapperScrollBox.current?.height;

    const wrapper = useCallback((node: PIXI.Container) => {
      if (node) {
        const gameList = node.children[0] as PIXI.Container;

        wrapperScrollBox.current = node;
        const bounds = gameList.getLocalBounds();

        setTableBounds(bounds);
      }
    }, []);

    return (
      <>
        <Container ref={wrapper}>
          <Graphics
            draw={(graphics) => {
              graphics.beginFill(0x00000, 0.0001);
              graphics.drawRect(
                0,
                0,
                computedWidth,
                wrapperScrollBox?.current?.height ?? computedHeight,
              );
            }}
          />
          <WrappedComponent {...(props as T)} />
        </Container>
        <ScrollViewComponent
          isScrollbarHidden={isScrollbarHidden ?? false}
          scrollType={scrollType || "vertical"}
          content={wrapperScrollBox.current!}
          onChange={onSlideChange ?? null}
          width={scrollWidth}
          height={scrollHeight}
        />
      </>
    );
  };

  ComponentWithScroll.displayName = `withScroll(${displayName})`;

  return ComponentWithScroll;
}
