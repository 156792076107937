import { useQueryClient } from "@tanstack/react-query";

import { useSmartMutation, useSmartQuery } from "@shared/lib/api";

export const useWheelFortuneSettings = () => {
  const queryClient = useQueryClient();

  const queryDailyWheelSettings = useSmartQuery("POST FortuneWheel.getSettings", { body: {} });

  const mutationDailyWheelGetWin = useSmartMutation("POST FortuneWheel.getWin", {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["POST Auth.user"] });
    },
  });

  return {
    mutationDailyWheelGetWin,
    queryDailyWheelSettings,
    isLoading: queryDailyWheelSettings.isLoading || queryDailyWheelSettings.isPending,
    isError: queryDailyWheelSettings.isError,
    settings: queryDailyWheelSettings.data?.results[0].response.settings ?? null,
    handleWin: () => {
      mutationDailyWheelGetWin.mutate({ body: {} });
    },
  };
};
