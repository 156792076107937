import { memo } from "react";

import { usePosition, useTranslate } from "@shared/lib/hooks";
import { localStorageService } from "@shared/lib/services";
import {
  Checkbox,
  InputView,
  MutationButtonPrimary,
  MutationErrorAlert,
  SmartContainer,
} from "@shared/ui";

import { useAuthFormState } from "./auth.form.state";

export const AuthFormView = memo(() => {
  const { t } = useTranslate();
  const { handleSubmit, handleInputPassword, handleInputLogin, mutation } = useAuthFormState();

  const authContainer = usePosition({ width: 650, height: 300 });

  function handleRememberMeToggle(checked: boolean) {
    localStorageService.saveData("authRemember", checked);

    if (!checked) {
      localStorageService.removeData("authLogin");
    }
  }

  return (
    <>
      <SmartContainer>
        <InputView
          placeholder={t("Enter login")}
          width={550}
          onChange={handleInputLogin}
          defaultValue={localStorageService.getData("authLogin")}
        />
      </SmartContainer>
      <SmartContainer paddingTop={120}>
        <InputView
          placeholder={t("Enter password")}
          width={550}
          type="password"
          onChange={handleInputPassword}
        />
      </SmartContainer>
      <SmartContainer position={{ x: 0, y: 260 }}>
        <Checkbox
          defaultValue={Boolean(localStorageService.getData("authRemember"))}
          onToggle={handleRememberMeToggle}
        />
      </SmartContainer>
      <SmartContainer
        paddingRight={50}
        paddingTop={100}
        centeredChild={true}
        position={authContainer["bottom-center"]}
      >
        <MutationButtonPrimary title={t("login")} mutation={mutation} onPress={handleSubmit} />
      </SmartContainer>
      <SmartContainer paddingRight={50} position={authContainer["top"]}>
        <MutationErrorAlert mutation={mutation} />
      </SmartContainer>
    </>
  );
});

AuthFormView.displayName = "AuthFormView";
