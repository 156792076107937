/**
 * スライダーを初期化する際のオプション
 */
import { SHAPES } from "pixi.js";

import { SliderView } from "@shared/lib/vendors/pixi/scrollview/SliderView";
import { SliderViewUtil } from "@shared/lib/vendors/pixi/scrollview/SliderViewUtil";

import type { DisplayObject, Graphics } from "pixi.js";

export interface SliderViewOption {
  /**
   * スライダーボタンの座標の最小値
   * @default 0.0
   */
  minPosition?: number;
  maxPosition: number; //スライダーボタンの座標の最大値
  /**
   * @default 0.0
   */
  rate?: number;
  track: DisplayObject; //スライダーの地
  /**
   * スライドボタン
   */
  thumb: DisplayObject;
  mask?: Graphics; //バーのマスク
  bar?: DisplayObject; //スライドにあわせて表示されるバー
  /**
   * 水平スクロールか否か
   * @default true
   */
  isHorizontal?: boolean;

  canvas?: HTMLCanvasElement;
}

export interface InitializedSliderViewOption extends SliderViewOption {
  minPosition: number;
  rate: number;
  isHorizontal: boolean;
}

export class SliderViewOptionUtil {
  public static init(option: SliderViewOption): InitializedSliderViewOption {
    this.check(option);

    if (option.rate != null) {
      option.rate = Math.max(0, option.rate);
      option.rate = Math.min(SliderView.MAX_RATE, option.rate);
    }
    option.rate ??= 0.0;

    option.minPosition ??= 0.0;
    option.isHorizontal ??= true;

    return option as InitializedSliderViewOption;
  }

  protected static check(option: SliderViewOption): void {
    this.checkParts(option.track, "track");
    this.checkParts(option.thumb, "thumb");
    this.checkParts(option.mask, "mask");
    this.checkParts(option.bar, "bar");
  }

  private static checkParts(obj: DisplayObject | undefined, targetName: string): void {
    if (obj == null) return;

    const bounds = SliderViewUtil.getContentsBounds(obj);
    if (bounds.width === 0 && bounds.height === 0 && bounds.type === SHAPES.RECT) {
      throw new Error(
        `SliderView : ${targetName}
          The DisplayObject specified in the initialization options does not have a bounding box.
         When using Container, please use hitArea to manually set the bounding box.`,
      );
    }

    if (obj.parent) {
      console.warn(
        `The part specified in the initialization option
         has already been addedChild to another parent.
         It is assumed that the components of SliderView and ScrollBarView
          are addedChild to the same container.`,
      );
    }
  }
}
