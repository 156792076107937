import { PixiComponent } from "@pixi/react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import TaggedText from "pixi-tagged-text";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import type { TaggedTextOptions, TextStyleSet } from "pixi-tagged-text/dist/types";

export interface ITaggedText {
  text: string;
  tagStyles?: TextStyleSet;
  options?: TaggedTextOptions;
}

const PixiTaggedTextComponent = PixiComponent("TaggedText", {
  create: ({ text, tagStyles, options }: ITaggedText) => {
    return new TaggedText(text, tagStyles, options);
  },
  config: {
    destroy: true,
    destroyChildren: true,
  },
});

export const TaggedTextComponent = (props: ITaggedText) => {
  return <PixiTaggedTextComponent {...props} />;
};
