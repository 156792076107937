import { Sprite, Text } from "@pixi/react";
import { TextStyle, Texture } from "pixi.js";

import { useThemeConfig } from "@shared/lib/hooks";
import { ClickableContainer } from "@shared/ui";
import { RoundedRect } from "@shared/ui/shapes";

import type { TTab } from "./types";

type TProps = {
  tab: TTab;
  textAlign: "center" | "left";
  onClick: (tab: TTab) => void;
  isActive?: boolean;
};

const BUTTON_WIDTH = 260;
const BUTTON_HEIGHT = 60;

function getTextAlignProps(align: "center" | "left"): {
  anchor: [number, number];
  position: { x: number; y: number };
} {
  switch (align) {
    case "center":
      return { anchor: [0.5, 0.5], position: { x: BUTTON_WIDTH / 2, y: BUTTON_HEIGHT / 2 } };
    default:
      return { anchor: [0, 0.5], position: { x: BUTTON_WIDTH * 0.1, y: BUTTON_HEIGHT / 2 } };
  }
}

export const TabButton = ({ textAlign = "left", tab, onClick, isActive }: TProps) => {
  const { fonts } = useThemeConfig();

  return (
    <ClickableContainer onpointerup={() => onClick(tab)}>
      <Sprite
        texture={Texture.EMPTY}
        width={BUTTON_WIDTH}
        height={BUTTON_HEIGHT}
        position={{ x: BUTTON_WIDTH * 0.1, y: BUTTON_HEIGHT / 2 - 12 }}
      />
      {isActive && (
        <RoundedRect
          width={BUTTON_WIDTH}
          height={BUTTON_HEIGHT}
          radius={12}
          color={fonts.infoActiveTabStyle.color}
        />
      )}
      <Text
        text={tab.label}
        style={
          new TextStyle({
            fill: "rgba(255,255,255,0.91)",
            align: "center",
            letterSpacing: 5,
            fontSize: 28,
          })
        }
        {...getTextAlignProps(textAlign)}
      />
    </ClickableContainer>
  );
};
