import { gsap } from "gsap";
import { Ticker, utils } from "pixi.js";

import { SliderViewUtil } from "./SliderViewUtil";

import { ScrollBarViewUtil } from "./index";

import type { ScrollBarEventTypes, ScrollBarView } from "./index";
import type { DisplayObject, DisplayObjectEvents, FederatedPointerEvent } from "pixi.js";

export class InertialScrollManager extends utils.EventEmitter<ScrollBarEventTypes> {
  public decelerationRate: number = 0.975;
  public overflowScrollRange: number = 180;
  public isDragging: boolean = false;
  public isStart: boolean = false;
  protected dragPos?: number;
  private scrollBarView: ScrollBarView;
  private tween?: GSAPTween;

  constructor(scrollBarView: ScrollBarView) {
    super();
    this.scrollBarView = scrollBarView;
    scrollBarView.scrollBarEventEmitter.on("stop_inertial_tween", this.stopInertial);

    const target = this.scrollBarView.contents.target;
    target.eventMode = "static";

    this.start();
  }

  private _speed: number = 0.0;

  get speed(): number {
    return this._speed;
  }

  public start(): void {
    if (this.isStart) return;
    this.isStart = true;

    const target = this.scrollBarView.contents.target;
    target.on("pointerdown", this.onMouseDown);
    Ticker.shared.add(this.onTick);
  }

  public stop(): void {
    if (!this.isStart) return;
    this.isStart = false;

    const target = this.scrollBarView.contents.target;
    target.off("pointerdown", this.onMouseDown);
    this.removeDragListener();
    this.stopInertial();
    Ticker.shared.remove(this.onTick);
  }

  public stopInertial = () => {
    this._speed = 0.0;
    this.disposeTween();
  };

  private onMouseDown = (e: FederatedPointerEvent) => {
    this.updateDragPos(e);

    this.isDragging = true;
    this._speed = 0.0;
    if (this.tween) this.disposeTween();

    this.addDragListener();
  };

  private addDragListener(): void {
    this.switchDragListener(true);
  }

  private removeDragListener(): void {
    this.switchDragListener(false);
  }

  private switchDragListener(isOn: boolean): void {
    const target = this.scrollBarView.contents.target;
    const dragTarget = this.scrollBarView.canvas ?? target;
    const switchListener = (
      isOn: boolean,
      dragTarget: DisplayObject | HTMLCanvasElement,
      event: keyof DisplayObjectEvents,
      listener: utils.EventEmitter.ListenerFn,
    ) => {
      if (isOn) {
        dragTarget.addEventListener(event as string, listener);
      } else {
        dragTarget.removeEventListener(event as string, listener);
      }
    };
    switchListener(isOn, dragTarget, "pointermove", this.onMouseMove);
    switchListener(isOn, target, "pointerup", this.onMouseUp);
    switchListener(isOn, target, "pointerupoutside", this.onMouseUp);
  }

  private getDragPos(e: PointerEvent): number {
    return SliderViewUtil.getPointerEventPosition(e, this.scrollBarView.isHorizontal);
  }

  private updateDragPos(e: PointerEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.dragPos = this.getDragPos(e);
  }

  private onMouseMove = (e: FederatedPointerEvent) => {
    e.preventDefault();
    if (this.dragPos == null) return;
    const delta = this.getDragPos(e) - this.dragPos;

    this._speed = delta;
    this.addTargetPosition(delta * this.getOverflowDeceleration());

    this.updateDragPos(e);
  };

  private addTargetPosition(delta: number): void {
    const target = this.scrollBarView.contents.target;
    const isHorizontal = this.scrollBarView.isHorizontal;
    const currentPos = SliderViewUtil.getPosition(target, isHorizontal);
    SliderViewUtil.setPosition(target, isHorizontal, currentPos + delta);

    this.emit("update_target_position");
  }

  private onMouseUp = (e: FederatedPointerEvent) => {
    e.preventDefault();
    this.removeDragListener();
    this.isDragging = false;
    this.onTick();
  };

  private onTick = () => {
    if (this.isDragging) return;
    if (this._speed === 0.0 && this.getLeaveRangeFromMask() === 0.0) return;
    if (this.tween?.isActive()) return;

    //位置による減速率増加。マスクエリアから離れているなら減速率が大きくなる。
    const overflowDeceleration = this.getOverflowDeceleration();

    this._speed *= this.decelerationRate * overflowDeceleration;
    this.addTargetPosition(this._speed);

    if (Math.abs(this._speed) > 0.1) return;

    this._speed = 0.0;

    this.disposeTween();
    if (!this.scrollBarView.contents.target) return;

    this.tween = gsap.to(this.scrollBarView.contents.target, {
      ease: this.scrollBarView.isHorizontal ? "sine" : "sine",
      duration: this.scrollBarView.isHorizontal ? 0.5 : 0.1,
      pixi: { [this.scrollBarView.isHorizontal ? "x" : "y"]: this.getClampedPos() },
      onUpdate: () => {
        this.emit("update_target_position");
      },
    });
  };

  private disposeTween = () => {
    if (this.tween) {
      gsap.killTweensOf(this.scrollBarView.contents.target);
      this.tween = undefined;
    }
  };

  /**
   * スクロールのオーバーフロー量から、減退率を割り出す。
   * overflowScrollRange以上に離れている場合は0.0
   * スクロールエリア内にコンテンツがある場合は1.0を返す。
   */
  private getOverflowDeceleration() {
    const difPos = this.getLeaveRangeFromMask();
    let overflowDeceleration = (this.overflowScrollRange - difPos) / this.overflowScrollRange;
    if (overflowDeceleration < 0.0) overflowDeceleration = 0.0;

    return overflowDeceleration;
  }

  /**
   * ターゲットコンテンツがマスク領域からどれだけ離れているか。
   */
  private getLeaveRangeFromMask() {
    const target = this.scrollBarView.contents.target;
    const isHorizontal = this.scrollBarView.isHorizontal;
    const currentPos = SliderViewUtil.getPosition(target, isHorizontal);
    const clampedPos = this.getClampedPos();

    return Math.abs(currentPos - clampedPos);
  }

  private getClampedPos() {
    const target = this.scrollBarView.contents.target;
    const isHorizontal = this.scrollBarView.isHorizontal;
    return ScrollBarViewUtil.getClampedTargetPosition(
      target,
      this.scrollBarView.contents.mask,
      isHorizontal,
    );
  }
}
