import { Sound, sound } from "@pixi/sound";

import { localStorageService } from "@shared/lib/services";

import { EFFECT_VOLUME_DEFAULT } from "../../consts";

import type { PlayOptions } from "@pixi/sound";

class SFX {
  private _volume: number;

  constructor() {
    this._volume = localStorageService.getData("sfx") ?? EFFECT_VOLUME_DEFAULT;
  }

  public play(alias: string, options?: PlayOptions) {
    if (!sound.find(alias)) {
      Sound.from(alias);
    }
    sound.play(alias, { ...options, volume: this._volume });
  }

  public stop(alias: string) {
    if (!sound.find(alias)) {
      return;
    }

    if (sound.find(alias)?.isPlaying) {
      return sound.find(alias)?.stop();
    }
  }

  get volume() {
    return this._volume;
  }

  set volume(v: number) {
    this._volume = v;
  }
}

export const sfx = new SFX();
