import * as PIXI from "pixi.js";
import { useCallback, useEffect, useRef } from "react";

import { useAppStore } from "@app/providers";
import { Device, vp } from "@shared/lib/services";

import { settings } from "./settings";

import type { Application } from "pixi.js";

export const useInitApp = () => {
  const deviceRef = useRef<Device | null>(null);
  const appRef = useRef<Application | null>(null);
  const setLobbyConfig = useAppStore((state) => state.setLobbyConfig);

  const createMask = (rootNode: PIXI.Container, width: number, height: number) => {
    const mask = new PIXI.Graphics().beginFill(0x000000).drawRect(0, 0, width, height).endFill();

    const appArea = new PIXI.Rectangle(0, 0, width, height);

    rootNode.hitArea = appArea;
    rootNode.filterArea = appArea;
    rootNode.filterArea = appArea;

    const maskContainer = new PIXI.Container();
    maskContainer.name = "MaskContainer";

    if (rootNode.mask) {
      const currentMask = rootNode.getChildByName("MaskContainer");
      if (currentMask) {
        rootNode.mask = null;
        currentMask.destroy({ children: true });
      }
    }

    if (rootNode) {
      maskContainer.mask = mask;
      maskContainer.addChild(mask);
      rootNode.addChild(maskContainer);
      rootNode.mask = mask;
    }
    return mask;
  };

  const init = (app: Application) => {
    window.__PIXI_APP__ = app;
    
    deviceRef.current = new Device(app.renderer);
    appRef.current = app;

    window.addEventListener("resize", resize);

    setTimeout(() => {
      resize();
    }, 100);
  };

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", resize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resize = useCallback(() => {
    const app = appRef.current ?? window.__PIXI_APP__;

    if (!app) {
      return;
    }

    const rootNode = app.stage;
    const state = deviceRef.current;

    if (!state) {
      return;
    }

    state.resize(window.innerWidth, window.innerHeight);

    if (state.desktop || state.landscape) {
      app.renderer.resize(state.width, state.height);
      vp.resize(settings.size[0], settings.size[1]);
      rootNode.scale.set(vp.aspectRatio);
      rootNode.pivot.set(vp.width / 2, vp.height / 2);
      rootNode.position.set(state.width / 2, state.height / 2);
      createMask(rootNode, settings.size[0], settings.size[1]);
    } else {
      app.renderer.resize(state.width, state.height);
      vp.resize(settings.size[1], settings.size[0]);
      rootNode.scale.set(vp.aspectRatio);
      rootNode.pivot.set(vp.width / 2, 0);
      rootNode.position.set(state.width / 2, 0);
      createMask(rootNode, settings.size[1], settings.size[0]);
    }

    setLobbyConfig({
      isIOS: state.ios,
      isSafariOrIos: state.iosOrSafari,
      supportsPointerEvents: state.supportsPointerEvents,
      supportsTouchEvents: state.supportsTouchEvents,
      gameSceneAspectRatio: vp.aspectRatio,
      gameSceneHeight: state.height,
      gameSceneWidth: state.width,
      isDesktop: state.desktop,
      isLandscape: state.landscape,
      canvasHeight: app.renderer.view.height,
      canvasWidth: app.renderer.view.width,
    });
  }, [setLobbyConfig]);

  return { init, resize, device: deviceRef.current };
};
