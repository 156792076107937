import { Text } from "@pixi/react";

import { useThemeConfig } from "@shared/lib/hooks";
import { COLOR } from "@shared/ui";

import type * as PIXI from "pixi.js";

interface IProps {
  position?: PIXI.IPointData;
  error: string;
}

export function TextError({ position, error }: IProps) {
  const { fonts, themeWidth } = useThemeConfig();

  return (
    <Text
      anchor={0.5}
      position={position ?? { x: 0, y: 0 }}
      zIndex={4}
      style={{
        ...fonts.primary,
        align: "center",
        fontSize: 18,
        fill: [COLOR.orange],
        wordWrap: true,
        wordWrapWidth: themeWidth / 2,
        breakWords: true,
      }}
      text={error}
    />
  );
}
