import * as PIXI from "pixi.js";
import { useMemo } from "react";

import { useAppStore } from "@app/providers";

import { useResolveTexture } from "./use-resolve-texture";

export const useThemeConfig = () => {
  const textureSize = useResolveTexture();
  const theme = PIXI.Assets.cache.get("theme.json");
  const isSafariOrIos = useAppStore(({ lobbyConfig: { isSafariOrIos } }) => isSafariOrIos);
  const fonts = theme.components.fonts;
  const isCurrencyHidden = theme.settings.general.isCurrencyHidden;
  const width = theme.components[textureSize]["size"][0];
  const height = theme.components[textureSize]["size"][1];
  const isMobile = (textureSize as typeof textureSize) === "mobile";

  return useMemo(() => {
    return {
      themeName: theme.name,
      isCurrencyHidden,
      isSafariOrIos,
      fonts,
      isMobile,
      themeScreenDevice: textureSize as typeof textureSize,
      themeConfig: theme.components[textureSize],
      themeWidth: width,
      themeHeight: height,
    };
  }, [
    theme.name,
    theme.components,
    isCurrencyHidden,
    isSafariOrIos,
    fonts,
    isMobile,
    textureSize,
    width,
    height,
  ]);
};
