import { Container, Text } from "@pixi/react";
import { TextStyle } from "pixi.js";

import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { COLOR } from "@shared/ui";

export const Jackpots = () => {
  const { t } = useTranslate();
  const { fonts, isMobile } = useThemeConfig();
  return (
    <Container y={40} x={isMobile ? -20 : 20}>
      <Text
        y={20}
        text={t(
          "Make more bets to win one of the jackpots " +
            "\nA small amount from each bet forms the jackpot amount" +
            " \nThe moment at which the jackpot will be triggered is determined randomly",
        )}
        style={
          new TextStyle({
            ...fonts.primary,
            fontSize: 20,
            wordWrapWidth: 550,
            wordWrap: true,
            breakWords: true,
            fill: COLOR.white,
          })
        }
      />
    </Container>
  );
};
