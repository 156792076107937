import { useCallback, useEffect, useMemo } from "react";

import { useSearchParams } from "@shared/lib/hooks";

export function useStringSearchParam(key: string, defaultValue?: string) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.has(key)) {
      if (defaultValue !== undefined) {
        searchParams.set(key, defaultValue);
        setSearchParams(searchParams);
      }
    }
  }, [defaultValue, key, searchParams, setSearchParams]);

  const value = useMemo(() => {
    const value = searchParams.get(key);

    if (!value) {
      return defaultValue;
    }

    return value;
  }, [searchParams, key, defaultValue]);

  const setValue = useCallback(
    (value?: string) => {
      const searchParams = new URLSearchParams(window.location.search);
      if (value !== undefined) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
      setSearchParams(searchParams, { replace: true });
    },
    [key, setSearchParams],
  );

  return [value, setValue] as const;
}
