import { Container, Sprite } from "@pixi/react";
import { gsap } from "gsap";
import * as PIXI from "pixi.js";
import { memo } from "react";

import { usePosition, useThemeConfig } from "@shared/lib/hooks";
import { ButtonClose, SmartContainer } from "@shared/ui";

import type { IPointData, Spritesheet } from "pixi.js";
import type { ReactNode } from "react";

type TProps = {
  name: string;
  scale?: IPointData;
  render: (modalSpriteWidth: number, modalSpriteHeight: number) => ReactNode;
  isBg?: boolean;
  onClose?: () => void;
};

function tweenOpen(target: PIXI.Sprite | null) {
  if (!target) return;

  gsap.to(target, {
    ease: "none",
    duration: 0.25,
    pixi: { scale: 1 },
  });
}

export const Modal = memo(
  ({ render, name, scale = { x: 1, y: 1 }, isBg = true, onClose }: TProps) => {
    const { themeWidth, themeHeight, themeScreenDevice, isMobile } = useThemeConfig();
    const position = usePosition();
    const modalTexture =
      PIXI.Assets.cache.get<Spritesheet>("common.json").textures[`@${themeScreenDevice}.modal`];

    const modalSpriteWidth = modalTexture.width / scale.x;
    const modalSpriteHeight = modalTexture.height / scale.y;

    const paddingCloseButtonX = isMobile ? 100 : 100;
    const paddingCloseButtonY = isMobile ? 100 : 50;

    return (
      <>
        {isBg && (
          <Sprite
            zIndex={6}
            position={{ x: 0, y: 0 }}
            texture={PIXI.Texture.WHITE}
            width={themeWidth}
            height={themeHeight}
            alpha={0.5}
            tint={0x010101}
            interactive={true}
          />
        )}
        <Container name={name} zIndex={6} position={position.center}>
          <Sprite
            name="modal-wrapper"
            scale={0}
            ref={(node) => tweenOpen(node)}
            texture={modalTexture}
            anchor={0.5}
            sortableChildren={true}
            zIndex={6}
          >
            <SmartContainer>{render(modalSpriteWidth, modalSpriteHeight)}</SmartContainer>
            {!!onClose && (
              <SmartContainer
                position={{
                  x: modalSpriteWidth / 2 - paddingCloseButtonX,
                  y: -modalSpriteHeight / 2 + paddingCloseButtonY,
                }}
              >
                <ButtonClose onClick={onClose} />
              </SmartContainer>
            )}
          </Sprite>
        </Container>
      </>
    );
  },
);

Modal.displayName = "Modal";
