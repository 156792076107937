import { useCallback, useMemo, useRef } from "react";
import { useLocation, useSearch } from "wouter";

export function useSearchParams(
  defaultInit?: URLSearchParamsInit,
): [URLSearchParams, SetURLSearchParams] {
  const defaultSearchParamsRef = useRef(createSearchParams(defaultInit));
  const hasSetSearchParamsRef = useRef(false);

  const search = useSearch();
  const searchParams = useMemo(
    () =>
      getSearchParamsForLocation(
        search,
        hasSetSearchParamsRef.current ? null : defaultSearchParamsRef.current,
      ),
    [search],
  );

  const [_, navigate] = useLocation();

  const setSearchParams = useCallback<SetURLSearchParams>(
    (nextInit, navigateOptions) => {
      const newSearchParams = createSearchParams(
        typeof nextInit === "function" ? nextInit(searchParams) : nextInit,
      );
      hasSetSearchParamsRef.current = true;
      navigate("?" + newSearchParams, navigateOptions);
    },
    [navigate, searchParams],
  );

  return [searchParams, setSearchParams];
}

export type SetURLSearchParams = (
  nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
  navigateOpts?: NavigateOptions,
) => void;

export function createSearchParams(init: URLSearchParamsInit = ""): URLSearchParams {
  return new URLSearchParams(
    typeof init === "string" || Array.isArray(init) || init instanceof URLSearchParams
      ? init
      : Object.keys(init).reduce(
          (memo, key) => {
            const value = init[key];
            return memo.concat(Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]);
          },
          [] as [string, string][],
        ),
  );
}

export interface NavigateOptions {
  replace?: boolean;
  state?: any;
}

type URLSearchParamsInit =
  | string
  | [string, string][]
  | Record<string, string | string[]>
  | URLSearchParams;

function getSearchParamsForLocation(
  locationSearch: string,
  defaultSearchParams: URLSearchParams | null,
) {
  const searchParams = createSearchParams(locationSearch);

  if (defaultSearchParams) {
    defaultSearchParams.forEach((_, key) => {
      if (!searchParams.has(key)) {
        defaultSearchParams.getAll(key).forEach((value) => {
          searchParams.append(key, value);
        });
      }
    });
  }

  return searchParams;
}
