import { useThemeConfig } from "@/shared/lib/hooks";
import { withScroll } from "@shared/ui";

import { BaseBonus } from "./base-bonus";
import { BonusDescription } from "./description";

export const BonusesView = withScroll(({ scrollWidth }) => {
  const { isMobile } = useThemeConfig();
  return (
    <>
      <BonusDescription width={scrollWidth} posY={0} />
      <BaseBonus width={scrollWidth} y={isMobile ? 600 : 460} />
    </>
  );
});
