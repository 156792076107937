import { useCallback, useMemo } from "react";

import { gameModel } from "@entities/game";
import { usePagination, useThemeConfig } from "@shared/lib/hooks";

export function useGameListState() {
  const { computedGames, isLoading, queryGames } = gameModel.useFilteredGames();
  const { setGameStringId, handleGameStart, currentGameUrl, mutationStartupGame } =
    gameModel.useGameController();

  const { isMobile } = useThemeConfig();

  const { firstContentIndex, lastContentIndex, nextPage, prevPage, totalPages, setPage, page } =
    usePagination({
      contentPerPage: isMobile ? computedGames.length : 8,
      count: computedGames.length,
    });

  const isFirstPage = page < totalPages;
  const isLastPage = page === totalPages;

  const computedGamesByGroup = useMemo(() => {
    const paginatableGames = computedGames.slice(firstContentIndex, lastContentIndex);
    const mobileList = [];
    const desktopList: Array<
      [ArrayElement<typeof paginatableGames>, Nullable<ArrayElement<typeof paginatableGames>>]
    > = [];

    if (isMobile) {
      for (let index = 0; index < paginatableGames.length; index += 6) {
        mobileList.push([
          paginatableGames[index],
          paginatableGames[index + 1],
          paginatableGames[index + 2],
          paginatableGames[index + 3],
          paginatableGames[index + 4],
          paginatableGames[index + 5],
        ]);
      }

      return mobileList;
    }

    for (let index = 0; index < paginatableGames.length; index += 2) {
      if (index + 1 < paginatableGames.length) {
        desktopList.push([paginatableGames[index], paginatableGames[index + 1]]);
      } else {
        desktopList.push([paginatableGames[index], null]);
      }
    }

    return desktopList;
  }, [computedGames, firstContentIndex, isMobile, lastContentIndex]);

  const handleNext = useCallback(() => {
    nextPage();
  }, [nextPage]);

  const handlePrev = useCallback(() => {
    prevPage();
  }, [prevPage]);

  return useMemo(
    () => ({
      isFirstPage,
      isLastPage,
      computedGamesByGroup,
      computedGames,
      isGamesLoading: isLoading,
      setGameStringId,
      queryGames,
      handleGameStart,
      handlePrev,
      handleNext,
      totalPages,
      currentGameUrl,
      page,
      mutationStartupGame,
      setPage,
    }),
    [
      computedGames,
      computedGamesByGroup,
      currentGameUrl,
      handleGameStart,
      handleNext,
      handlePrev,
      isFirstPage,
      isLastPage,
      isLoading,
      mutationStartupGame,
      page,
      queryGames,
      setGameStringId,
      setPage,
      totalPages,
    ],
  );
}
