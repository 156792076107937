import { Container, Sprite, Text } from "@pixi/react";
import * as PIXI from "pixi.js";
import { memo } from "react";

import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { COLOR } from "@shared/ui";

import { useDailyWheelContext } from "../../lib/use-daily-wheel-context";

export const DailyWheelRules = memo(() => {
  const { t } = useTranslate();
  const { settings } = useDailyWheelContext();
  const { fonts, themeHeight, themeWidth, isMobile } = useThemeConfig();

  const bg = PIXI.Assets.cache.get("common.json").textures[`mini-modal`];
  const dailyRules = settings?.rules.daily;
  const generalRules = settings?.rules.general;

  const paddingY = isMobile ? themeHeight / 1.5 : themeHeight / 2;

  return (
    <>
      <Container interactiveChildren={false} x={200} y={paddingY}>
        <Sprite y={-12} zIndex={0} anchor={0.5} texture={bg} />
        <Text
          position={{ x: 0, y: -150 }}
          anchor={{ x: 0.5, y: 0.5 }}
          style={{ ...fonts.primary }}
          text={t("how it works").toUpperCase()}
        />
        <Text
          position={{ x: 0, y: -80 }}
          anchor={{ x: 0.5, y: 0 }}
          style={{ ...fonts.primary, ...style.text }}
          text={generalRules?.toUpperCase()}
        />
      </Container>
      <Container x={themeWidth - 200} y={paddingY}>
        <Sprite y={-12} zIndex={0} anchor={0.5} texture={bg} />
        <Text
          position={{ x: 0, y: -150 }}
          anchor={{ x: 0.5, y: 0.5 }}
          style={{ ...fonts.primary }}
          text={t("daily wheel rules").toUpperCase()}
        />
        <Text
          position={{ x: 0, y: -80 }}
          anchor={{ x: 0.5, y: 0 }}
          style={{ ...fonts.primary, ...style.text }}
          text={dailyRules?.toUpperCase()}
        />
      </Container>
    </>
  );
});

const style = {
  text: {
    fontSize: 20,
    wordWrap: true,
    wordWrapWidth: 330,
    align: "left",
    multiline: true,
    textBaseline: "alphabetic",
    whiteSpace: "pre-line",
    fill: [COLOR.white],
  },
};

DailyWheelRules.displayName = "WinJackpot";
