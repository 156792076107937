/*eslint-disable*/
export const ProgressLoaderHTML = ({ progress }: { progress: number }) => {
  return (
    <div className="spinner">
      <div className="progress">
        <h2>LOADING...</h2>
        <progress value={progress} />
      </div>
    </div>
  );
};
