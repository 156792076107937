import { Container, Sprite } from "@pixi/react";
import { gsap } from "gsap";
import * as PIXI from "pixi.js";
import { memo, useRef } from "react";

import { usePosition, useThemeConfig } from "@shared/lib/hooks";

import type { IPointData, Spritesheet } from "pixi.js";
import type { ReactNode } from "react";

type TProps = {
  name: string;
  scale?: IPointData;
  isBg?: boolean;
  render: (modalSpriteWidth: number, modalSpriteHeight: number) => ReactNode;
  onClose?: () => void;
};

export const MiniModal = memo(({ render, name, scale = { x: 1, y: 1 }, isBg = true }: TProps) => {
  const { themeWidth, themeHeight, isMobile } = useThemeConfig();
  const positionModal = usePosition();
  const modalRef = useRef<PIXI.Container | null>(null);
  const hasMountedRef = useRef(false);

  const modalTextureLabel = isMobile ? "@mobile.modal" : "mini-modal";
  const modalTexture =
    PIXI.Assets.cache.get<Spritesheet>("common.json").textures[modalTextureLabel];

  const modalSpriteWidth = modalTexture.width * scale.x;
  const modalSpriteHeight = modalTexture.height * scale.y;

  const tweenOpen = (target: PIXI.Container) => {
    if (!target) return;

    gsap.to(target, {
      ease: "none",
      duration: hasMountedRef.current ? 0 : 0.25,
      pixi: { scale: 1 },
    });
  };

  const tweenOpenRef = (node: PIXI.Container) => {
    if (node && !isMobile) {
      modalRef.current = node;
      tweenOpen(node);

      hasMountedRef.current = true;
    }
  };

  return (
    <Container name={name} zIndex={22} destroy={() => gsap.killTweensOf(modalRef.current)}>
      {isBg && (
        <Sprite
          position={positionModal["center"]}
          anchor={0.5}
          texture={PIXI.Texture.WHITE}
          width={themeWidth}
          height={themeHeight}
          alpha={0.5}
          tint={0x010101}
          interactive
        />
      )}
      <Container
        name="modal-wrapper"
        ref={tweenOpenRef}
        position={positionModal["center"]}
        scale={isMobile ? 1 : 0}
        zIndex={6}
        sortableChildren
      >
        <Sprite scale={scale} name="modal-sprite" anchor={0.5} texture={modalTexture} />
        <Container
          pivot={{
            x: modalSpriteWidth / 2,
            y: modalSpriteHeight / 2,
          }}
          name="modal-content"
          eventMode="dynamic"
        >
          {render(modalSpriteWidth, modalSpriteHeight)}
        </Container>
      </Container>
    </Container>
  );
});

MiniModal.displayName = "MiniModal";
