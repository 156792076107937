import { useRef } from "react";

import { playerModel } from "@entities/player";
import { localStorageService } from "@shared/lib/services";

import type { AuthFormSchema } from "./auth.form.schema";

export function useAuthFormState() {
  const { loginMutation } = playerModel.useLogin();

  const form = useRef<AuthFormSchema>({
    login: "",
    password: "",
  });

  const handleInputLogin = (login: string) => {
    form.current = { ...form.current, login };
  };

  const handleInputPassword = (password: string) => {
    form.current = { ...form.current, password };
  };

  function handleSubmit() {
    const request = { body: { remember: false, ...form.current } };
    loginMutation.mutate(request);

    if (localStorageService.getData("authRemember")) {
      localStorageService.saveData("authLogin", form.current.login);
    } else {
      localStorageService.removeData("authLogin");
    }
  }

  return { handleSubmit, mutation: loginMutation, handleInputLogin, handleInputPassword };
}
