import { useMemo, useState } from "react";

import { RulesWithScroll, useRules } from "@entities/lobby";
import { playerModel } from "@entities/player";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";

import type { TRuleTypes } from "@shared/lib/api/types";
import type { TTab } from "@shared/ui";

export const useModalRulesState = () => {
  const { handleExit } = playerModel.useLogout();
  const { handleAcceptRule, acceptRuleMutation, needAcceptRules, isSSEnabled } = useRules();
  const { t } = useTranslate();
  const { isMobile } = useThemeConfig();

  const hasSweepstakes = useMemo(
    () => needAcceptRules?.some((rule) => rule.type === "SweepstakesRules") ?? false,
    [needAcceptRules],
  );

  const hasTermsAndConditions = useMemo(
    () => needAcceptRules?.some((rule) => rule.type === "TermsAndConditions") ?? false,
    [needAcceptRules],
  );

  const tabItems = useMemo(
    () => [
      {
        key: "TermsAndConditions",
        label: t("T&C"),
        children: (
          <RulesWithScroll
            type="TermsAndConditions"
            isNeedAcceptRules={true}
            scrollHeight={isMobile ? 700 : 500}
            scrollWidth={isMobile ? 500 : 650}
          />
        ),
        isVisible: hasTermsAndConditions,
      },
      {
        key: "SweepstakesRules",
        label: t("Sweepstakes"),
        children: (
          <RulesWithScroll
            type="SweepstakesRules"
            isNeedAcceptRules={true}
            scrollHeight={isMobile ? 700 : 500}
            scrollWidth={isMobile ? 500 : 650}
          />
        ),
        isVisible: !!isSSEnabled && hasSweepstakes,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasSweepstakes, hasTermsAndConditions, t],
  );

  const [currentTab, setCurrentTab] = useState<TTab>(tabItems[0]);

  const isVisible = useMemo(() => {
    if (!isSSEnabled) {
      if (!needAcceptRules) {
        return false;
      }

      return hasTermsAndConditions;
    }

    return true;
  }, [hasTermsAndConditions, isSSEnabled, needAcceptRules]);

  const handleRuleAccept = () => {
    handleAcceptRule(currentTab.key as TRuleTypes);
  };

  return {
    handleRuleAccept,
    tabItems,
    setCurrentTab,
    currentTab,
    isSSEnabled,
    isVisible,
    acceptRuleMutation,
    handleExit,
  };
};

export type TModalRulesState = ReturnType<typeof useModalRulesState>;
