import type { AssetsBundlesName } from "@app/providers";

export const BUNDLES: AssetsBundlesName[] = [
  "default",
  "backgrounds",
  "lobby-screen",
  "sounds",
  "spine",
  "common",
  "wheel-fortune",
  "mini-game",
];
