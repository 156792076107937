import { useModal } from "@shared/lib/services/search-params/use-modal";

export const useModalSelectLimitState = () => {
  const { isOpen, close } = useModal("select-limit");

  return {
    isOpen,
    close,
  };
};
