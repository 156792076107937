import { Container, Text } from "@pixi/react";
import { TextStyle } from "pixi.js";
import { useMemo } from "react";

import { useRules } from "@entities/lobby";
import { useThemeConfig } from "@shared/lib/hooks";
import { COLOR, Spinner, withScroll } from "@shared/ui";

import type { TRuleTypes } from "@shared/lib/api/types";
import type { TWithScroll } from "@shared/ui";

type TProps = {
  type: TRuleTypes;
  isNeedAcceptRules?: boolean;
} & TWithScroll;

const Rules = ({ type, isNeedAcceptRules = false, scrollWidth }: TProps) => {
  const { rules, needAcceptRules, isSSEnabled, getRulesQuery, getNeedAcceptQuery } = useRules();
  const { fonts } = useThemeConfig();
  const sourceQueryLoader = isNeedAcceptRules ? getNeedAcceptQuery : getRulesQuery;

  const computedRules = useMemo(() => {
    const sourceRules = isNeedAcceptRules ? needAcceptRules : rules;

    if (type === "SweepstakesRules" && isSSEnabled) {
      return sourceRules?.filter((rule) => rule.type === "SweepstakesRules");
    }

    if (type === "TermsAndConditions") {
      return sourceRules?.filter((rule) => rule.type === "TermsAndConditions");
    }

    return null;
  }, [isNeedAcceptRules, isSSEnabled, needAcceptRules, rules, type]);

  if (sourceQueryLoader.isLoading) {
    return <Spinner />;
  }

  if (!computedRules) {
    return null;
  }

  return computedRules.map((rule, index) => {
    return (
      <Container y={40} x={10} key={rule.type}>
        <Text
          anchor={index}
          text={rule.text}
          style={
            new TextStyle({
              ...fonts.primary,
              fontSize: 20,
              fill: COLOR.white,
              wordWrapWidth: scrollWidth - 20,
              wordWrap: true,
              breakWords: true,
              whiteSpace: "pre",
            })
          }
        />
      </Container>
    );
  });
};

export const RulesWithScroll = withScroll(Rules);
