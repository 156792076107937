import gsap from "gsap";
import { memo, useCallback, useEffect, useRef } from "react";

import { useAppStore } from "@app/providers";

import { useGameFrameState } from "./game-frame.state";

export const GameFrame = memo(() => {
  const iframeRef = useRef<null | HTMLIFrameElement>(null);
  const isLayoutLoading = useAppStore(({ isLayoutLoading }) => isLayoutLoading);

  const { gameUrl, iframeStyle, mutationStartupGame, miniGameWins } = useGameFrameState();

  const setIframeRef = useCallback((node: HTMLIFrameElement | null) => {
    if (node) {
      iframeRef.current = node;
    }
  }, []);

  useEffect(() => {
    const iframe = iframeRef.current;

    return () => {
      if (iframe) {
        iframe.src = "";
        iframe.contentDocument?.write("");
      }
    };
  }, [gameUrl]);

  const tweenVisibility = useCallback((isVisible: boolean) => {
    const target = iframeRef.current;
    if (!target) return;

    return gsap.to(target, {
      paused: true,
      duration: 2,
      ease: "none",
      opacity: Number(isVisible),
      display: isVisible ? "block" : "none",
    });
  }, []);

  useEffect(() => {
    if (miniGameWins) {
      tweenVisibility(false)?.restart();
    } else {
      tweenVisibility(true)?.restart();
    }
  }, [miniGameWins, tweenVisibility]);

  if (!gameUrl || isLayoutLoading || mutationStartupGame.isError) {
    return null;
  }

  return (
    <iframe
      ref={setIframeRef}
      allowFullScreen={true}
      id="game-frame"
      key={gameUrl}
      src={gameUrl}
      style={{
        ...iframeStyle,
      }}
    />
  );
});

GameFrame.displayName = "GameFrame";
