import { Container, Text } from "@pixi/react";
import * as PIXI from "pixi.js";
import { memo } from "react";

import { useLobbyButtons } from "@entities/lobby";
import { LogoutButton } from "@features/player";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { FormGroup, Slider, SmartContainer } from "@shared/ui";

import { useSettingsFormState } from "./settings.form.state";

function getContainerPosition(isMobile = false, type: "sound" | "player") {
  if (type === "sound") {
    return {
      x: isMobile ? 0 : -150,
      y: isMobile ? 50 : 50,
    };
  }

  if (type === "player") {
    return {
      x: isMobile ? 0 : 300,
      y: isMobile ? 350 : 50,
    };
  }
}

export const SettingsFormView = memo(() => {
  const { t } = useTranslate();
  const { fonts, isMobile } = useThemeConfig();
  const { buttons } = useLobbyButtons();

  const { setEffectVolume, setBackgroundVolume, defaultBackgroundVolume, defaultEffectVolume } =
    useSettingsFormState();

  const formGroupSoundPosition = getContainerPosition(isMobile, "sound");
  const formGroupPlayerAccountPosition = getContainerPosition(isMobile, "player");

  return (
    <>
      <FormGroup groupName={t("Sound settings")} position={formGroupSoundPosition}>
        <Container y={-20}>
          <Text
            anchor={{ x: 0, y: 1.5 }}
            style={new PIXI.TextStyle({ ...fonts.primary, fontSize: 24 })}
            text={t("music").toUpperCase()}
          />
          <Slider onProgress={setBackgroundVolume} progress={defaultBackgroundVolume} width={360} />
        </Container>
        <Container y={90}>
          <Text
            anchor={{ x: 0, y: 1.5 }}
            style={new PIXI.TextStyle({ ...fonts.primary, fontSize: 24 })}
            text={t("sound").toUpperCase()}
          />
          <Slider onProgress={setEffectVolume} progress={defaultEffectVolume} width={360} />
        </Container>
      </FormGroup>
      <FormGroup groupName={t("Player account")} position={formGroupPlayerAccountPosition}>
        {buttons?.exit ? (
          <SmartContainer paddingLeft={10}>
            <LogoutButton />
          </SmartContainer>
        ) : (
          <Text
            x={100}
            anchor={{ x: 0, y: 0.5 }}
            style={new PIXI.TextStyle({ ...fonts.primary, fontSize: 40 })}
            text={"—"}
          />
        )}
      </FormGroup>
    </>
  );
});

SettingsFormView.displayName = "SettingsFormView";
