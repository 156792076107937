import { Container } from "@pixi/react";

import { get } from "@shared/lib/utils";
import { AlertError } from "@shared/ui/error-queries/alert-error";

import type { UseMutationResult } from "@tanstack/react-query";
import type * as PIXI from "pixi.js";

interface IProps<TData, TError, TResult> {
  mutation: UseMutationResult<TData, TError, TResult, unknown>;
  position?: PIXI.IPointData;
}

export function MutationErrorAlert<TData, TError, TResult>({
  mutation,
  position,
}: IProps<TData, TError, TResult>) {
  const error = get(mutation.error, "message", "Unknown error");

  return (
    <Container renderable={mutation.isError}>
      <AlertError position={position} handleOk={mutation.reset} error={error} />
    </Container>
  );
}
