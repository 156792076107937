import { Container, Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";
import { memo } from "react";

import { JackpotOpenerView } from "@features/toggle-jackpots";
import { useJackpotsContext } from "@shared/lib/hooks";
import { useThemeConfig } from "@shared/lib/hooks/use-theme-config";

import { LeftButtonsGroup } from "./left-buttons-group";
import { RightButtonsGroup } from "./right-buttons-group";

export const Footer = memo(() => {
  const { slots } = useJackpotsContext();

  const { themeWidth, themeHeight, themeScreenDevice, isMobile } = useThemeConfig();
  const footerTexture =
    PIXI.Assets.cache.get("lobby-screen.json").textures[`@${themeScreenDevice}.footer`];

  return (
    <Container y={themeHeight} zIndex={3} x={themeWidth / 2} name="footer" sortableChildren={true}>
      <Sprite anchor={{ x: 0.5, y: 1 }} texture={footerTexture} />
      <Container x={isMobile ? -120 : 0} sortableChildren={true} zIndex={1}>
        <RightButtonsGroup />
        <LeftButtonsGroup />
      </Container>
      {!!slots && <JackpotOpenerView />}
    </Container>
  );
});

Footer.displayName = "Footer";
