import { useAppStore } from "@app/providers";
import { useGameStringId, useModal } from "@shared/lib/services";

export const useLobbyState = () => {
  const { gameStringId } = useGameStringId();
  const isLayoutLoading = useAppStore((state) => state.isLayoutLoading);
  const { isOpen: isSelectLimitModalOpen } = useModal("select-limit");

  return {
    isLayoutLoading,
    gameStringId,
    isSelectLimitModalOpen,
  };
};
