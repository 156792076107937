export class TaggedTextBuilder {
  #text: string = "";
  #prefix: string = "";

  constructor(text?: string) {
    if (text) {
      this.#text = text;
    }
  }

  build() {
    return this.#text;
  }

  add(text: string, condition: boolean = true) {
    if (!condition) {
      return this;
    }

    this.#text += `\n${this.#prefix}${text}`;
    return this;
  }

  addBold(text: string) {
    this.#text += `\n<b>${text}</b>`;
    return this;
  }

  addLine(count: number = 1) {
    this.#text += `\n`.repeat(count);
    return this;
  }

  addPrefix(prefix: string) {
    this.#prefix = prefix;
    return this;
  }
}
