import { Container, Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";
import { memo } from "react";

import { usePosition, useThemeConfig } from "@shared/lib/hooks";
import { AnimatedSpine } from "@shared/ui";

export const AnimatedBackground = memo(() => {
  const { themeWidth, themeHeight, themeScreenDevice, isMobile } = useThemeConfig();
  const position = usePosition();
  const spineData = PIXI.Assets.cache.get("spine/lobby-bg/skeleton.json").spineData;
  const bg = PIXI.Assets.cache.get(`@${themeScreenDevice}.bg.jpg`);
  bg.baseTexture.resolution = 0.5;

  return (
    <Container name="animated-bg">
      <AnimatedSpine
        position={isMobile ? position["right-center"] : position.center}
        isAnimating={true}
        spineData={spineData}
      >
        <Sprite width={themeWidth} height={themeHeight} anchor={0} alpha={1} texture={bg} />
      </AnimatedSpine>
    </Container>
  );
});

AnimatedBackground.displayName = "AnimatedBackground";
