import { Sprite } from "@pixi/react";
import { Texture } from "pixi.js";

import { useThemeConfig } from "@shared/lib/hooks";

export const BgDarkenSprite = () => {
  const { themeHeight, themeWidth } = useThemeConfig();
  return (
    <Sprite
      cacheAsBitmap={true}
      texture={Texture.WHITE}
      alpha={1}
      tint={0x00000}
      interactive={true}
      width={themeWidth}
      height={themeHeight}
    />
  );
};
