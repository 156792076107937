import { Container } from "@pixi/react";
import { useEffect } from "react";

import { useDropdownStore } from "./listbox-context";
import { ListboxLabel } from "./listbox-label";
import { ListboxOption } from "./listbox-option";
import { ListboxOptions } from "./listbox-options";

import type { TChangeCallback } from "./listbox-context";
import type { ReactNode } from "react";

type TProps<Value> = {
  onChange: TChangeCallback<Value>;
  children: (isOpen: boolean) => ReactNode;
};

function ListboxRoot<T>({ onChange, children }: TProps<T>) {
  const setChangeValueCallback = useDropdownStore(
    ({ setChangeValueCallback }) => setChangeValueCallback,
  );
  const isOpen = useDropdownStore(({ isOpen }) => isOpen);

  useEffect(() => {
    setChangeValueCallback(onChange);
  }, [onChange, setChangeValueCallback]);

  return (
    <Container zIndex={1} sortableChildren={true}>
      {children(isOpen)}
    </Container>
  );
}

export const Listbox = Object.assign(ListboxRoot, {
  Label: ListboxLabel,
  Options: ListboxOptions,
  Option: ListboxOption,
});
