import { useMemo, useState } from "react";

import { RulesWithScroll, useLobbyButtons, useRules } from "@entities/lobby";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { Table } from "@widgets/modal-info/ui/tab-content/bonuses/table";

import { BalanceView, BonusesView, Contacts, Jackpots } from "../ui/tab-content";

import type { TTab } from "@shared/ui/tabs/types";

export const useInfoState = () => {
  const { t } = useTranslate();
  const { isMobile } = useThemeConfig();
  const { isSSRulesExist, isTermsAndConditionsExist } = useRules();
  const { buttons } = useLobbyButtons();

  const scrollHeight = isMobile ? styles.mobile.scrollHeight : styles.desktop.scrollHeight;
  const scrollWidth = isMobile ? styles.mobile.scrollWidth : styles.desktop.scrollWidth;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scrollStyles = {
    scrollWidth,
    scrollHeight,
  };

  const selectItems = useMemo(
    () => [
      {
        key: "balance",
        label: t("Balance").toUpperCase(),
        children: <BalanceView />,
        isVisible: true,
      },
      {
        key: "bonuses",
        label: t("Bonuses").toUpperCase(),
        children: <BonusesView {...scrollStyles} />,
        isVisible: true,
      },
      {
        key: "bonus-table",
        label: t("Bonus Table").toUpperCase(),
        children: <Table {...scrollStyles} />,
        isVisible: true,
      },
      {
        key: "jackpots",
        label: t("Jackpots").toUpperCase(),
        children: <Jackpots />,
        isVisible: true,
      },
      {
        key: "contacts",
        label: t("Contacts").toUpperCase(),
        children: <Contacts />,
        isVisible: buttons?.contacts.enabled ?? false,
      },
      {
        key: "terms-and-conditions",
        label: t("T&C").toUpperCase(),
        children: <RulesWithScroll type="TermsAndConditions" {...scrollStyles} />,
        isVisible: isTermsAndConditionsExist,
      },
      {
        key: "sweepstakes",
        label: t("Sweepstakes").toUpperCase(),
        children: <RulesWithScroll type="SweepstakesRules" {...scrollStyles} />,
        isVisible: isSSRulesExist,
      },
    ],

    [buttons?.contacts.enabled, isSSRulesExist, isTermsAndConditionsExist, scrollStyles, t],
  );

  const selectFilteredItems = selectItems.filter((item) => item.isVisible);
  const [currentTab, setCurrentTab] = useState<TTab>(selectFilteredItems[0]);

  return {
    currentTab,
    setCurrentTab,
    selectFilteredItems,
    t,
  };
};

const styles = {
  mobile: {
    scrollWidth: 460,
    scrollHeight: 680,
  },
  desktop: {
    scrollWidth: 700,
    scrollHeight: 500,
  },
};

export type TInfoState = ReturnType<typeof useInfoState>;
