export type TLocalStorageKey = "sound" | "bgm" | "sfx" | "authLogin" | "authRemember";

class LocalStorage {
  public saveData(key: TLocalStorageKey, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getData(key: TLocalStorageKey) {
    return JSON.parse(localStorage.getItem(key) || "null");
  }

  public removeData(key: TLocalStorageKey) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }
}

export const localStorageService = new LocalStorage();
