import { useThemeConfig } from "@shared/lib/hooks";

import { GameListDesktop } from "./desktop/list-desktop";
import { GameListMobileHTML } from "./mobile/list-mobile-html";

export const GameListView = () => {
  const { isMobile } = useThemeConfig();

  const GameListUI = isMobile ? GameListMobileHTML : GameListDesktop;

  return (
    <>
      <GameListUI />
    </>
  );
};

GameListView.displayName = "SuspenseGameList";
