import { Container } from "@pixi/react";
import { useMemo } from "react";

import { GiftAnimation } from "@entities/bonus";
import { useLobbyState } from "@pages/lobby/model/lobby.state";
import { BgDarkenSprite } from "@shared/ui";
import { DailyWheel } from "@widgets/daily-wheel";
import { Footer } from "@widgets/footer";
import { Header } from "@widgets/header";
import { Jackpots } from "@widgets/jackpots";
import { MiniGameView } from "@widgets/mini-game";
import { ModalBonusView } from "@widgets/modal-bonus";
import { ModalEditSettingsView } from "@widgets/modal-edit-settings";
import { ModalInfoView } from "@widgets/modal-info";
import { ModalSelectLimitView } from "@widgets/modal-select-limit";

import { AnimatedBackground } from "./animated-background";
import { LayoutContent } from "./content";
import { SpinnerLayout } from "./spinner-layout";

export const Layout = () => {
  const { gameStringId, isSelectLimitModalOpen, isLayoutLoading } = useLobbyState();

  const renderLayout = useMemo(() => {
    if (gameStringId && !isSelectLimitModalOpen && !isLayoutLoading) {
      return (
        <>
          <BgDarkenSprite />
          <MiniGameView />
        </>
      );
    }

    return (
      <>
        <AnimatedBackground />
        <GiftAnimation />
        <Footer />
        <SpinnerLayout />
        <LayoutContent />
        <ModalInfoView />
        <ModalBonusView />
        <DailyWheel />
        <Jackpots />
        <ModalEditSettingsView />
        <ModalSelectLimitView />
      </>
    );
  }, [isLayoutLoading, gameStringId, isSelectLimitModalOpen]);

  return (
    <>
      <Container sortableChildren={true} name="layout-container">
        <Header />
        {renderLayout}
      </Container>
    </>
  );
};

Layout.displayName = "Layout";
