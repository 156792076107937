import { Container } from "@pixi/react";
import { forwardRef, memo } from "react";

import { sfx } from "../../lib/services";

import type { _ReactPixi } from "@pixi/react";
import type * as PIXI from "pixi.js";
import type { FederatedEvent } from "pixi.js";
import type { FC, PropsWithChildren, Ref } from "react";

type IContainer = _ReactPixi.IContainer;

interface IClickableContainerProps extends PropsWithChildren<IContainer> {
  onpointerup?: (event: FederatedEvent) => void;
  onpointerdown?: (event: FederatedEvent) => void;
  onmouseenter?: (event: FederatedEvent) => void;
  onmouseleave?: (event: FederatedEvent) => void;
  ontouchstart?: (event: FederatedEvent) => void;
  ontouchend?: (event: FederatedEvent) => void;
}

export const ClickableContainer: FC<IClickableContainerProps> = memo(
  forwardRef(
    (
      {
        children,
        onpointerup,
        onpointerdown,
        onmouseenter,
        onmouseleave,
        ontouchstart,
        ontouchend,
        ...props
      },
      ref: Ref<PIXI.Container>,
    ) => {
      const handleMouseLeave = (event: FederatedEvent) => {
        onmouseleave?.(event);
      };

      const handleMouseEnter = (event: FederatedEvent) => {
        onmouseenter?.(event);
      };

      const handlePointerDown = (event: FederatedEvent) => {
        onpointerdown?.(event);
      };

      const handlePointerUp = (event: FederatedEvent) => {
        sfx.play("sounds/click.mp3");
        onpointerup?.(event);
      };

      const handleTouchStart = (event: FederatedEvent) => {
        ontouchstart?.(event);
      };

      const handleTouchEnd = (event: FederatedEvent) => {
        ontouchend?.(event);
      };

      return (
        <Container
          ref={ref}
          interactive={true}
          interactiveChildren={true}
          cursor="pointer"
          eventMode="dynamic"
          onmousedown={handleTouchStart}
          onmouseup={handleMouseEnter}
          onpointerup={handlePointerUp}
          onpointerdown={handlePointerDown}
          onmouseenter={handleMouseEnter}
          onmouseleave={handleMouseLeave}
          ontouchstart={handleTouchStart}
          ontouchend={handleTouchEnd}
          {...props}
        >
          {children}
        </Container>
      );
    },
  ),
);

ClickableContainer.displayName = "ClickableContainer";
