export const SHOW_ALL = -1;
export const TAG_RECOMMENDATION_ID = -2;
export const TAG_FAVORITE = 0;

export const PROVIDER_NAME_LIVE = "live";
export const PROVIDER_ID_LIVE = 8;

export const TAG_DEFAULT_ID = SHOW_ALL;

export const BACKGROUND_VOLUME_DEFAULT = 0.5;
export const EFFECT_VOLUME_DEFAULT = 0.5;

export const TAG_HTML = "html";
export const TAG_MOBILE = "mobile";
export const MOBILE_TAGS = new Set([TAG_HTML, TAG_MOBILE]);
export const TAG_SWIPE_UP = "swipe-up-fix";
export const TAG_NO_SWIPE_UP = "disable-swipe-up-fix";
export const TAG_ONLY_PORTRAIT = "only-portrait";
export const TAG_ONLY_LANDSCAPE = "only-landscape";
export const TECHNICAL_TAGS = new Set([
  TAG_HTML,
  TAG_MOBILE,
  TAG_SWIPE_UP,
  TAG_NO_SWIPE_UP,
  TAG_ONLY_PORTRAIT,
  TAG_ONLY_LANDSCAPE,
]);

export const providersNameMap = {
  live: {
    name: "live",
    tags: ["roulette", "super", "blackjack", "baccarat"],
  },
};
