import { useContext } from "react";

import { MiniGameContext } from "./mini-game-context-provider";

export const useMiniGameContext = () => {
  const miniGameModel = useContext(MiniGameContext);
  if (!miniGameModel) {
    throw new Error("Provider MiniGame not found");
  }

  return miniGameModel;
};
