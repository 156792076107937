import { memo, useCallback, useRef } from "react";
import { Pagination, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { useLobbyStore } from "@/entities/lobby";
import { useAppStore } from "@app/providers";
import { bonusModel } from "@entities/bonus";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { Tunnel } from "@shared/lib/vendors/tunnel-rat";

import { useGameListState } from "../../lib/game-list.state";

import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";

type TCard = {
  card: {
    pic: string;
    string_id: string;
  };
  handleGameStart: (stringId: string) => void;
};

const styles = {
  card: {
    position: "relative",
    display: "inline-block",
    width: "380px",
    height: "380px",
    boxSizing: "border-box",
    background: "linear-gradient(90deg, #E8664E 0%, #F5BC17 100%)",
    clipPath: "polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)",
    WebkitClipPath:
      "polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)",
    outline: "none",
    border: "none",
  },
  img: {
    position: "absolute",
    top: "20px",
    left: "20px",
    width: "340px",
    height: "340px",
    objectFit: "cover",
    WebkitClipPath:
      "polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)",
    clipPath: "polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "790px",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    gap: 30,
    height: 1200,
    overflowX: "scroll",
    overflowY: "hidden",
  },
} as const;

export const GameListMobileHTML = memo(() => {
  const { t } = useTranslate();
  const { ui } = Tunnel.getInstance();
  const { handleGameStart, computedGamesByGroup, handleNext, handlePrev } = useGameListState();

  const { themeWidth } = useThemeConfig();
  const gameListRef = useRef<HTMLDivElement | null>(null);
  const isAnimationBonusTakeSuccess = bonusModel.useIsAnimationBonusTakeSuccess();
  const gameOpenError = useAppStore(({ gameOpenError }) => gameOpenError);

  const gameSceneAspectRatio = useAppStore(
    ({ lobbyConfig: { gameSceneAspectRatio } }) => gameSceneAspectRatio,
  );
  const isWheelFortuneWidgetVisible = useAppStore(
    ({ isWheelFortuneWidgetVisible }) => isWheelFortuneWidgetVisible,
  );
  const isJackpotsWidgetVisible = useAppStore(
    ({ isJackpotsWidgetVisible }) => isJackpotsWidgetVisible,
  );
  const isLayoutLoading = useAppStore(({ isLayoutLoading }) => isLayoutLoading);
  const isModalOpened = useAppStore(({ modal }) => modal);
  const isSideMenuStartedOpen = useLobbyStore(({ isSideMenuStartedOpen }) => isSideMenuStartedOpen);

  const isHiddenList =
    isJackpotsWidgetVisible ||
    isLayoutLoading ||
    isWheelFortuneWidgetVisible ||
    isModalOpened ||
    isAnimationBonusTakeSuccess ||
    gameOpenError;

  const setListCallback = useCallback((node: HTMLDivElement) => {
    if (node) {
      gameListRef.current = node;
    }
  }, []);

  if (!computedGamesByGroup) {
    return <div>{t("no available games").toUpperCase()}</div>;
  }

  return (
    <>
      <ui.In>
        <div
          style={{
            display: isHiddenList ? "none" : "block",
            position: "absolute",
            width: themeWidth - 230,
            height: 1290,
            transform: `translateX(-50%) scale(${gameSceneAspectRatio}) translate(30px, 144px) translateX(${
              isSideMenuStartedOpen ? 330 : 0
            }px)`,
            top: 0,
            left: "50%",
            transformOrigin: "center top",
            transition: "transform 0.3s linear",
          }}
          key="mobile-game-list"
          ref={setListCallback}
        >
          <Swiper
            onPaginationRender={(_, paginationEl) => (paginationEl.style.top = "1220px")}
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 5,
            }}
            style={{ height: "100%" }}
            modules={[Virtual, Pagination]}
            virtual={{
              addSlidesAfter: 2,
              addSlidesBefore: 2,
              enabled: true,
            }}
            spaceBetween={50}
            slidesPerView={1}
            onSlidePrevTransitionStart={handlePrev}
            onSlideNextTransitionStart={handleNext}
          >
            {computedGamesByGroup.map((group, index) => (
              <SwiperSlide key={`swiper-${index}`}>
                <div style={styles.container}>
                  {group.map((card) => {
                    if (!card) return;
                    return (
                      <GameCardHTML
                        key={`game-card-${card.string_id}`}
                        card={card}
                        handleGameStart={handleGameStart}
                      />
                    );
                  })}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </ui.In>
    </>
  );
});

function GameCardHTML({ card, handleGameStart }: TCard) {
  const alt = `game ${card.string_id}`;
  return (
    <button style={{ ...styles.card }} onClick={() => handleGameStart(card.string_id)}>
      <img style={styles.img} src={card.pic} alt={alt} />
    </button>
  );
}

GameListMobileHTML.displayName = "GameListMobileHTML";
