import { Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";

import { useAppStore } from "@app/providers";
import { ClickableContainer } from "@shared/ui";

export const JackpotButton = () => {
  const jpHandleButtonTexture = PIXI.Assets.cache.get("lobby-screen.json").textures[`jackpot`];

  const toggleJackpotsWidgetVisible = useAppStore((state) => state.toggleJackpotsWidgetVisible);

  return (
    <>
      <ClickableContainer sortableChildren={true} onpointerup={toggleJackpotsWidgetVisible}>
        <Sprite zIndex={2} anchor={0.5} texture={jpHandleButtonTexture} />
      </ClickableContainer>
    </>
  );
};
