import * as PIXI from "pixi.js";

import { ScrollBarContents, ScrollBarView } from "@shared/lib";

import { ScrollbarThumb } from "./scrollbar-thumb";
import { ScrollbarTrack } from "./scrollbar-track";
import { ScrollViewContentMask } from "./scrollview-content-mask";

export interface IScrollView {
  width: number;
  height: number;
  content: PIXI.Container;
  app: PIXI.Application;
  scrollType: "horizontal" | "vertical";
  paddingBetweenContentAndScrollBar?: number;
  isScrollbarHidden: boolean;
}

export class ScrollView extends PIXI.Container {
  public scrollbar: ScrollBarView;

  constructor({
    width,
    height,
    content,
    app,
    paddingBetweenContentAndScrollBar = 15,
    scrollType,
    isScrollbarHidden,
  }: IScrollView) {
    super();

    const isHorizontal = scrollType === "horizontal";

    const SCROLLBAR_W = isHorizontal ? width : 16;
    const SCROLLBAR_H = isHorizontal ? 16 : height;

    const contentsMask = new ScrollViewContentMask({
      contentWidth: width,
      trackHeight: isHorizontal ? height : SCROLLBAR_H,
    });

    const track = new ScrollbarTrack({
      trackWidth: SCROLLBAR_W,
      trackHeight: SCROLLBAR_H,
      isScrollbarHidden,
      color: 0x162f83,
    });

    const thumb = new ScrollbarThumb({
      thumbSize: 16,
      color: 0xffb23f,
      isScrollbarHidden,
    });

    const contents = new ScrollBarContents(content, contentsMask, this);

    const option = {
      track,
      thumb,
      minPosition: 0,
      maxPosition: isHorizontal ? SCROLLBAR_W : SCROLLBAR_H,
      rate: 0,
      isHorizontal,
      canvas: app.view as HTMLCanvasElement,
    };

    const scrollbar = new ScrollBarView(option, contents);

    if (isHorizontal) {
      scrollbar.y = 0;
      scrollbar.x = 0;
    } else {
      scrollbar.x = this.x + content.width + paddingBetweenContentAndScrollBar;
    }

    this.scrollbar = scrollbar;
    this.addChild(scrollbar); // add scrollbar // calculate content scroll
    this.addChild(content); // add scrollable content
  }

  public destroy() {
    this.scrollbar.destroyTicker();
  }
}
