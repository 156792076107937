import * as PIXI from "pixi.js";
import { memo, useState } from "react";

import { useAppStore } from "@app/providers";
import { playerModel } from "@entities/player";
import { useThemeConfig } from "@shared/lib/hooks";
import { AnimatedSpine, ClickableContainer } from "@shared/ui";

import { CooldownWheelTimer } from "./cooldown-wheel-timer";

export const ToggleDailyWheelButtonView = memo(() => {
  const { isMobile } = useThemeConfig();
  const toggleWheelFortuneWidgetVisible = useAppStore(
    (state) => state.toggleWheelFortuneWidgetVisible,
  );
  const player = playerModel.usePlayer();
  // const paddingY = themeScreenDevice === "mobile" ? 290 : 120;

  const [timeRemaining, setTimeRemaining] = useState(1);
  const wheelFortuneReadyTimestamp = player?.bankGroup?.fortuneWheel?.nextWinTime ?? 0;

  const wheelFortuneTimeExpired =
    wheelFortuneReadyTimestamp < Date.now() / 1000 || timeRemaining <= 0;

  const handleWheelWidgetOpen = () => {
    if (!wheelFortuneTimeExpired) {
      return;
    }

    toggleWheelFortuneWidgetVisible();
  };

  const wheelLogoAnimData = PIXI.Assets.cache.get("spine/wheel/logo/skeleton.json").spineData;

  if (wheelLogoAnimData && player?.bankGroup?.fortuneWheel?.isActive) {
    return (
      <ClickableContainer
        zIndex={0}
        scale={isMobile ? 0.9 : 1}
        onpointerup={handleWheelWidgetOpen}
        position={{ x: 0, y: 0 }}
      >
        <AnimatedSpine animationName={"animation"} spineData={wheelLogoAnimData} />
        {!wheelFortuneTimeExpired && (
          <CooldownWheelTimer setTimeRemaining={setTimeRemaining} timeRemaining={timeRemaining} />
        )}
      </ClickableContainer>
    );
  }
});

ToggleDailyWheelButtonView.displayName = "ToggleDailyWheelButtonView";
