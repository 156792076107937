import { Container, Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";
import { memo } from "react";

import { useAppStore } from "@/app/providers";
import { bonusModel } from "@entities/bonus";
import { PlayerAvatar, PlayerLogin } from "@entities/player";
import { ToggleFullscreen } from "@features/toggle-fullscreen";
import { ToggleGameClose } from "@features/toggle-game-close";
import { ToggleLiveProvider } from "@features/toggle-live-provider";
import { useThemeConfig } from "@shared/lib/hooks/use-theme-config";
import { HeaderBalance } from "@widgets/header/ui/header-balance";
import { HeaderLogo } from "@widgets/header/ui/header-logo";
import { ToggleBonus } from "src/features/toggle-bonus";

export const Header = memo(() => {
  const { themeConfig, themeWidth, isMobile } = useThemeConfig();
  const { bonus, isAvailable } = bonusModel.useActiveBonus();
  const isIOS = useAppStore(({ lobbyConfig: { isIOS } }) => isIOS);

  return (
    <Container zIndex={2} name="header">
      <Sprite
        width={themeWidth}
        height={themeConfig.header.height}
        anchor={{ x: 0, y: 0 }}
        texture={PIXI.Assets.cache.get("lobby-screen.json").textures[`header`]}
      />
      <PlayerAvatar position={{ x: 50, y: 50 }} />
      <PlayerLogin />
      <HeaderBalance />
      <HeaderLogo />
      <ToggleGameClose position={{ x: isMobile ? themeWidth - 200 : themeWidth - 500, y: 55 }} />
      <ToggleLiveProvider position={{ x: themeWidth - 500, y: 50 }} />
      {!isIOS && <ToggleFullscreen />}
      {bonus && isAvailable && <ToggleBonus />}
    </Container>
  );
});

Header.displayName = "Header";
