import { Container } from "@pixi/react";
import * as PIXI from "pixi.js";

import { AnimatedSpine } from "@shared/ui";

import type { Spine } from "pixi-spine";
import type { IPointData } from "pixi.js";

export const Spinner = ({
  position = { x: 0, y: 0 },
  scale = { x: 1, y: 1 },
}: {
  position?: IPointData;
  scale?: IPointData;
}) => {
  const spineData = PIXI.Assets.cache.get<Spine>("spine/loader/skeleton.json").spineData;

  return (
    <Container position={position && position}>
      <AnimatedSpine spineData={spineData} scale={scale} />
    </Container>
  );
};
