import { type PropsWithChildren } from "react";
import { IntlProvider } from "react-intl";

export function ReactIntlProvider({ children }: PropsWithChildren) {
  return (
    <IntlProvider
      locale="en"
      onError={(error) => console.error(error)}
      onWarn={(warn) => console.warn(warn)}
    >
      {children}
    </IntlProvider>
  );
}
