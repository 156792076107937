import { Text } from "@pixi/react";
import gsap from "gsap";
import { TextStyle } from "pixi.js";
import { useCallback, useMemo, useState } from "react";

import { playerModel } from "@entities/player";
import { useThemeConfig } from "@shared/lib/hooks";
import { formatter } from "@shared/lib/utils";

import { JackpotOpenerState } from "../lib/jackpot-opener.state";

import type * as PIXI from "pixi.js";

export const SlotsRewardText = () => {
  const [count, setCount] = useState(0);
  const { slots } = JackpotOpenerState();
  const { fonts } = useThemeConfig();
  const { currency } = playerModel.usePlayer();

  const wrapper = useCallback(
    (node: PIXI.Text) => {
      if (node && slots?.length) {
        gsap.to(node, {
          repeat: -1,
          duration: 2,
          repeatDelay: 5,
          ease: "none",
          pixi: { x: 0 },
          onRepeat: () => {
            setCount((prevState) => (prevState < slots?.length - 1 ? prevState + 1 : 0));
          },
        });
      }
    },
    [slots?.length],
  );

  const jpText = useMemo(() => {
    if (slots && count < slots?.length) {
      return `${slots[count]?.name} ${formatter.formatMoney(formatter.toMoney(slots[count]?.value))} ${currency}`;
    }
  }, [count, currency, slots]);

  return (
    <Text
      x={-40}
      renderable={!!slots && !!jpText}
      ref={wrapper}
      anchor={{ x: 0.5, y: 0 }}
      text={jpText?.toUpperCase()}
      style={
        new TextStyle({
          ...fonts.primary,
          ...fonts.jpsRewardStyle,
        })
      }
    />
  );
};
