import { Text } from "@pixi/react";

import { useThemeConfig } from "@shared/lib/hooks";

type TProps = {
  type?: "primary" | "secondary";
  text: string;
  wordWrapWidth?: number;
  anchorY?: number;
};

export function Title({ text, type = "primary", wordWrapWidth, anchorY }: TProps) {
  const { fonts } = useThemeConfig();

  if (type === "primary") {
    return (
      <Text
        anchor={{ x: 0.5, y: anchorY ?? 0.5 }}
        text={text}
        style={{
          ...fonts.primary,
          fontSize: 50,
          letterSpacing: 4,
          wordWrapWidth: wordWrapWidth ?? 800,
          wordWrap: true,
          breakWords: true,
          lineJoin: "bevel",
          stroke: "black",
          strokeThickness: 5,
          align: "center",
        }}
      />
    );
  }

  if (type === "secondary") {
    return null;
  }
}
