import { Container } from "@pixi/react";
import * as PIXI from "pixi.js";

import { ButtonSecondary } from "@shared/ui";

import type { IPointData } from "pixi.js";

export const PlayerAvatar = ({ position }: { position?: IPointData }) => {
  return (
    <Container position={position}>
      <ButtonSecondary
        onPress={() => console.log("open profile")}
        width={50}
        height={50}
        iconSize={30}
        disabled={true}
        iconTexture={PIXI.Assets.cache.get("lobby-screen.json").textures[`icon-avatar`]}
      />
    </Container>
  );
};
