import type { ISlot, IWin } from "@shared/lib/api/types";

const JACKPOT_ID = "jp-ex";

export const _normalize = (data: any): { slots: ISlot[] | null; wins: IWin[] | null } => {
  if (Array.isArray(data?.slots)) {
    data.slots = _normalizeSlots(data.slots);
  }

  if (Array.isArray(data?.wins)) {
    data.wins = data.wins.map(_normalizeWin);
  }

  return data;
};

export const _normalizeSlots = (slots: Record<string, any>): ISlot[] => {
  return slots.filter((item: any) => item.jackpot_id === JACKPOT_ID).map(_normalizeSlot);
};

export const _normalizeSlot = ({
  is_community: isCommunity,
  jackpot_id: jackpotId,
  value,
  slot,
}: { isCommunity: boolean } & Record<string, any>): {
  jackpotId: number;
  slot: string;
  isCommunity: boolean;
  value: number;
} => ({
  isCommunity,
  jackpotId,
  value,
  slot,
});

export const _normalizeWin = ({ jackpot_id: jackpotId, win_id: id, value, state, slot }: any) => ({
  jackpotId,
  value,
  state,
  slot,
  id,
});
