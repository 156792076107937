import { Container, Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";
import { useCallback, useRef } from "react";

import { usePosition, useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { MutationButtonPrimary } from "@shared/ui";

import { useMiniGameContext } from "../../../lib/use-mini-game-context";

import { Reels } from "./reels";

import type { Reel } from "../../../lib/pixi/reel";

export const Slot = () => {
  const { t } = useTranslate();
  const miniGameModel = useMiniGameContext();
  const { themeConfig } = useThemeConfig();
  const position = usePosition();
  const reels = useRef<Reel[]>([]);
  const miniGameBg = PIXI.Assets.cache.get("mini-game-bg");

  const handleSpin = useCallback(() => {
    for (const reel of reels.current) {
      reel.start();
      miniGameModel.updateReelStatus("spin-start");
    }
  }, [miniGameModel]);

  return (
    <Container position={position.center} sortableChildren={true} zIndex={2}>
      <Sprite
        width={themeConfig.jackpots.miniGame.slotSize[0]}
        height={themeConfig.jackpots.miniGame.slotSize[1]}
        zIndex={0}
        anchor={0.5}
        texture={miniGameBg}
      />
      <Reels
        miniGameModel={miniGameModel}
        position={{
          x: themeConfig.jackpots.miniGame.reelsPosition.x,
          y: themeConfig.jackpots.miniGame.reelsPosition.y,
        }}
        reels={reels}
      />
      <MutationButtonPrimary
        title={t("spin")}
        disabled={miniGameModel.reelStatus === "spin-start"}
        onPress={handleSpin}
        position={{
          x: themeConfig.jackpots.miniGame.spinButtonPosition.x,
          y: themeConfig.jackpots.miniGame.spinButtonPosition.y,
        }}
      />
    </Container>
  );
};
