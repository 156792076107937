import { Container, Graphics, Text } from "@pixi/react";
import * as PIXI from "pixi.js";
import { useCallback } from "react";

import { bonusModel } from "@entities/bonus";
import { gameModel } from "@entities/game";
import { playerModel } from "@entities/player";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { useModal } from "@shared/lib/services/search-params";
import { formatter } from "@shared/lib/utils";
import { AnimatedSpine, ClickableContainer, Spinner } from "@shared/ui";

export const ToggleBonus = () => {
  const { t } = useTranslate();

  const { fonts, themeWidth, themeHeight, isMobile } = useThemeConfig();
  const { bonus, isLoading } = bonusModel.useActiveBonus();
  const { currency, isLoading: isCurrencyLoading } = playerModel.usePlayer();
  const { currentGameUrl } = gameModel.useGameController();

  const { open } = useModal("bonus");

  const spineData = PIXI.Assets.cache.get("spine/bonus/skeleton.json").spineData;

  const draw = useCallback(
    (g: PIXI.Graphics) => {
      g.clear();
      g.beginFill(fonts.infoActiveTabStyle.color);
      g.lineStyle(2, fonts.spinner.fillColor);
      g.drawRoundedRect(0, 0, 260, 80, 12);
      g.endFill();
    },
    [fonts.infoActiveTabStyle.color, fonts.spinner.fillColor],
  );

  if (isLoading || isCurrencyLoading) {
    return <Spinner />;
  }

  if (bonus?.amount && !currentGameUrl) {
    const paddingX = isMobile ? themeWidth / 2 + 260 : 350;
    const paddingY = isMobile ? themeHeight - 500 : 10;

    return (
      <ClickableContainer onpointerup={open} position={{ x: themeWidth - paddingX, y: paddingY }}>
        <Container scale={isMobile ? 2 : 1}>
          <Graphics draw={draw} />
          <Text
            anchor={{ x: -0.45, y: -0.5 }}
            text={`${t("take")} ${bonus.type}`.toUpperCase()}
            style={
              new PIXI.TextStyle({
                ...fonts.primary,
                fontSize: 18,
              })
            }
          />
          <Text
            anchor={{ x: 0.5, y: 0.5 }}
            position={{ x: 260 / 2 + 20, y: 80 / 1.6 }}
            text={`${formatter.formatMoney(formatter.toMoney(bonus.amount))} ${currency}`}
            style={
              new PIXI.TextStyle({
                ...fonts.primary,
                fontSize: 20,
                align: "center",
              })
            }
          />
          <AnimatedSpine
            spineData={spineData}
            position={{ x: 40, y: 25 }}
            scale={{ x: 0.7, y: 0.7 }}
          />
        </Container>
      </ClickableContainer>
    );
  }

  return null;
};
