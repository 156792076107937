import { Redirect, Route, Switch } from "wouter";

import { RootGuard } from "@app/guards/root-guard";
import { RootLayout } from "@app/layout/root-layout";
import { PixiApplication } from "@app/providers";
import { LoginPage } from "@pages/auth/ui/page";
import { GameFrame } from "@pages/game-frame/game-frame";
import { AsyncLoadableBundle } from "@shared/ui";

import { BUNDLES } from "../pixi/const";

export function ReactRouterProvider() {
  return (
    <RootLayout>
      <AsyncLoadableBundle bundleNames={BUNDLES}>
        {(isLoaded) => {
          if (!isLoaded) {
            return null;
          }

          return (
            <>
              <PixiApplication
                options={{
                  resolution: window.devicePixelRatio,
                  backgroundAlpha: 0,
                  useContextAlpha: false,
                  antialiasing: false,
                  background: 0x010101,
                  hello: true,
                }}
              >
                <Switch>
                  <Route path="/sign-in" component={LoginPage} />
                  <Route path="/lobby" component={RootGuard} />
                  <Route path="*" component={() => <Redirect to={"/sign-in"} />} />
                </Switch>
              </PixiApplication>
              <GameFrame />
            </>
          );
        }}
      </AsyncLoadableBundle>
    </RootLayout>
  );
}
