import { memo } from "react";
import { Redirect } from "wouter";

import { playerModel } from "@entities/player";

import { LoginPageContainer } from "./page.view";

export const LoginPage = memo(() => {
  const player = playerModel.usePlayer();

  if (player?.isSuccess && player?.bankGroup) {
    return <Redirect to={"/lobby"} />;
  }

  return <LoginPageContainer />;
});

LoginPage.displayName = "LoginPage";
