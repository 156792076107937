import { Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";
import { memo } from "react";

import { useAppStore } from "@app/providers";
import { usePosition, useThemeConfig } from "@shared/lib/hooks";
import { SmartContainer, SpinnerWithBg } from "@shared/ui";
import { AuthForm } from "src/features/player/auth-form";

export const LoginPageContainer = memo(() => {
  const { themeScreenDevice } = useThemeConfig();
  const position = usePosition();

  const isLayoutLoading = useAppStore((state) => state.isLayoutLoading);
  const textureBackground = `@${themeScreenDevice}.bg.jpg`;

  const bg = PIXI.Assets.get<PIXI.Texture>(textureBackground);

  const defaultScale = themeScreenDevice === "mobile" ? 1.8 : 1;

  return (
    <>
      <Sprite name="login-bg" texture={bg}>
        <SmartContainer scale={defaultScale} centeredChild={true} position={position["center"]}>
          <AuthForm />
        </SmartContainer>
      </Sprite>
      {isLayoutLoading && <SpinnerWithBg />}
    </>
  );
});

LoginPageContainer.displayName = "LoginPageContainer";
