import { useEffect } from "react";

import { bonusModel } from "@entities/bonus/model";
import { playerModel } from "@entities/player";
import { useModal } from "@shared/lib/services";

export const useModalBonusState = () => {
  const { isOpen, close } = useModal("bonus");
  const { handleBonusUse, useBonusMutation } = bonusModel.useHandleBonus();
  const { bonus } = bonusModel.useActiveBonus();
  const { currency } = playerModel.usePlayer();

  useEffect(() => {
    if (useBonusMutation.isSuccess) {
      close();
    }
  }, [close, useBonusMutation.isSuccess]);

  return {
    isOpen,
    handleBonusAccept: handleBonusUse,
    bonus,
    useBonusMutation,
    currency,
  };
};
