import { Container } from "@pixi/react";
import * as PIXI from "pixi.js";
import { memo, useCallback, useDeferredValue, useRef } from "react";

import { CARD_SPRITE_HEIGHT, CARD_SPRITE_WIDTH, GameCard } from "@entities/game";
import { PROVIDER_ID_LIVE, TAG_DEFAULT_ID } from "@shared/lib/consts";
import { useThemeConfig } from "@shared/lib/hooks";
import { useProviderId, useTagId } from "@shared/lib/services";

import { useGameListState } from "../../lib/game-list.state";

import { ListSkeleton } from "./list-skeleton";
import { NextButton } from "./next-button";
import { PrevButton } from "./prev-button";

export const GameListDesktop = memo(() => {
  const {
    computedGamesByGroup,
    totalPages,
    handleGameStart,
    page,
    setPage,
    isGamesLoading,
    handlePrev,
    handleNext,
  } = useGameListState();
  const { providerId } = useProviderId();
  const { tagId } = useTagId();
  const { themeConfig, themeWidth, themeHeight } = useThemeConfig();
  const gameListRef = useRef<PIXI.Container | null>(null);

  const isVigProvider = providerId === PROVIDER_ID_LIVE && tagId === TAG_DEFAULT_ID;
  const area = new PIXI.Rectangle(-200, 0, themeWidth * 0.95, themeHeight * 0.8);
  const isLastPage = page === totalPages;
  const isFirstPage = page === 1;
  const paddingX = isVigProvider ? themeWidth * 0.26 : themeWidth * 0.15;
  const paddingY = themeHeight * 0.15;
  const scale = { x: 1, y: 1 };

  const setContainerRef = useCallback(
    (node: PIXI.Container) => {
      if (node && totalPages) {
        gameListRef.current = node;
      }
      setPage(0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalPages],
  );

  const deferredComputedGamesByGroup = useDeferredValue(computedGamesByGroup);

  if (isGamesLoading) {
    return (
      <Container name="game-list-skeleton" zIndex={0} x={paddingX} y={paddingY} scale={scale}>
        <ListSkeleton containerWidth={isVigProvider ? themeWidth * 0.7 : themeWidth * 0.9} />
      </Container>
    );
  }

  return (
    <>
      <Container
        filterArea={area}
        cullArea={area}
        hitArea={area}
        name="game-list-wrapper-desktop"
        zIndex={0}
        x={paddingX}
        y={paddingY}
        scale={scale}
      >
        <Container name="game-list" ref={setContainerRef} x={CARD_SPRITE_WIDTH}>
          {deferredComputedGamesByGroup.map((cards, index) => (
            <Container
              key={cards[0].string_id + cards.at(1)?.string_id}
              position={{ x: (CARD_SPRITE_WIDTH + 60) * index, y: -20 }}
              cullable
            >
              <GameCard
                stringId={cards[0]!.string_id}
                gameScale={themeConfig.gameList.gameScale}
                position={{ x: -CARD_SPRITE_WIDTH - 100, y: 0 }}
                width={CARD_SPRITE_WIDTH}
                height={CARD_SPRITE_HEIGHT}
                name={cards[0].name}
                textureSourceUrl={cards[0].pic}
                onClick={handleGameStart}
              />
              {cards.at(1) && (
                <GameCard
                  stringId={cards[1]!.string_id}
                  gameScale={themeConfig.gameList.gameScale}
                  position={{
                    x: isVigProvider ? -250 : -CARD_SPRITE_WIDTH - 100,
                    y: CARD_SPRITE_HEIGHT + 50,
                  }}
                  width={CARD_SPRITE_WIDTH}
                  height={CARD_SPRITE_HEIGHT}
                  name={cards.at(1)?.name ?? ""}
                  textureSourceUrl={cards[1]?.pic ?? ""}
                  onClick={handleGameStart}
                />
              )}
            </Container>
          ))}
        </Container>
      </Container>
      {!isFirstPage && <PrevButton ref={gameListRef} handlePrev={handlePrev} />}
      {!isLastPage && <NextButton ref={gameListRef} handleNext={handleNext} />}
    </>
  );
});

GameListDesktop.displayName = "GameListDesktop";
