import { Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";

import { ClickableContainer } from "@shared/ui";

import type { FederatedEvent, IPointData, Spritesheet } from "pixi.js";

type TProps = {
  onClick: (event: FederatedEvent) => void | VoidFunction;
  position?: IPointData;
  anchor: IPointData | number;
  pivot?: IPointData | number;
  angle?: number;
  type?: "arrow-exit" | "arrow-next";
  scale?: number;
};
export const ArrowButton = ({
  onClick,
  position,
  anchor,
  pivot = { x: 0, y: 0 },
  angle = 0,
  type = "arrow-exit",
  scale = 1,
}: TProps) => {
  const iconGameClose = PIXI.Assets.cache.get("common.json").textures[`icon-game-exit`];
  const iconGamesNext = PIXI.Assets.cache.get<Spritesheet>("common.json").textures[`arrow`];

  return (
    <ClickableContainer name="icon-arrow" zIndex={0} onpointerup={onClick} position={position}>
      <Sprite
        scale={scale}
        angle={angle}
        pivot={pivot}
        anchor={anchor}
        texture={type === "arrow-exit" ? iconGameClose : iconGamesNext}
      />
    </ClickableContainer>
  );
};
