import { Container } from "@pixi/react";
import * as PIXI from "pixi.js";
import { useCallback } from "react";

import { useThemeConfig } from "@shared/lib/hooks";

import { Reel } from "../../../lib/pixi/reel";

import type { IMiniGameContext } from "../../../lib/mini-game-context-provider";
import type { IReelConfig } from "../../../lib/pixi/reel";
import type { IPointData } from "pixi.js";
import type { MutableRefObject } from "react";

interface IReels {
  position: IPointData;
  reels: MutableRefObject<Reel[]>;
  pivot?: IPointData;
  miniGameModel: IMiniGameContext;
}

export const Reels = ({ position, reels, pivot, miniGameModel }: IReels) => {
  const { themeConfig } = useThemeConfig();

  const config: IReelConfig = {
    totalReels: 3,
    countSpinning: 20,
    totalReelCells: 3,
    reelCellHeight: themeConfig.jackpots.miniGame.reelCellHeight,
    reelCellWidth: themeConfig.jackpots.miniGame.reelCellWidth,
    reelVerticalPadding: 50,
    reelHorizontalMargin: themeConfig.jackpots.miniGame.reelHorizontalMargin,
    countUniqueSymbolTextures: 4,
  };

  const { win, updateReelStatus } = miniGameModel;

  const handleStopSpin = useCallback(() => {
    const reelStoppedCounter: number[] = [];

    for (const reel of reels.current) {
      if (!reel.spinning) {
        reelStoppedCounter.push(reel.reelIndex);
      }
    }

    if (reelStoppedCounter.length === reels.current.length) {
      updateReelStatus("spin-finished");
    }
  }, [reels, updateReelStatus]);

  const setReelsContainerRef = useCallback((node: PIXI.Container) => {
    Reel.totalCells = config.totalReelCells;

    if (node && win) {
      for (let index = 0; index < config.totalReels; index = index + 1) {
        const reel = new Reel(index, win.slot, 0.1 + index / 8, handleStopSpin, config);

        node.addChild(reel);
        reels.current.push(reel);
      }

      const maskContainer = new PIXI.Graphics()
        .beginFill(0xffffff)
        .drawRect(0, 130, 580, config.reelCellHeight + 10)
        .endFill();

      node.mask = maskContainer;
      node.addChild(maskContainer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Container pivot={pivot ?? 0} zIndex={4} ref={setReelsContainerRef} position={position} />;
};

Reels.displayName = "Reels";
