import * as PIXI from "pixi.js";
import { useMemo } from "react";

import { useThemeConfig } from "@shared/lib/hooks";
import { ButtonSecondary } from "@shared/ui";

import type { IPointData, Spritesheet } from "pixi.js";

type TProps = {
  onClick: VoidFunction;
  position?: IPointData;
  disabled?: boolean;
};
export const ButtonClose = ({ onClick, position, disabled }: TProps) => {
  const { isMobile } = useThemeConfig();
  const iconClose = PIXI.Assets.cache.get<Spritesheet>("common.json").textures[`icon-close`];

  const scale = isMobile ? 1.6 : 1;

  const computedPosition = useMemo(() => {
    if (position) {
      const scaleGapWidth = iconClose.width * scale - iconClose.width;
      const scaleGapHeight = iconClose.height * scale - iconClose.height;

      return {
        x: position?.x - scaleGapWidth,
        y: position?.y - scaleGapHeight,
      };
    }
  }, [iconClose.height, iconClose.width, position, scale]);
  return (
    <ButtonSecondary
      scale={scale}
      disabled={disabled}
      position={computedPosition}
      anchor={0.5}
      iconTexture={iconClose}
      onPress={onClick}
    />
  );
};
