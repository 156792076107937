import { memo } from "react";

import { TakeWin } from "@features/take-win";
import { JackpotsType } from "@shared/lib/api/types";
import { usePosition } from "@shared/lib/hooks";

import { useMiniGameContext } from "../../lib/use-mini-game-context";

export const WinJackpotInfo = memo(() => {
  const { win, reelStatus, fetchWinAccept } = useMiniGameContext();
  const position = usePosition();

  if (!win) {
    return null;
  }

  return (
    <TakeWin
      position={position.center}
      slotName={JackpotsType[win.slot]}
      onTakeWin={fetchWinAccept}
      spinStatus={reelStatus}
      sum={win?.value ?? 0}
      type="jackpot"
    />
  );
});

WinJackpotInfo.displayName = "WinJackpot";
