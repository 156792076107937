export class SliderEventContext {
  public rate: number;

  constructor(rate: number) {
    this.rate = rate;
  }
}

export interface SliderEventTypes {
  slider_change: (e: SliderEventContext) => void;
  slider_change_finished: (e: SliderEventContext) => void;
}
