import { Container, Sprite, Text } from "@pixi/react";
import gsap from "gsap";
import * as PIXI from "pixi.js";
import { memo, useCallback, useRef } from "react";

import { playerModel } from "@entities/player";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { sfx } from "@shared/lib/services";
import { formatter } from "@shared/lib/utils";
import { AnimatedSpine, MutationButtonPrimary } from "@shared/ui";

import type { IPointData } from "pixi.js";

type TTakeWin = {
  spinStatus: "spin-finished" | "spin-start" | "spin-stop";
  sum: number;
  type: "jackpot" | "bonus" | "daily-wheel";
  slotName?: string;
  onTakeWin: VoidFunction;
  position: IPointData;
  scale?: number;
};

export const TakeWin = memo(
  ({ spinStatus, sum, type, slotName = "", onTakeWin, position, scale = 1 }: TTakeWin) => {
    const { t } = useTranslate();

    const { fonts, themeConfig, themeScreenDevice } = useThemeConfig();
    const animateContainerRef = useRef<PIXI.Container | null>(null);
    const { currency } = playerModel.usePlayer() ?? {};

    const winAnimData = PIXI.Assets.cache.get("spine/jackpot/jp-win/skeleton.json")?.spineData;

    const tweenFadeIn = (target: PIXI.Container) => {
      gsap.to(target, {
        pixi: {
          alpha: 1,
          scale: scale,
        },
        ease: "power4.inOut",
        duration: 0.5,
      });
    };

    const winContainerRef = useCallback(
      (node: PIXI.Container) => {
        if (node && spinStatus === "spin-finished") {
          sfx.play("sounds/wheel-win.mp3");
          tweenFadeIn(node);
          animateContainerRef.current = node;
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [spinStatus],
    );

    if (animateContainerRef?.current) {
      tweenFadeIn(animateContainerRef.current!);
    }

    return (
      <Container
        position={position}
        zIndex={3}
        alpha={0}
        scale={{ x: 0.25, y: 0.25 }}
        ref={winContainerRef}
      >
        <AnimatedSpine
          anchor={{ x: 0.5, y: 0.5 }}
          animationName={themeConfig.jackpots.miniGame.winAnimationName}
          spineData={winAnimData}
        />
        <Sprite
          width={themeConfig.jackpots.miniGame.slotSize[0]}
          height={themeConfig.jackpots.miniGame.slotSize[1]}
          anchor={0.5}
          texture={PIXI.Assets.cache.get("common.json").textures[`@${themeScreenDevice}.modal`]}
        />
        <Text
          position={{ x: 0, y: -190 }}
          anchor={{ x: 0.5, y: 0 }}
          style={{ ...fonts.primary, fontSize: 48 }}
          text={t("congrats!").toUpperCase()}
        />
        <Text
          position={{ x: 0, y: -100 }}
          anchor={{ x: 0.5, y: 0 }}
          style={{ ...fonts.primary, fontSize: 48 }}
          text={t("you won").toUpperCase()}
        />
        {type === "jackpot" && (
          <Text
            anchor={0.5}
            style={{ ...fonts.primary, fontSize: 48 }}
            text={t("{slotName} jackpot!", {
              slotName,
            }).toUpperCase()}
          />
        )}
        <Text
          anchor={0.5}
          position={{ x: 0, y: 120 }}
          style={{
            ...fonts.primary,
            fill: "white",
            fontSize: 52,
            dropShadowColor: fonts.secondary.stroke,
            dropShadow: true,
            dropShadowDistance: 4,
            dropShadowBlur: 2,
          }}
          text={`${formatter.formatMoney(formatter.toMoney(sum))} ${currency}`}
        />
        <MutationButtonPrimary
          title={t("take")}
          onPress={onTakeWin}
          position={{ x: -153, y: 182 }}
        />
      </Container>
    );
  },
);

TakeWin.displayName = "TakeWin";
