import * as PIXI from "pixi.js";
import {
  Assets,
  type AssetsManifest,
  extensions,
  ExtensionType,
  resolveTextureUrl,
  type ResolveURLParser,
  settings,
  type UnresolvedAsset,
} from "pixi.js";
import { createContext, type PropsWithChildren, useRef, useState } from "react";

import type { BUNDLES } from "./const";

const basePath = `${import.meta.env.VITE_PUBLIC_PATH}assets`;
const appVersion = import.meta.env.VITE_APP_VERSION;
const resolveJsonUrl = {
  extension: ExtensionType.ResolveParser,
  test: (value: string): boolean =>
    // @ts-expect-error should be fixed in the next version of pixi (RETINA_PREFIX is of type RegEx)
    settings.RETINA_PREFIX.test(value) && value.endsWith(".json"),
  parse: resolveTextureUrl.parse,
} as ResolveURLParser;

extensions.add(resolveJsonUrl);

PIXI.BaseTexture.defaultOptions.mipmap = PIXI.MIPMAP_MODES.POW2;

type TBundleName = (typeof BUNDLES)[number];

export type AssetsBundlesName =
  | "default"
  | "backgrounds"
  | "lobby-screen"
  | "sounds"
  | "spine"
  | "common"
  | "wheel-fortune"
  | "mini-game";

interface ResourcesContextValue {
  progress: number;
  areBundlesLoaded: (bundles: TBundleName[]) => boolean;
  loadBundles: (bundles: TBundleName[]) => Promise<void>;
  unloadBundle: (bundle: TBundleName) => void;
}

const initialResourcesContextValue: ResourcesContextValue = {
  progress: 0,
  areBundlesLoaded: () => false,
  loadBundles: async () => {},
  unloadBundle: () => {},
};

export const AssetsContext = createContext<ResourcesContextValue>(initialResourcesContextValue);

export const PixiAssetsProvider = ({ children }: PropsWithChildren) => {
  const manifestRef = useRef<AssetsManifest>({ bundles: [] });
  const initRef = useRef(false);
  const [progress, setProgress] = useState(0);

  const fetchAssetsManifest = async (url: string) => {
    const response = await fetch(url);
    const manifest = await response.json();

    if (!manifest.bundles) {
      throw new Error("[Assets] Invalid assets manifest");
    }
    return manifest;
  };

  const initAssets = async () => {
    const manifest = await fetchAssetsManifest(`${basePath}/assets-manifest.json?v=${appVersion}`);
    manifestRef.current = manifest;
    if (!initRef.current) {
      await PIXI.Assets.init({
        manifest,
        // defaultSearchParams: `v=${appVersion}`,
        basePath: basePath,
        texturePreference: {
          format: ["webp", "mp3", "ogg", "png"],
        },
      });
      initRef.current = true;
    }
  };

  const checkBundleExists = (bundle: TBundleName) => {
    return !!manifestRef.current?.bundles?.find((b) => b.name === bundle);
  };

  const loadBundles = async (bundles: TBundleName[]) => {
    await initAssets();
    setProgress(0);

    for (const bundle of bundles) {
      if (!checkBundleExists(bundle)) {
        throw new Error(`[Assets] Invalid bundle: ${bundle}`);
      }
    }

    const loadList = bundles
      .filter((bundle) => !Object.keys(manifestRef.current.bundles).includes(bundle))
      .filter((bundle) => !isBundleLoaded(bundle));

    if (!loadList.length) return;
    console.info("[Assets] Load:", loadList.join(", "));

    await PIXI.Assets.loadBundle(loadList, (progressValue) => {
      setProgress(progressValue);
    });
  };

  const unloadBundle = (bundle: TBundleName) => {
    PIXI.Assets.unloadBundle(bundle);
  };

  function isBundleLoaded(bundle: string) {
    const bundleManifest = manifestRef.current?.bundles.find((b) => b.name === bundle);
    if (!bundleManifest) {
      return false;
    }

    for (const asset of bundleManifest.assets as UnresolvedAsset[]) {
      if (!Assets.cache.has(asset.alias as string)) {
        return false;
      }
    }

    return true;
  }

  function areBundlesLoaded(bundles: string[]) {
    for (const name of bundles) {
      if (!isBundleLoaded(name)) {
        return false;
      }
    }

    return true;
  }

  return (
    <AssetsContext.Provider
      value={{
        progress,
        areBundlesLoaded,
        loadBundles,
        unloadBundle,
      }}
    >
      {children}
    </AssetsContext.Provider>
  );
};
