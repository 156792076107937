import { useEffect } from "react";

import { playerModel } from "@entities/player";
import { useThemeConfig } from "@shared/lib/hooks";
import { Tunnel } from "@shared/lib/vendors";

import "./styles.css";

const DEFAULT_PANIC_URL = "https://www.google.com/search?igu=1";

export const PanicIframe = () => {
  const { lobby } = playerModel.usePlayer();
  const { themeName } = useThemeConfig();
  const { ui } = Tunnel.getInstance();

  useEffect(() => {
    if (lobby?.isPanic) {
      window.document.title = "Welcome";
    }

    return () => {
      window.document.title = themeName;
    };
  }, [lobby?.isPanic, themeName]);

  return (
    <>
      <ui.In>
        <iframe className="panic-iframe" src={lobby?.panicUrl ?? DEFAULT_PANIC_URL} />
      </ui.In>
    </>
  );
};
