import { Graphics, Rectangle } from "pixi.js";

type TScrollbarThumbOptions = {
  color: number;
  thumbSize: number;
  isScrollbarHidden: boolean;
};

export class ScrollbarThumb extends Graphics {
  constructor(options: TScrollbarThumbOptions) {
    super();
    const { thumbSize, color, isScrollbarHidden } = options;
    const ratio = 0.5;
    this.beginFill(color, isScrollbarHidden ? 0 : 1);
    this.drawRect(-thumbSize / 2, -thumbSize * ratio, thumbSize, thumbSize);
    this.hitArea = new Rectangle(-thumbSize / 2, -thumbSize * ratio, thumbSize, thumbSize);
    this.x = thumbSize / 2;
  }
}
