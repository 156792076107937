import { Sprite, Text } from "@pixi/react";
import * as PIXI from "pixi.js";
import { useState } from "react";

import { gameModel } from "@entities/game";
import { playerModel } from "@entities/player";
import { useThemeConfig } from "@shared/lib/hooks/use-theme-config";
import { formatter } from "@shared/lib/utils";
import { ClickableContainer } from "@shared/ui";

export const PlayerBalance = () => {
  const { isLoading, balance, balanceBet, currency } = playerModel.usePlayer() ?? {};

  const [hideBalance, setHideBalance] = useState(false);

  const { themeConfig, fonts, isMobile } = useThemeConfig();
  const { currentGameUrl } = gameModel.useGameController();

  if (isLoading) {
    return null;
  }

  if (currentGameUrl && isMobile) {
    return null;
  }

  return (
    <ClickableContainer
      position={themeConfig.header.balance.position}
      scale={themeConfig.header.balance.scale}
      onpointerup={() => setHideBalance((prev) => !prev)}
    >
      <Sprite
        anchor={{ x: 0, y: 0.5 }}
        texture={PIXI.Assets.cache.get("lobby-screen.json").textures[`balance`]}
      >
        <Text
          anchor={{ x: 0, y: hideBalance ? 0.37 : 0.5 }}
          x={90}
          style={{
            ...fonts.primary,
            wordWrap: true,
            wordWrapWidth: 250,
            breakWords: true,
            fontSize: 34,
          }}
          text={
            hideBalance
              ? "* * * * * *"
              : `${
                  formatter.formatMoney(formatter.toMoney(balance ?? balanceBet)) ?? "-"
                } ${currency}`
          }
        />
      </Sprite>
    </ClickableContainer>
  );
};
