import { Container } from "@pixi/react";
import { useMemo } from "react";

import { bonusModel } from "@entities/bonus";
import { useIsSweepstakesEnabled } from "@entities/lobby";
import { playerModel } from "@entities/player";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { formatter, TaggedTextBuilder } from "@shared/lib/utils";
import { COLOR, QueryErrorAlert, TaggedTextComponent } from "@shared/ui";

import { Table } from "./table-balance";

export const BalanceView = () => {
  const { t } = useTranslate();
  const { fonts } = useThemeConfig();
  const { wager, remainingWager, query } = bonusModel.useBonusAndWagers();
  const { isSSEnabled } = useIsSweepstakesEnabled();
  const { balance, currency, balanceBet, balanceDeposit } = playerModel.usePlayer();

  const buildEntityBalance = useMemo(() => {
    return new TaggedTextBuilder()
      .add(
        t("Balance {balance} {currency}", {
          balance: `<b>${formatter.formatMoney(formatter.toMoney(balance ?? balanceBet))} </b>`,
          currency: currency,
        }),
        !!balance || !!balanceBet,
      )
      .add(
        t("Remaining wager {remainingWager} {currency}", {
          remainingWager: `<b>${formatter.formatMoney(formatter.toMoney(remainingWager))} </b>`,
          currency: currency,
        }),
      )
      .add(
        t("Wager {wager} {currency}", {
          wager: `<b>${formatter.formatMoney(formatter.toMoney(wager))} </b>`,
          currency: currency,
        }),
      )
      .build();
  }, [balance, balanceBet, currency, remainingWager, t, wager]);

  return (
    <>
      <QueryErrorAlert query={query} />
      <TaggedTextComponent
        tagStyles={{
          b: {
            ...fonts.primary,
            fontWeight: 700,
          },
          default: {
            ...fonts.primary,
            wordWrap: true,
            fill: COLOR.white,
          },
          splitStyle: "words",
        }}
        text={buildEntityBalance}
      />
      {isSSEnabled && balanceDeposit && (
        <Container y={200} x={10}>
          <Table />
        </Container>
      )}
    </>
  );
};
