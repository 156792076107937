import { Container, Text } from "@pixi/react";

import { useEditFormState } from "@features/player/edit-form/edit.form.state";
import { useThemeConfig, useTranslate } from "@shared/lib/hooks";
import { InputView, MutationButtonPrimary, MutationErrorText, SmartContainer } from "@shared/ui";

import type { IPointData } from "pixi.js";

type TProps = {
  submitButtonPosition: IPointData;
};

export const EditFormView = ({ submitButtonPosition }: TProps) => {
  const { handleSubmit, handleInputNewPassword, handleInputOldPassword, mutation, form } =
    useEditFormState();

  const { t } = useTranslate();
  const { fonts, isMobile } = useThemeConfig();
  return (
    <SmartContainer paddingRight={isMobile ? 20 : 0}>
      <Container>
        <Text
          anchor={{ x: 0, y: 1 }}
          style={{ ...fonts.primary, fontSize: 24 }}
          text={t("old password").toUpperCase()}
        />
        <InputView
          type="password"
          defaultValue={form.oldPassword}
          width={400}
          height={60}
          onChange={handleInputOldPassword}
        />
      </Container>
      <Container position={{ x: 0, y: 120 }}>
        <Text
          anchor={{ x: 0, y: 1 }}
          style={{ ...fonts.primary, fontSize: 24 }}
          text={t("new password").toUpperCase()}
        />
        <InputView
          type="password"
          width={400}
          height={60}
          defaultValue={form.newPassword}
          onChange={handleInputNewPassword}
        />
      </Container>
      <MutationButtonPrimary
        mutation={mutation}
        position={submitButtonPosition}
        onPress={handleSubmit}
        title={t("update").toUpperCase()}
      />
      <MutationErrorText mutation={mutation} position={{ x: 90, y: submitButtonPosition.y - 20 }} />
    </SmartContainer>
  );
};
