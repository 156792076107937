import { Container } from "@pixi/react";
import { Spine } from "pixi-spine";
import { memo, useLayoutEffect, useRef } from "react";

import type { ISkeletonData, ITrackEntry } from "pixi-spine";
import type * as PIXI from "pixi.js";
import type { IPointData } from "pixi.js";

type TAnimatedSpine = {
  spineData: ISkeletonData;
  isAnimating?: boolean;
  position?: IPointData;
  anchor?: IPointData;
  pivot?: IPointData;
  width?: number;
  height?: number;
  children?: React.ReactElement;
  alpha?: number;
  animationName?: string;
  loop?: boolean;
  scale?: IPointData;
  onAnimationCb?: VoidFunction;
  angle?: number;
  timeScale?: number;
  skinName?: string | null;
};

const ANIMATION_COMPLETE_LISTENERS = ["winGift"];

export const AnimatedSpine = memo(
  ({
    spineData,
    position = { x: 0, y: 0 },
    isAnimating = true,
    width,
    height,
    alpha = 1,
    animationName = "animation",
    loop = true,
    children,
    scale = { x: 1, y: 1 },
    onAnimationCb,
    angle = 0,
    timeScale = 1,
    skinName = null,
  }: TAnimatedSpine) => {
    const spineRef = useRef<PIXI.Sprite>(null);

    useLayoutEffect(() => {
      const spineInstance = new Spine(spineData);
      spineInstance.name = animationName;

      if (spineRef.current) {
        spineRef.current.addChild(spineInstance);
      }

      if (height) {
        spineInstance.height = height;
      }

      if (width) {
        spineInstance.width = width;
      }

      if (position) {
        spineInstance.position = position;
      }

      if (angle) {
        spineInstance.angle = angle;
      }

      if (scale) {
        spineInstance.scale.set(scale.x, scale.y);
      }

      if (isAnimating) {
        spineInstance.state.setAnimation(0, animationName, loop);
        spineInstance.state.timeScale = timeScale;

        if (skinName) {
          const skeleton = spineInstance.skeleton;
          skeleton.setSkinByName(skinName);
        }
      }

      spineInstance.state.addListener({
        complete(entry: ITrackEntry) {
          if (entry.animationEnd) {
            if (ANIMATION_COMPLETE_LISTENERS.includes(animationName)) {
              onAnimationCb?.();
            }
          }
        },
      });

      return () => {
        spineInstance?.state?.clearTrack(0);
        spineInstance?.destroy();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnimating, position, scale, width, height]);

    if (!isAnimating) {
      return null;
    }

    return (
      <Container zIndex={2} alpha={alpha} ref={spineRef}>
        {!!children && children}
      </Container>
    );
  },
);

AnimatedSpine.displayName = "AnimatedSpine";
