import { Container } from "@pixi/react";
import { gsap } from "gsap";
import * as PIXI from "pixi.js";
import { useCallback, useMemo, useRef, useState } from "react";

import { useLobbyStore } from "@entities/lobby";
import { useThemeConfig } from "@shared/lib/hooks";
import { ClickableContainer } from "@shared/ui";

import { useSideMenuState } from "../model/side.menu.state";

import { SideMenuSprite } from "./side-menu-sprite";
import { Tags } from "./tags";

const tweenOpen = (target: PIXI.Container) => {
  if (!target) return;

  gsap.to(target, {
    duration: 0.3,
    ease: "none",
    pixi: {
      x: 0,
    },
  });
};

const tweenClose = (target: PIXI.Container, x: number) => {
  if (!target) return;
  gsap.to(target, {
    duration: 0.3,
    ease: "none",
    pixi: {
      x,
    },
  });
};

export const SideMenu = () => {
  const { themeConfig } = useThemeConfig();
  const refMenuContainer = useRef<PIXI.Container | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const state = useSideMenuState();

  const closeMenuPaddingX = useMemo(
    () => -274 * themeConfig.sideMenu.scale.x,
    [themeConfig.sideMenu.scale.x],
  );

  const hitArea = useMemo(
    () => new PIXI.Rectangle(0, 0, 600, 1200 * themeConfig.sideMenu.scale.y),
    [themeConfig.sideMenu.scale.y],
  );
  const toggleSideMenu = useLobbyStore((state) => state.toggleSideMenu);

  const toggleMenu = useCallback(() => {
    if (!refMenuContainer.current) return;

    if (isOpen) {
      toggleSideMenu(false);
      tweenClose(refMenuContainer.current, closeMenuPaddingX);
    } else {
      toggleSideMenu(true);
      tweenOpen(refMenuContainer.current);
    }

    setIsOpen((prev) => !prev);
  }, [closeMenuPaddingX, isOpen, toggleSideMenu]);

  const wrapperRef = useCallback((node: PIXI.Container) => {
    refMenuContainer.current = node;
  }, []);

  return (
    <Container name="side-menu" x={closeMenuPaddingX} zIndex={1} ref={wrapperRef} hitArea={hitArea}>
      <ClickableContainer
        x={0}
        y={themeConfig.sideMenu.position.y}
        scale={themeConfig.sideMenu.scale}
        onpointerup={toggleMenu}
      >
        <SideMenuSprite {...state} />
      </ClickableContainer>
      <Container y={themeConfig.sideMenu.position.y} scale={themeConfig.sideMenu.scale}>
        <Tags toggle={toggleMenu} {...state} />
      </Container>
    </Container>
  );
};

SideMenu.displayName = "SideMenu";
