import { type PropsWithChildren } from "react";

import { ClickableContainer } from "@shared/ui";

import { useDropdownStore } from "./listbox-context";

export const ListboxLabel = ({ children }: PropsWithChildren) => {
  const toggle = useDropdownStore(({ toggle }) => toggle);

  return (
    <ClickableContainer zIndex={1} onpointerup={toggle}>
      {children}
    </ClickableContainer>
  );
};
