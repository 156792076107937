import * as Sentry from "@sentry/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { App } from "./app";

if (import.meta.env.PROD) {
  Sentry.init({
    release: import.meta.env.VITE_APP_VERSION,
    dsn: "https://38bcff39d7d44548b1e5164848cbf462@services-dev.kiosk.games/16",
    tracesSampleRate: 1.0,
    environment: "production",
  });
}

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
