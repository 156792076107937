import { Container, Sprite, Text } from "@pixi/react";
import * as PIXI from "pixi.js";
import { useState } from "react";

import { useThemeConfig } from "@shared/lib/hooks";
import { ClickableContainer, Spinner } from "@shared/ui";

import type { UseMutationResult } from "@tanstack/react-query";

interface IProps<TData, TError, TResult> {
  onPress?: VoidFunction;
  disabled?: boolean;
  iconSize?: number;
  width?: number;
  height?: number;
  title?: string;
  position?: PIXI.IPointData;
  zIndex?: number;
  scale?: number;
  isNegative?: boolean;
  mutation?: UseMutationResult<TData, TError, TResult, unknown>;
  anchor?: number;
  titleStyle?: PIXI.TextStyle;
}

export function MutationButtonPrimary<TData = unknown, TError = unknown, TResult = unknown>({
  width = 320,
  height = 100,
  onPress = () => null,
  disabled = false,
  title = "",
  position = {
    x: 0,
    y: 0,
  },
  zIndex = 0,
  scale = 1,
  mutation,
  isNegative = false,
  titleStyle = {} as PIXI.TextStyle,
}: IProps<TData, TError, TResult>) {
  const { fonts } = useThemeConfig();

  const common = PIXI.Assets.cache.get("common.json");
  const defaultButton = common.textures[`button-primary_default`];
  const pressedButton = common.textures[`button-primary_pressed`];
  const hoverButton = common.textures[`button-primary_hover`];
  const disabledButton = common.textures[`button-primary_disabled`];
  const rejectedButton = common.textures[`button-primary_rejected`];

  const [texture, setTexture] = useState(isNegative ? rejectedButton : defaultButton);

  if (disabled) {
    return (
      <Container position={position} zIndex={zIndex} scale={scale}>
        <Sprite anchor={{ x: 0.5, y: 0.5 }} x={width / 2} y={height / 2} texture={disabledButton} />
        <Text
          x={width / 2}
          y={height / 2}
          text={title?.toUpperCase() ?? ""}
          anchor={{ x: 0.5, y: 0.5 }}
          style={{ ...fonts.defaultBtnTitleStyle, ...titleStyle }}
        />
      </Container>
    );
  }

  if (mutation?.isPending) {
    return (
      <Container position={position} zIndex={zIndex} scale={scale}>
        <Sprite anchor={{ x: 0.5, y: 0.5 }} x={width / 2} y={height / 2} texture={disabledButton} />
        <Text
          x={width / 2}
          y={height / 2}
          text={title?.toUpperCase() ?? ""}
          anchor={{ x: 0.5, y: 0.5 }}
          style={{ ...fonts.defaultBtnTitleStyle, ...titleStyle }}
        />
        <Spinner scale={{ x: 0.4, y: 0.4 }} position={{ x: 30, y: height / 2 }} />
      </Container>
    );
  }

  return (
    <ClickableContainer
      position={position}
      zIndex={zIndex}
      scale={scale}
      pointertap={onPress}
      ontouchstart={() => {
        setTexture(pressedButton);
      }}
      ontouchend={() => {
        if (isNegative) {
          setTexture(rejectedButton);
        } else {
          setTexture(defaultButton);
        }
      }}
      onmouseenter={() => {
        setTexture(hoverButton);
      }}
      onmouseleave={() => {
        if (isNegative) {
          setTexture(rejectedButton);
        } else {
          setTexture(defaultButton);
        }
      }}
    >
      <Sprite anchor={0.5} x={width / 2} y={height / 2} texture={texture} />
      <Text
        x={width / 2}
        y={height / 2}
        text={title?.toUpperCase() ?? ""}
        anchor={{ x: 0.5, y: 0.5 }}
        style={{ ...fonts.defaultBtnTitleStyle, ...titleStyle }}
      />
    </ClickableContainer>
  );
}
