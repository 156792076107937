import { Container } from "@pixi/react";
import { memo } from "react";

import { usePosition, useThemeConfig } from "@shared/lib/hooks";
import { Modal, SmartContainer, Tabs, Title } from "@shared/ui";

import { useModalEditSettingsState } from "../lib/modal-edit-settings.state";

import type { TModalEditSettingsState } from "../lib/modal-edit-settings.state";

export const ModalEditSettingsView = memo(() => {
  const { isOpen, close, ...state } = useModalEditSettingsState();

  const onClose = () => {
    close();
    state.setCurrentTab(state.tabItems[0]);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      name="edit-settings"
      onClose={onClose}
      render={(modalSpriteWidth, modalSpriteHeight) => (
        <Content
          modalSpriteWidth={modalSpriteWidth}
          modalSpriteHeight={modalSpriteHeight}
          {...state}
        />
      )}
    />
  );
});

const Content = ({
  modalSpriteWidth,
  modalSpriteHeight,
  tabItems,
  setCurrentTab,
  currentTab,
}: Omit<TModalEditSettingsState, "close" | "isOpen"> & TModalContent) => {
  const { isMobile } = useThemeConfig();
  const scale = isMobile ? 2 : 1;

  const position = usePosition({
    width: modalSpriteWidth / scale,
    height: modalSpriteHeight / scale,
  });

  return (
    <Container scale={scale}>
      <SmartContainer position={{ x: 0, y: -position.bottom.y / 2 }} paddingTop={45}>
        <Title text={currentTab.label} />
      </SmartContainer>
      <Tabs
        backgroundColor={0xffffff}
        activeTab={currentTab}
        items={tabItems}
        onTabChange={setCurrentTab}
        contentPaddingTop={isMobile ? 0 : 100}
        tabPaddingTop={isMobile ? -200 : -100}
        tabPaddingLeft={isMobile ? 48 : 80}
      />
    </Container>
  );
};

ModalEditSettingsView.displayName = "ModalEditSettingsView";
