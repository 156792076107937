import { get } from "@shared/lib/utils";

import { TextError } from "../text-error";

import type { UseMutationResult } from "@tanstack/react-query";
import type * as PIXI from "pixi.js";

interface IProps<TData, TError, TResult> {
  mutation: UseMutationResult<TData, TError, TResult, unknown>;
  position?: PIXI.IPointData;
}

export function MutationErrorText<TData, TError, TResult>({
  mutation,
  position,
}: IProps<TData, TError, TResult>) {
  const error = get(mutation.error, "message", "Unknown error");

  if (mutation.isError) {
    return <TextError error={error} position={position} />;
  }

  return null;
}
