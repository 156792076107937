import { Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";
import { useState } from "react";

import { ClickableContainer } from "@shared/ui";

interface IProps {
  onPress?: () => void;
  width?: number;
  height?: number;
}

export const ButtonExpand = ({ onPress }: IProps) => {
  const defaultTexture = PIXI.Assets.cache.get("common.json").textures["button-expand"];
  const hoverTexture = PIXI.Assets.cache.get("common.json").textures["button-expand_hover"];
  const pressedTexture = PIXI.Assets.cache.get("common.json").textures["button-expand_active"];

  const [texture, setTexture] = useState(defaultTexture);
  return (
    <ClickableContainer
      onpointerup={() => {
        onPress?.();
      }}
      onmouseenter={() => {
        setTexture(hoverTexture);
      }}
      onmouseleave={() => {
        setTexture(defaultTexture);
      }}
      ontouchstart={() => {
        setTexture(pressedTexture);
      }}
      ontouchend={() => {
        setTexture(defaultTexture);
      }}
    >
      <Sprite
        scale={0.8}
        anchor={{ x: 0.5, y: 0.5 }}
        sortableChildren={true}
        texture={texture}
      ></Sprite>
    </ClickableContainer>
  );
};
