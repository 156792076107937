import { useMutationState } from "@tanstack/react-query";

import type { Endpoint, Output } from "@shared/lib/api/types";
import type { DefaultError, Mutation, MutationState, MutationStatus } from "@tanstack/react-query";

type MutationEndpoint = Endpoint;

type MutationStateOptions<TUrl extends MutationEndpoint> = {
  filters: { mutationKey?: TUrl[]; status?: MutationStatus };
  select?: (
    mutation: Mutation<Output<TUrl>, DefaultError, unknown, unknown>,
  ) => MutationState<Output<TUrl>> | unknown;
};

type TMutationStateOptions<E extends MutationEndpoint> = MutationStateOptions<E>;

export function useSmartMutationState<E extends MutationEndpoint>(
  options: TMutationStateOptions<E>,
) {
  return useMutationState({
    ...options,
    select: (mutation) => {
      return options?.select?.(mutation as Mutation<Output<E>, DefaultError, unknown, unknown>);
    },
  });
}
