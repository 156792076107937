import { PROVIDER_ID_LIVE } from "../../consts";

import { useIntegerSearchParam } from "./_core_/use-integer-search-param";
import { SearchParamKey } from "./search-param-key";

export function useProviderId(defaultValue = PROVIDER_ID_LIVE) {
  const [providerId, setProviderId] = useIntegerSearchParam(
    SearchParamKey.ProviderId,
    defaultValue,
  );
  return { providerId, setProviderId };
}
