import { Graphics, Sprite, Text } from "@pixi/react";
import * as PIXI from "pixi.js";
import { Rectangle } from "pixi.js";
import { useCallback, useState } from "react";

import { useThemeConfig } from "@shared/lib/hooks";
import { sfx } from "@shared/lib/services";
import { AnimatedSpine, ClickableContainer, COLOR } from "@shared/ui";

import type { IPointData } from "pixi.js";

type TGameCardsItem = {
  textureSourceUrl: string;
  onClick: (stringId: string) => void;
  stringId: string;
  position: IPointData;
  height: number;
  width: number;
  name: string;
  gameScale: number;
};

const BORDER_SCALE = { x: 1.085, y: 1.095 };

export const GameCard = ({
  textureSourceUrl,
  width,
  height,
  name,
  stringId,
  onClick,
  position,
  gameScale,
}: TGameCardsItem) => {
  const { fonts, themeScreenDevice, isMobile } = useThemeConfig();
  const [hover, setHover] = useState(false);

  const spineData = PIXI.Assets.cache.get("spine/hover-card/skeleton.json").spineData;
  const border = PIXI.Assets.cache.get("lobby-screen.json").textures[`game-border`];
  const borderFill = PIXI.Assets.cache.get("lobby-screen.json").textures[`game-border-fill`];

  const handleHover = useCallback(() => {
    sfx.play("sounds/hover.mp3");
    setHover(true);
  }, []);

  const draw = useCallback(
    (g: PIXI.Graphics) => {
      g.clear();
      g.beginFill(0x000000, 0.5);
      g.drawRect(30, height - 34, width - 30, 35);
      g.endFill();
    },
    [width, height],
  );

  const handleHoverOut = useCallback(() => setHover(false), []);

  const handleClick = useCallback(() => {
    onClick(stringId);
  }, [onClick, stringId]);

  // сдвиг спрайта с игрой с учетом border scale
  const spriteGapWithScaleBorderX = (width * BORDER_SCALE.x - width) / 2;
  const spriteGapWithScaleBorderY = (width * BORDER_SCALE.y - width) / 2;
  const spriteGapWithScaleGameX = (width - width * gameScale) / 2;
  const spriteGapWithScaleGameY = (height - height * gameScale) / 2;
  const bgTexture = PIXI.Texture.from(textureSourceUrl);

  return (
    <>
      <ClickableContainer
        sortableChildren={true}
        position={position}
        onpointerup={isMobile ? () => null : handleClick}
        ontouchend={isMobile ? handleClick : () => null}
        onmouseenter={handleHover}
        onmouseleave={handleHoverOut}
        hitArea={new Rectangle(0, 0, width, height)}
      >
        {hover && themeScreenDevice === "desktop" && (
          <AnimatedSpine
            timeScale={0.8}
            alpha={1}
            position={{
              x: width / 2 + spriteGapWithScaleBorderX,
              y: height / 2 + spriteGapWithScaleBorderY,
            }}
            scale={{
              x: gameScale + 0.5,
              y: gameScale + 0.5,
            }}
            isAnimating={hover}
            spineData={spineData}
          />
        )}
        <Sprite
          width={width * BORDER_SCALE.x}
          height={height * BORDER_SCALE.y}
          texture={borderFill ?? PIXI.Texture.EMPTY}
          zIndex={3}
        />
        <Sprite
          x={spriteGapWithScaleGameX + spriteGapWithScaleBorderX}
          y={spriteGapWithScaleGameY + spriteGapWithScaleBorderY - 1}
          width={width * gameScale}
          height={height * gameScale}
          texture={bgTexture}
          zIndex={3}
        />
        <Sprite
          width={width * BORDER_SCALE.x}
          height={height * BORDER_SCALE.y}
          texture={border}
          zIndex={4}
        />

        <Graphics name="gameTitleBackground" draw={draw} zIndex={3} />
        <Text
          position={{ x: width / 2 + 20, y: height - 35 }}
          anchor={{ x: 0.5, y: 0 }}
          text={name}
          style={{
            ...fonts.primary,
            fontWeight: "bold",
            fontSize: 26,
            letterSpacing: -1,
            align: "center",
            dropShadowColor: COLOR.black,
            dropShadow: true,
            dropShadowDistance: 4,
            dropShadowBlur: 2,
          }}
          zIndex={4}
        />
      </ClickableContainer>
    </>
  );
};

GameCard.displayName = "GameCard";
