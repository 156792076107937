import { useMemo } from "react";

import { useThemeConfig } from "@shared/lib/hooks/use-theme-config";

type TUsePosition = {
  width?: number;
  height?: number;
};

export const usePosition = (props: Partial<TUsePosition> = {}) => {
  const { themeHeight, themeWidth } = useThemeConfig();

  const computedWidth = props?.width ?? themeWidth;
  const computedHeight = props?.height ?? themeHeight;

  const viewportCenter = useMemo(
    () => ({
      x: computedWidth / 2,
      y: computedHeight / 2,
    }),
    [computedHeight, computedWidth],
  );

  const viewportTopCenter = useMemo(
    () => ({
      x: computedWidth / 2,
      y: 0,
    }),
    [computedWidth],
  );

  const viewportBottomCenter = useMemo(
    () => ({
      x: computedWidth / 2,
      y: computedHeight,
    }),
    [computedHeight, computedWidth],
  );

  const viewportLeftCenter = useMemo(
    () => ({
      x: 0,
      y: computedHeight / 2,
    }),
    [computedHeight],
  );

  const viewportRightCenter = useMemo(
    () => ({
      x: computedWidth,
      y: computedHeight / 2,
    }),
    [computedHeight, computedWidth],
  );

  const viewportTopLeft = useMemo(
    () => ({
      x: 0,
      y: 0,
    }),
    [],
  );

  const viewportTopRight = useMemo(
    () => ({
      x: computedWidth,
      y: 0,
    }),
    [computedWidth],
  );

  const viewportBottomLeft = useMemo(
    () => ({
      x: 0,
      y: computedHeight,
    }),
    [computedHeight],
  );

  const viewportBottomRight = useMemo(
    () => ({
      x: computedWidth,
      y: computedHeight,
    }),
    [computedHeight, computedWidth],
  );

  const viewportTop = useMemo(
    () => ({
      x: computedWidth / 2,
      y: 0,
    }),
    [computedWidth],
  );

  const viewportBottom = useMemo(
    () => ({
      x: computedWidth / 2,
      y: computedHeight,
    }),
    [computedHeight, computedWidth],
  );

  return {
    "center": viewportCenter,
    "top-center": viewportTopCenter,
    "bottom-center": viewportBottomCenter,
    "left-center": viewportLeftCenter,
    "right-center": viewportRightCenter,
    "top-left": viewportTopLeft,
    "top-right": viewportTopRight,
    "bottom-left": viewportBottomLeft,
    "bottom-right": viewportBottomRight,
    "top": viewportTop,
    "bottom": viewportBottom,
  };
};

export type TPosition = ReturnType<typeof usePosition>;
