import { create } from "zustand";

import { settings } from "@app/settings";

interface ILobbyConfig {
  isDesktop: boolean;
  isLandscape: boolean;
  isIOS: boolean;
  isSafariOrIos: boolean;
  supportsTouchEvents: boolean;
  supportsPointerEvents: boolean;
  gameSceneAspectRatio: number;
  gameSceneWidth: number;
  gameSceneHeight: number;
  canvasHeight: number;
  canvasWidth: number;
}

interface IAppContext {
  isJackpotsWidgetVisible: boolean;
  isLayoutLoading: boolean;
  isWheelFortuneWidgetVisible: boolean;
  lobbyConfig: ILobbyConfig;
  modal: string;
  gameOpenError: Nullable<string>;
  setIsLayoutLoading: (isLoading: boolean) => void;
  setLobbyConfig: (config: ILobbyConfig) => void;
  setModal: (modal?: string) => void;
  setGameOpenError: (error?: Partial<Nullable<string>>) => void;
  toggleJackpotsWidgetVisible: VoidFunction;
  toggleWheelFortuneWidgetVisible: VoidFunction;
}

export const useAppStore = create<IAppContext>()((set) => ({
  isWheelFortuneWidgetVisible: false,
  isJackpotsWidgetVisible: false,
  isLayoutLoading: false,
  modal: "",
  gameOpenError: null,
  lobbyConfig: {
    isIOS: false,
    isSafariOrIos: false,
    isDesktop: false,
    isLandscape: true,
    supportsTouchEvents: false,
    supportsPointerEvents: false,
    gameSceneAspectRatio: window.devicePixelRatio,
    gameSceneWidth: settings.size[0],
    gameSceneHeight: settings.size[1],
    canvasWidth: window.innerWidth,
    canvasHeight: window.innerHeight,
  },
  toggleJackpotsWidgetVisible: () =>
    set((state) => ({ isJackpotsWidgetVisible: !state.isJackpotsWidgetVisible })),
  toggleWheelFortuneWidgetVisible: () =>
    set((state) => ({ isWheelFortuneWidgetVisible: !state.isWheelFortuneWidgetVisible })),
  setIsLayoutLoading: (isLoading) => set(() => ({ isLayoutLoading: isLoading })),
  setLobbyConfig: (config) => set(() => ({ lobbyConfig: config })),
  setModal: (modal = "") => set(() => ({ modal })),
  setGameOpenError: (error = null) => set(() => ({ gameOpenError: error })),
}));
