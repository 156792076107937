import { useContext } from "react";

import { DailyWheelContext } from "./daily-wheel-context-provider";

export const useDailyWheelContext = () => {
  const dailyWheelContext = useContext(DailyWheelContext);
  if (!dailyWheelContext) {
    throw new Error("Provider DailyWheel not found");
  }

  return dailyWheelContext;
};
