import { SmartContainer } from "@shared/ui";

import { TabButton } from "./tab-button";

import type { TTab, TTabChangeCb } from "./types";

const TAB_VERTICAL_POSITION = 70;
const TAB_HORIZONTAL_POSITION = 270;
const CONTAINER_VERTICAL_POSITION = 20;
const CONTAINER_HORIZONTAL_POSITION = -50;
const PADDING_BETWEEN_TABS = 50;

type TTabs = {
  items: TTab[];
  activeTab: TTab;
  backgroundColor?: number;
  isVertical?: boolean;
  textAlign?: "center" | "left";
  onTabChange?: TTabChangeCb;
  contentPaddingTop?: number;
  contentPaddingLeft?: number;
  tabPaddingTop?: number;
  tabPaddingLeft?: number;
};

export const Tabs = ({
  items,
  activeTab,
  textAlign,
  onTabChange,
  isVertical = false,
  contentPaddingTop,
  tabPaddingTop,
  tabPaddingLeft,
  contentPaddingLeft,
}: TTabs) => {
  const allTabsLengthPX = isVertical
    ? items.length * TAB_VERTICAL_POSITION
    : items.length * TAB_HORIZONTAL_POSITION;

  const buttonsPosition = {
    x: isVertical
      ? CONTAINER_VERTICAL_POSITION
      : -allTabsLengthPX / 2 + items.length * PADDING_BETWEEN_TABS,
    y: CONTAINER_HORIZONTAL_POSITION,
  };

  return (
    <>
      <SmartContainer
        name="tab-buttons"
        centeredChild={true}
        paddingLeft={tabPaddingLeft}
        position={buttonsPosition}
      >
        <TabButtons {...{ items, activeTab, textAlign, onTabChange, isVertical, tabPaddingTop }} />
      </SmartContainer>
      <SmartContainer
        centeredChild={true}
        name="tab-content"
        paddingLeft={contentPaddingLeft}
        paddingTop={contentPaddingTop}
        position={{ x: 0, y: isVertical ? allTabsLengthPX : 0 }}
      >
        {activeTab.children}
      </SmartContainer>
    </>
  );
};

function TabButtons({ ...props }: TTabs) {
  const { onTabChange, items, isVertical, tabPaddingTop, textAlign = "center", activeTab } = props;

  function handleTabClick(tab: TTab) {
    if (onTabChange) {
      onTabChange(tab);
    }
  }

  return items.map((tab, idx) => {
    if (!tab.isVisible) return;

    const position = {
      x: !isVertical ? TAB_HORIZONTAL_POSITION * idx : 0,
      y: !isVertical ? 0 : TAB_VERTICAL_POSITION * idx,
    };

    return (
      <SmartContainer key={tab.key} position={position} paddingTop={tabPaddingTop}>
        <TabButton
          onClick={handleTabClick}
          tab={tab}
          textAlign={textAlign}
          isActive={activeTab.key === tab.key}
        />
      </SmartContainer>
    );
  });
}
