import { useMemo } from "react";

import { BonusBouncebackSchema, BonusCashbackSchema, BonusLastchanceSchema } from "@entities/bonus";
import { playerModel } from "@entities/player";
import { BonusType } from "@shared/lib/api/types";

import type { ZodError } from "zod";

export function useBonusInfo(type: BonusType) {
  const player = playerModel.usePlayer();

  const lobbyBonuses = player?.bankGroup?.bonuses;

  const lobbyAllowBonuses = useMemo(() => {
    const bonuses = [];
    if (lobbyBonuses) {
      for (const type of Object.keys(lobbyBonuses)) {
        bonuses.push(type);
      }
    }

    return bonuses;
  }, [lobbyBonuses]);

  const currentBonus = lobbyBonuses?.[type];

  return useMemo(() => {
    if (type === BonusType.cashback && lobbyAllowBonuses.includes(type)) {
      try {
        return { bonus: BonusCashbackSchema.parse(currentBonus), name: BonusType.cashback };
      } catch (e: unknown) {
        console.error((e as ZodError).message);
      }
    }

    if (type === BonusType.bounceback && lobbyAllowBonuses.includes(type)) {
      try {
        return { bonus: BonusBouncebackSchema.parse(currentBonus), name: BonusType.bounceback };
      } catch (e: unknown) {
        console.error((e as ZodError).message);
      }
    }

    if (type === BonusType.lastchance && lobbyAllowBonuses.includes(type)) {
      try {
        return { bonus: BonusLastchanceSchema.parse(currentBonus), name: BonusType.lastchance };
      } catch (e: unknown) {
        console.error((e as ZodError).message);
      }
    }
  }, [currentBonus, lobbyAllowBonuses, type]);
}
