import { Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";

import { useThemeConfig } from "@shared/lib/hooks/use-theme-config";
import { AnimatedSpine } from "@shared/ui";

export const HeaderLogo = () => {
  const { themeConfig, themeScreenDevice } = useThemeConfig();
  const spineData = PIXI.Assets.cache.get("spine/shine/logo/skeleton.json").spineData;

  return (
    <AnimatedSpine
      spineData={spineData}
      scale={themeConfig.header.logo.spine.scale}
      position={themeConfig.header.logo.spine.position}
    >
      <Sprite
        scale={themeConfig.header.logo.scale}
        position={themeConfig.header.logo.position}
        texture={PIXI.Assets.cache.get("lobby-screen.json").textures[`@${themeScreenDevice}.logo`]}
      />
    </AnimatedSpine>
  );
};
