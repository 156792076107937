/**
 * Test-cases:
 *
 * toMoney("") -> ""
 * toMoney("1000") -> "1000"
 * toMoney(100000) -> 1000.00
 *
 * formatMoney("") -> N/A
 * formatMoney("100") -> N/A
 * formatMoney(1) -> 1.00
 * formatMoney(100000) -> 1 000.00
 */
class Formatter {
  static ERROR_VALUE = "N/A";

  toMoney(value?: string | number): string | number {
    if (value == null) {
      throw new Error("toMoney value exist");
    }
    return typeof value === "string" ? value : value / 100;
  }

  rmZeroFractionsDigits(value: string | undefined) {
    if (value) {
      const fractions = value.split(".");
      if (fractions[1] === "00") {
        return fractions[0];
      } else {
        return value;
      }
    }
    return "";
  }

  formatMoney(
    value: string | number,
    locale = "ru-RU",
    options: Intl.NumberFormatOptions = { minimumFractionDigits: 2 },
  ): string {
    return typeof value === "string"
      ? Formatter.ERROR_VALUE
      : this.rmZeroFractionsDigits(value.toLocaleString(locale, options).replace(",", "."));
  }
}

export const formatter = new Formatter();
