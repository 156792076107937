import { Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";

import { useJackpotsContext, useThemeConfig } from "@shared/lib/hooks";

import { MiniGameContextProvider } from "../lib/mini-game-context-provider";

import { MiniGame } from "./mini-game/mini-game";

const getSpriteProps = (isMobile: boolean, background: PIXI.Sprite) => {
  const pivot = isMobile ? { x: -background.width / 2, y: background.height / 2 } : { x: 0, y: 0 };
  const angle = isMobile ? 90 : 0;
  const anchor = isMobile ? { x: 0.5, y: 0.5 } : { x: 0, y: 0 };

  return { pivot, angle, anchor };
};

export const MiniGameView = () => {
  const { isMobile } = useThemeConfig();
  const { wins } = useJackpotsContext();
  const background = PIXI.Assets.cache.get("jackpots-bg.png");

  if (!wins) {
    return null;
  }

  const spriteProps = getSpriteProps(isMobile, background);

  return (
    <MiniGameContextProvider>
      <Sprite {...spriteProps} interactive={true} texture={background} />
      <MiniGame />
    </MiniGameContextProvider>
  );
};
