import { Graphics, Rectangle } from "pixi.js";

type TScrollbarTrackOptions = {
  color: number;
  trackHeight: number;
  trackWidth: number;
  isScrollbarHidden: boolean;
};

export class ScrollbarTrack extends Graphics {
  constructor(options: TScrollbarTrackOptions) {
    super();
    const { trackHeight, trackWidth, color, isScrollbarHidden } = options;
    this.beginFill(color, isScrollbarHidden ? 0 : 1);
    this.drawRoundedRect(0, 0, trackWidth, trackHeight, 6);
    this.hitArea = new Rectangle(0, 0, trackWidth, trackHeight);
  }
}
