import { useThemeConfig } from "@shared/lib/hooks";
import { ButtonClose } from "@shared/ui";

import { useDailyWheelContext } from "../../lib/use-daily-wheel-context";

type TProps = { toggleClose: VoidFunction };

export const CloseWheelFortuneButton = ({ toggleClose }: TProps) => {
  const { themeWidth, themeConfig } = useThemeConfig();
  const { spinStatus } = useDailyWheelContext();

  if (spinStatus === "spin-start") {
    return null;
  }

  return (
    <ButtonClose
      position={{ x: themeWidth - 150, y: themeConfig.header.height + 50 }}
      onClick={toggleClose}
    />
  );
};
