import { QueryClientProvider } from "@tanstack/react-query";
import { lazy, type PropsWithChildren, Suspense, useEffect, useState } from "react";

import { tanstackQueryClient } from "./client";

const ReactQueryDevtools = lazy(() =>
  import("@tanstack/react-query-devtools/production").then(({ ReactQueryDevtools }) => ({
    default: ReactQueryDevtools,
  })),
);

const DEVTOOLS_HOSTNAMES = ["localhost"];

export function TanstackQueryProvider({ children }: PropsWithChildren) {
  const [isDevtoolsEnabled, setIsDevtoolsEnabled] = useState(false);

  function enableDevtools() {
    setIsDevtoolsEnabled(true);
  }

  useEffect(() => {
    (window as any).enableDevtools = enableDevtools;

    if (DEVTOOLS_HOSTNAMES.includes(window.location.hostname)) {
      enableDevtools();
    }
  }, []);

  return (
    <QueryClientProvider client={tanstackQueryClient}>
      {children}
      {isDevtoolsEnabled && (
        <Suspense fallback={null}>
          <ReactQueryDevtools initialIsOpen={false} position="left" />
        </Suspense>
      )}
    </QueryClientProvider>
  );
}
