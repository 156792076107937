import { useCallback, useMemo } from "react";

import { useSmartQuery } from "@shared/lib/api";
import {
  MOBILE_TAGS,
  PROVIDER_ID_LIVE,
  SHOW_ALL,
  TAG_DEFAULT_ID,
  TAG_RECOMMENDATION_ID,
} from "@shared/lib/consts";
import { useThemeConfig } from "@shared/lib/hooks";
import { useProviderId, useTagId } from "@shared/lib/services";

export const useFilteredGames = () => {
  const { isMobile } = useThemeConfig();

  const queryGames = useSmartQuery("POST Game.list", { body: {} });
  const queryRecommendationGames = useSmartQuery("POST Game.getRecommendations", { body: {} });

  const { tagId } = useTagId(SHOW_ALL);
  const { providerId } = useProviderId(PROVIDER_ID_LIVE);

  const games = useMemo(
    () => queryGames?.data?.results[0]?.response?.list ?? [],
    [queryGames?.data?.results],
  );

  const recommendationGamesId = useMemo(
    () => queryRecommendationGames?.data?.results[0]?.response?.games ?? [],
    [queryRecommendationGames?.data?.results],
  );

  const hasMatchingTagId = useCallback(
    (game: ArrayElement<typeof games>) => {
      if (tagId === SHOW_ALL) {
        return game;
      }
      return !!game.tags.find((tag) => tagId === tag.id);
    },
    [tagId],
  );

  const hasIncludesRecommendation = useCallback(
    (game: ArrayElement<typeof games>) => {
      return recommendationGamesId.includes(game.id);
    },
    [recommendationGamesId],
  );

  const hasIncludesProvider = useCallback(
    (game: ArrayElement<typeof games>) => {
      if (providerId === SHOW_ALL) {
        return game.game_provider_id !== PROVIDER_ID_LIVE;
      }

      return game.game_provider_id === providerId;
    },
    [providerId],
  );

  const hasMobile = useCallback((game: ArrayElement<typeof games>) => {
    return game.tags.some((tag) => MOBILE_TAGS.has(tag.name.toLowerCase()));
  }, []);

  const mobileFilter = useCallback(
    () => (isMobile ? hasMobile : () => true),
    [hasMobile, isMobile],
  );

  const computedGames = useMemo(() => {
    if (providerId !== SHOW_ALL) {
      return games.filter(hasIncludesProvider).filter(hasMatchingTagId);
    }

    if (tagId === TAG_DEFAULT_ID) {
      return games.filter(mobileFilter).filter(hasIncludesProvider);
    }

    if (tagId === TAG_RECOMMENDATION_ID) {
      return games.filter(hasIncludesRecommendation);
    }

    return games.filter(hasMatchingTagId);
  }, [
    providerId,
    tagId,
    games,
    hasMatchingTagId,
    hasIncludesProvider,
    mobileFilter,
    hasIncludesRecommendation,
  ]);

  return {
    rawGames: games,
    computedGames,
    isLoading: queryGames.isLoading || queryRecommendationGames.isLoading,
    isError: queryGames.isError,
    queryGames,
  };
};
