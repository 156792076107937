import { PixiComponent, useApp } from "@pixi/react";
import gsap from "gsap";
import * as PIXI from "pixi.js";
import { Container } from "pixi.js";

import type { Application, IPointData } from "pixi.js";

const DEFAULT_WIDTH = 320;

type TProps = {
  onProgress: (currentValue: number) => void;
  progress: number;
  app: Application;
  width?: number;
  position?: IPointData;
  scale: number;
};

export const PixiSlider = PixiComponent("slider", {
  create: ({ onProgress, app, width, position, progress, scale }: TProps) => {
    const sliderWidth = width ?? DEFAULT_WIDTH * scale;

    const sliderBar = new PIXI.Graphics()
      .beginFill(0x162f83)
      .drawRoundedRect(0, 0, sliderWidth, 12, 4);

    const handleButton = new PIXI.Graphics().beginFill(0xffb23f).drawCircle(0, 0, 20 * scale);

    const halfHandleWidth = handleButton.width / 2;
    const relativeHalfHandleWidth = halfHandleWidth / sliderWidth;
    const progressClamper = gsap.utils.normalize(
      relativeHalfHandleWidth,
      1 - relativeHalfHandleWidth,
    );

    handleButton.y = sliderBar.height / 2;
    handleButton.x = calcHandleButtonX(sliderWidth * progress);

    handleButton.eventMode = "static";
    handleButton.cursor = "pointer";

    function calcHandleButtonX(progress: number) {
      return Math.max(halfHandleWidth, Math.min(progress, sliderWidth - halfHandleWidth));
    }

    const handleDrag = (e: any) => {
      handleButton.x = calcHandleButtonX(sliderBar.toLocal(e.global).x);

      if (onProgress) {
        // Normalize handle position between 0 and 1.
        const progress = progressClamper(handleButton.x / sliderWidth);
        onProgress(progress);
      }
    };

    function onDragStart() {
      app.stage.eventMode = "static";
      app.stage.addEventListener("pointermove", handleDrag);
    }

    function onDragEnd() {
      app.stage.eventMode = "auto";
      app.stage.removeEventListener("pointermove", handleDrag);
    }

    handleButton
      .on("pointerdown", onDragStart)
      .on("pointerup", onDragEnd)
      .on("pointerupoutside", onDragEnd);

    const sliderContainer = new Container();

    sliderContainer.addChild(sliderBar);
    sliderContainer.addChild(handleButton);

    if (position) {
      sliderContainer.position = position;
    }

    return sliderContainer;
  },

  config: {
    destroy: true,
    destroyChildren: true,
  },
});

export const Slider = ({
  onProgress,
  position,
  width,
  progress,
}: Omit<TProps, "app" | "scale">) => {
  const app = useApp();

  return (
    <PixiSlider
      scale={1}
      progress={progress}
      position={position}
      app={app}
      onProgress={onProgress}
      width={width}
    />
  );
};
