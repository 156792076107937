import { Graphics } from "@pixi/react";
import { useCallback } from "react";

import type { Graphics as PIXIGraphics } from "pixi.js";

type TProps = {
  width: number;
  height: number;
  radius: number;
  color: number;
};
export const RoundedRect = ({ width, height, radius, color }: TProps) => {
  const draw = useCallback(
    (g: PIXIGraphics) => {
      g.clear();
      g.beginFill(color);
      g.drawRoundedRect(0, 0, width, height, radius);
      g.endFill();
    },
    [color, height, radius, width],
  );
  return <Graphics draw={draw} />;
};
