import * as PIXI from "pixi.js";

export class GameSymbol extends PIXI.Sprite {
  #textures = [
    PIXI.Texture.from("symbol-mini"),
    PIXI.Texture.from("symbol-major"),
    PIXI.Texture.from("symbol-grand"),
    PIXI.Texture.from("symbol-ultimate"),
  ];

  names = ["MINI", "MAJOR", "GRAND", "ULTIMATE"];

  constructor(positionX: number, positionY: number, textureIndex: number) {
    super();
    this.x = positionX;
    this.y = positionY;
    this.texture = this.#textures[textureIndex];
    this.anchor.set(0, 0.5);
  }

  replaceTexture(textureIndex: number) {
    if (textureIndex >= this.numTextures) {
      this.texture = this.#textures[this.numTextures - 1];
    }

    this.texture = this.#textures[textureIndex];
  }

  get numTextures() {
    return this.#textures.length;
  }
}
