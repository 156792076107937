import { Container } from "@pixi/react";

import { useDropdownStore } from "./listbox-context";

import type { PropsWithChildren } from "react";

// const PADDING_BG = 25;

// function generateOptionsBg(node: PIXI.Container, tintColor: TListboxOptionsProps["tintColor"]) {
//   const child = node.children[0] satisfies PIXI.DisplayObject;
//
//   const { width, height } = child.getLocalBounds();
//
//   const bgTint = new PIXI.Sprite();
//
//   bgTint.texture = PIXI.Texture.WHITE;
//   bgTint.zIndex = -1;
//   bgTint.tint = tintColor ? tintColor : 0x010101;
//   bgTint.width = width + PADDING_BG;
//   bgTint.x = -PADDING_BG;
//   bgTint.y = -PADDING_BG;
//   bgTint.height = height + PADDING_BG;
//
//   return bgTint;
// }

type TListboxOptionsProps = {
  tintColor?: number;
};
export const ListboxOptions = ({
  children,
  // tintColor,
}: PropsWithChildren<TListboxOptionsProps>) => {
  const isOpen = useDropdownStore(({ isOpen }) => isOpen);

  // const addTintRef = useCallback(
  //   (node: PIXI.Container) => {
  //     if (!node) return;
  //     const bg = generateOptionsBg(node, tintColor);
  //     node.addChild(bg);
  //   },
  //   [tintColor],
  // );

  return (
    <Container zIndex={1} interactive={isOpen} visible={isOpen} sortableChildren={true}>
      {children}
    </Container>
  );
};
