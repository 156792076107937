import { Container, Text } from "@pixi/react";
import { memo, type PropsWithChildren } from "react";

import { useThemeConfig } from "../../lib/hooks";

import type { IPointData } from "pixi.js";

type TProps = {
  groupName: string;
  position?: IPointData;
};

export const FormGroup = memo(({ children, groupName, position }: PropsWithChildren<TProps>) => {
  const { fonts } = useThemeConfig();

  return (
    <Container position={position ?? { x: 0, y: 0 }}>
      <Text
        y={-120}
        style={{ ...fonts.primary, fill: "white" }}
        text={`${groupName.toUpperCase()}:`}
      />
      {children}
    </Container>
  );
});

FormGroup.displayName = "FormGroup";
