import * as PIXI from "pixi.js";
import { memo } from "react";

import { useModal } from "@shared/lib/services/search-params/use-modal";
import { ButtonSecondary } from "@shared/ui";

export const ToggleSettings = memo(() => {
  const { open } = useModal("edit-settings");
  const userIcon = PIXI.Assets.cache.get("lobby-screen.json").textures[`icon-settings`];

  return <ButtonSecondary iconSize={90} iconTexture={userIcon} onPress={open} />;
});

ToggleSettings.displayName = "ToggleEditPlayer";
