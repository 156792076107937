/*eslint-disable*/
export const HtmlErrorBoundary = () => {
  return (
    <div className="slot-machine">
      <div className="reels-wrapper">
        <div className="reel">
          <div className="symbols">
            <div className="symbol">4</div>
            <div className="symbol">🍊</div>
            <div className="symbol">🍇</div>
          </div>
        </div>
        <div className="reel">
          <div className="symbols">
            <div className="symbol">0</div>
            <div className="symbol">🍇</div>
            <div className="symbol">🍊</div>
          </div>
        </div>
        <div className="reel">
          <div className="symbols">
            <div className="symbol">4</div>
            <div className="symbol">🍇</div>
            <div className="symbol">🍊</div>
          </div>
        </div>
      </div>
      <p className="error-message">Oops! Something went wrong. Try again later or reload page</p>
      <button className="error-btn" onClick={() => window.location.reload()}>
        Try
      </button>
    </div>
  );
};
