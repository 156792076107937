import { Container, Text } from "@pixi/react";

import { ToggleSound } from "@features/toggle-sound";
import { useTranslate } from "@shared/lib/hooks";
import { useThemeConfig } from "@shared/lib/hooks/use-theme-config";
import { ToggleSettings } from "src/features/settings/toggle-settings";

export const RightButtonsGroup = () => {
  const { t } = useTranslate();

  const { themeWidth, themeConfig, fonts, isMobile } = useThemeConfig();

  const textStyle = { ...fonts.primary, fontSize: 18, fontWeight: "bolder" };

  const paddingY = isMobile
    ? themeConfig.footer.menuButton.marginTop + 50
    : themeConfig.footer.menuButton.marginTop;
  const paddingX = isMobile ? themeWidth / 2 + 80 : themeWidth / 2 - 200;

  return (
    <>
      <Container scale={themeConfig.footer.menuButton.scale} x={paddingX - 400} y={paddingY}>
        <ToggleSound />
        <Text
          renderable={!isMobile}
          anchor={{ x: 0.45, y: -2.5 }}
          style={textStyle}
          text={t("mute").toUpperCase()}
        />
      </Container>
      <Container x={paddingX - 200} y={paddingY} scale={themeConfig.footer.menuButton.scale}>
        <ToggleSettings />
        <Text
          renderable={!isMobile}
          style={textStyle}
          anchor={{ x: 0.5, y: -2.5 }}
          text={t("settings").toUpperCase()}
        />
      </Container>
    </>
  );
};
