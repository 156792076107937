import { Container } from "@pixi/react";

import { useRules } from "@entities/lobby";
import { ModalRulesView } from "@widgets/modal-rules";

export function RulesGuard() {
  const { needAcceptRules } = useRules();
  if (needAcceptRules) {
    return (
      <Container x={0}>
        <ModalRulesView />
      </Container>
    );
  }

  return null;
}
