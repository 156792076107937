import { boolean, z } from "zod";

export const BonusCashbackSchema = z.object({
  minDeposit: z.number().int().min(0),
  maxDeposit: z.number().int(),
  cooldown: z.number().int().min(0),
  value: z.number().int().min(0),
  wager: boolean(),
  threshold: z.number().int().min(0),
  happyHours: z
    .object({
      enabled: z.boolean(),
      from: z.string(),
      to: z.string(),
      boost: z.number(),
    })
    .optional(),
});

export const BonusBouncebackSchema = z.object({
  minDeposit: z.number().int().min(0),
  maxDeposit: z.number().int(),
  cooldown: z.number().int().min(0),
  value: z.number().int().min(0),
  wager: boolean(),
  threshold: z.number().int().min(0),
  happyHours: z
    .object({
      enabled: z.boolean(),
      from: z.string(),
      to: z.string(),
      boost: z.number(),
    })
    .optional(),
});

export const BonusLastchanceSchema = z.object({
  minDeposit: z.number().int().min(0),
  maxDeposit: z.number().int(),
  value: z.number().int().min(0),
  cooldown: z.number().int().min(0),
  wager: boolean(),
  threshold: z.number().int().min(0),
});

export type IBonusCashbackSchema = z.infer<typeof BonusCashbackSchema>;
export type IBonusBouncebackSchema = z.infer<typeof BonusBouncebackSchema>;
export type IBonusLastchanceSchema = z.infer<typeof BonusLastchanceSchema>;
