import { Container, Graphics, Text } from "@pixi/react";
import * as PIXI from "pixi.js";
import { memo } from "react";

import { useThemeConfig } from "@shared/lib/hooks";
import { formatter } from "@shared/lib/utils";

export type TSector = {
  startAngle: number;
  endAngle: number;
  color: number;
  value: number;
  rotation: number;
  x: number;
  y: number;
  wheelRadius: number;
};

export const Sector = memo(
  ({ startAngle, endAngle, color, value, wheelRadius, x, y, rotation }: TSector) => {
    const { fonts } = useThemeConfig();
    return (
      <Container name="wheel-section">
        <Graphics
          draw={(g) => {
            g.beginFill(color);
            g.lineStyle(2, color, 1);
            g.moveTo(0, 0);
            g.arc(0, 0, wheelRadius, startAngle, endAngle);
            g.lineTo(0, 0);
          }}
        />
        <Text
          rotation={rotation}
          x={x}
          y={y}
          text={formatter.formatMoney(formatter.toMoney(value))}
          anchor={0.5}
          style={new PIXI.TextStyle({ ...fonts.primary, fontWeight: "lighter" })}
        />
      </Container>
    );
  },
);

Sector.displayName = "Sector";
