import { useAppStore } from "@/app/providers";

export function useModal(id: string) {
  const modal = useAppStore((state) => state.modal);
  const setModal = useAppStore((state) => state.setModal);

  const isOpen = modal === id;

  function open() {
    setModal(id);
  }

  function close() {
    setModal();
  }

  return { isOpen, open, close };
}
