import { useCallback, useEffect, useMemo } from "react";

import { useSearchParams } from "@shared/lib/hooks/use-search-params";

export function useIntegerSearchParam(key: string, defaultValue?: number) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.has(key)) {
      if (defaultValue !== undefined) {
        searchParams.set(key, defaultValue.toString());
        setSearchParams(searchParams);
      }
    }
  }, [defaultValue, key, searchParams, setSearchParams]);

  const value = useMemo(() => {
    if (defaultValue !== undefined && !Number.isSafeInteger(defaultValue)) {
      throw new Error(`Invalid default value: ${defaultValue}. It must be an integer.`);
    }

    const stringValue = searchParams.get(key)!;

    if (!/^-?\d+$/.test(stringValue)) {
      return defaultValue;
    }

    const value = Number.parseInt(stringValue, 10);

    if (!Number.isSafeInteger(value)) {
      return defaultValue;
    }

    return value;
  }, [key, defaultValue, searchParams]);

  const setValue = useCallback(
    (value?: number) => {
      if (value !== undefined && !Number.isSafeInteger(value)) {
        throw new Error(`Invalid value: ${value}. It must be an integer.`);
      }

      const searchParams = new URLSearchParams(window.location.search);
      if (value !== undefined) {
        searchParams.set(key, value.toString());
      } else {
        searchParams.delete(key);
      }

      setSearchParams(searchParams, { replace: true });
    },
    [key, setSearchParams],
  );

  return [value, setValue] as const;
}
