import { Graphics, Rectangle } from "pixi.js";

type TScrollViewContentMaskOptions = {
  trackHeight: number;
  contentWidth: number;
};

export class ScrollViewContentMask extends Graphics {
  constructor(options: TScrollViewContentMaskOptions) {
    super();
    const { trackHeight, contentWidth } = options;
    this.beginFill(0x000000, 0.5);
    this.drawRect(0, 0, contentWidth, trackHeight);
    this.hitArea = new Rectangle(0, 0, contentWidth, trackHeight);
  }
}
