import { Container, Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";
import { memo } from "react";

import { useAppStore } from "@app/providers";
import { useSmartMutationState } from "@shared/lib/api";
import { useThemeConfig } from "@shared/lib/hooks";
import { AnimatedSpine } from "@shared/ui";

import { DailyWheelContextProvider } from "../lib/daily-wheel-context-provider";

import { CloseWheelFortuneButton } from "./daily-wheel/close-wheel-fortune";
import { DailyWheelRules } from "./daily-wheel/daily-wheel-rules";
import { Wheel } from "./daily-wheel/wheel";
import { WinDailyWheelInfo } from "./daily-wheel/win-daily-wheel-info";

import type { IDailyWheelWin } from "@shared/lib/api/types";

export const DailyWheel = memo(() => {
  const { themeWidth, isMobile, themeConfig } = useThemeConfig();
  const { toggleWheelFortuneWidgetVisible, isWheelFortuneWidgetVisible } = useAppStore(
    (state) => state,
  );
  const background = PIXI.Assets.cache.get(`jackpots-bg.png`);
  const spineAnimateBg = PIXI.Assets.cache.get(
    "spine/jackpot/jp-animate-bg/skeleton.json",
  ).spineData;

  const winResult = useSmartMutationState({
    filters: { mutationKey: ["POST FortuneWheel.getWin"], status: "success" },
    select: (mutation) => mutation.state.data?.results[0].response,
  }) as Array<IDailyWheelWin>;

  if (!isWheelFortuneWidgetVisible) {
    return null;
  }

  const pivot = isMobile ? { x: -background.width / 2, y: background.height / 2 } : { x: 0, y: 0 };
  const angle = isMobile ? 90 : 0;
  const anchor = isMobile ? { x: 0.5, y: 0.5 } : { x: 0, y: 0 };

  return (
    <Container zIndex={4} interactive={true}>
      <Sprite anchor={anchor} pivot={pivot} angle={angle} texture={background} />
      <Sprite
        name="wheel-title"
        texture={PIXI.Assets.cache.get("wheel-fortune.json").textures[`daily-wheel-title`]}
        y={themeConfig.header.height + 190}
        x={themeWidth / 2}
        anchor={0.5}
      />
      <AnimatedSpine
        position={{ y: 100, x: themeWidth / 2 }}
        timeScale={0.75}
        spineData={spineAnimateBg}
      />
      <DailyWheelContextProvider>
        <Wheel sectorId={winResult[0]?.sectorId} />
        <DailyWheelRules />
        <WinDailyWheelInfo win={winResult[0]?.win} toggleClose={toggleWheelFortuneWidgetVisible} />
        <CloseWheelFortuneButton toggleClose={toggleWheelFortuneWidgetVisible} />
      </DailyWheelContextProvider>
    </Container>
  );
});

DailyWheel.displayName = "DailyWheel";
