import { useState } from "react";

import { playerModel } from "@entities/player";

import type { EditFormSchema } from "./edit.form.schema";
import type { ZodError } from "zod";

export function useEditFormState() {
  const { editMutation } = playerModel.useEditPlayerPassword();

  const [form, setForm] = useState<EditFormSchema>({
    oldPassword: "",
    newPassword: "",
  });

  const handleInputOldPassword = (oldPassword: string) => {
    setForm((prev) => ({ ...prev, oldPassword }));
  };

  const handleInputNewPassword = (newPassword: string) => {
    setForm((prev) => ({ ...prev, newPassword }));
  };

  function handleSubmit() {
    try {
      // EditFormSchema.parse(form);
      const request = { body: { remember: true, ...form } };
      editMutation.mutate(request);
    } catch (e: unknown) {
      console.log((e as ZodError).formErrors.fieldErrors);
    }
  }

  return {
    form,
    handleSubmit,
    mutation: editMutation,
    handleInputOldPassword,
    handleInputNewPassword,
  };
}
