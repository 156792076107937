import { Container, Sprite } from "@pixi/react";
import { Texture } from "pixi.js";

import { useThemeConfig } from "@shared/lib/hooks";

import { Spinner } from "./spinner";

export const SpinnerWithBg = () => {
  const { themeWidth, themeHeight } = useThemeConfig();

  return (
    <Container zIndex={10}>
      <Sprite
        texture={Texture.WHITE}
        alpha={0.5}
        tint={0x010101}
        interactive={true}
        width={themeWidth}
        height={themeHeight}
      />
      <Spinner position={{ x: themeWidth / 2, y: themeHeight / 2 }} />
    </Container>
  );
};
