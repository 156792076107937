import { useIntl } from "react-intl";

import type { MessageDescriptor } from "react-intl";

export function useTranslate() {
  const intl = useIntl();
  const fm = intl.formatMessage;

  function t(...args: [string | MessageDescriptor, values?: Record<string, string>]): string {
    if (typeof args[0] === "string") {
      if (!args[1]) return args[0];
      args[0] = { id: args[0], defaultMessage: args[0] };
    }
    return fm(args[0], args[1]);
  }

  return { t };
}

export type TUseTranslate = ReturnType<typeof useTranslate>;
