import { Container, Sprite, Text } from "@pixi/react";
import gsap from "gsap";
import * as PIXI from "pixi.js";
import { useEffect, useMemo } from "react";

import { playerModel } from "@entities/player";
import { useThemeConfig } from "@shared/lib/hooks";

import type { Dispatch, SetStateAction } from "react";

export const CooldownWheelTimer = ({
  timeRemaining,
  setTimeRemaining,
}: {
  timeRemaining: number;
  setTimeRemaining: Dispatch<SetStateAction<number>>;
}) => {
  const { fonts, isMobile } = useThemeConfig();
  const player = playerModel.usePlayer();
  const wheelFortuneReadyTimestamp = player.bankGroup?.fortuneWheel?.nextWinTime ?? 0;
  const additionalTimingGapSeconds = 60;

  const timeDifference = () => {
    const wheelFortuneReadyTimestamp1 = wheelFortuneReadyTimestamp + additionalTimingGapSeconds;
    const currentTime = Date.now() / 1000;
    return Math.floor(wheelFortuneReadyTimestamp1 - currentTime);
  };

  const countdown = gsap.timeline({
    paused: true,
    repeat: -1,
    defaults: { duration: timeDifference },
    onUpdate: () => {
      setTimeRemaining(timeDifference);
    },
    onComplete: function () {
      if (!countdown) return;
      countdown.seek(0).pause();
    },
  });

  const formattedTime = useMemo(() => {
    const hours = Math.floor(timeRemaining / 3600);
    const minutes = Math.floor((timeRemaining % 3600) / 60);
    const seconds = timeRemaining % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }, [timeRemaining]);

  useEffect(() => {
    if (!countdown) return;
    countdown.restart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container position={{ x: -55, y: isMobile ? -50 : -33 }} zIndex={4}>
      <Sprite
        texture={PIXI.Texture.WHITE}
        y={32}
        width={110}
        height={40}
        alpha={0.5}
        tint={0x010101}
      />
      <Text text={formattedTime} style={fonts.primary} anchor={0} y={33} />
    </Container>
  );
};
