import { memo } from "react";

import { TakeWin } from "@features/take-win";
import { useThemeConfig } from "@shared/lib/hooks";

import { useDailyWheelContext } from "../../lib/use-daily-wheel-context";

import type { IDailyWheelWin } from "@shared/lib/api/types";

type TWinDailyWheel = Pick<IDailyWheelWin, "win"> & { toggleClose: VoidFunction };
export const WinDailyWheelInfo = memo(({ win, toggleClose }: TWinDailyWheel) => {
  const { spinStatus } = useDailyWheelContext();
  const { themeWidth, themeHeight, themeScreenDevice } = useThemeConfig();

  const paddingY = themeScreenDevice === "mobile" ? themeHeight / 2.7 : themeHeight / 2;
  const scale = themeScreenDevice === "mobile" ? 1.2 : 1;

  const onTakeWin = () => {
    toggleClose();
  };

  if (!win) {
    return;
  }

  return (
    <TakeWin
      type="daily-wheel"
      scale={scale}
      position={{ y: paddingY, x: themeWidth / 2 }}
      onTakeWin={onTakeWin}
      spinStatus={spinStatus}
      sum={win}
    />
  );
});

WinDailyWheelInfo.displayName = "WinJackpot";
