import { Container, Sprite } from "@pixi/react";
import { gsap } from "gsap";
import * as PIXI from "pixi.js";
import { useCallback } from "react";

import { CARD_SPRITE_HEIGHT, CARD_SPRITE_WIDTH } from "@entities/game";

const GUTTER = 25;

const SKELETON_CARD_WIDTH = CARD_SPRITE_WIDTH * 1.12;
const SKELETON_CARD_HEIGHT = CARD_SPRITE_HEIGHT * 1.1;

type TProps = {
  containerWidth: number;
};
export const ListSkeleton = ({ containerWidth }: TProps) => {
  const cardNum = Math.floor(containerWidth / (CARD_SPRITE_WIDTH + GUTTER));

  const borderFill = PIXI.Assets.cache.get("lobby-screen.json").textures[`game-border-fill`];

  const animateRef = useCallback((node: PIXI.Container) => {
    const tweenTl = gsap.timeline({
      repeat: -1,
      paused: true,
    });

    if (node || !tweenTl) {
      tweenTl.from(node, {
        duration: 1.5,
        pixi: { alpha: 0.3 },
        repeat: 1,
        yoyo: true,
        ease: "none",
      });

      tweenTl.restart();
    }
  }, []);

  return (
    <Container ref={animateRef} zIndex={1} x={-105} y={-20}>
      {Array.from(Array(cardNum)).map((_, index) => {
        return (
          <Container key={SKELETON_CARD_WIDTH * index}>
            <Sprite
              height={SKELETON_CARD_HEIGHT}
              width={SKELETON_CARD_WIDTH}
              texture={borderFill}
              y={0}
              x={SKELETON_CARD_WIDTH * index + 20 * index}
            />
            <Sprite
              height={SKELETON_CARD_HEIGHT}
              width={SKELETON_CARD_WIDTH}
              texture={borderFill}
              y={SKELETON_CARD_HEIGHT + 20}
              x={SKELETON_CARD_WIDTH * index + 20 * index}
            />
          </Container>
        );
      })}
    </Container>
  );
};
