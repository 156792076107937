import { type CSSProperties, useEffect, useMemo } from "react";

import { useAppStore } from "@app/providers";
import { gameModel } from "@entities/game";
import { useJackpotsContext, useThemeConfig } from "@shared/lib/hooks";

export const useGameFrameState = () => {
  const { themeHeight, themeWidth, themeConfig } = useThemeConfig();
  const { wins } = useJackpotsContext();
  const { handleGameStart, gameStringId, currentGameUrl, mutationStartupGame } =
    gameModel.useGameController();
  const gameSceneAspectRatio = useAppStore(({ lobbyConfig }) => lobbyConfig.gameSceneAspectRatio);
  const headerHeight = themeConfig.header.height;

  const iframeStyle = useMemo(
    () =>
      ({
        visibility: "visible",
        display: wins ? "none" : "block",
        width: themeWidth,
        height: themeHeight - themeConfig.header.height,
        transform: `scale(${gameSceneAspectRatio}) translateX(-50%) translateY(-50%)`,
        top: "50%",
        left: "50%",
        marginTop: (headerHeight / 2) * gameSceneAspectRatio,
        transformOrigin: "0px 0px",
      }) as CSSProperties,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gameSceneAspectRatio, headerHeight, themeConfig.header.height, themeHeight, themeWidth],
  );

  useEffect(() => {
    if (gameStringId) {
      handleGameStart(gameStringId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    miniGameWins: wins,
    mutationStartupGame,
    gameStringId,
    gameUrl: currentGameUrl,
    iframeStyle,
  };
};
